<template>
<modal name="modal-newemployeestep3" @before-open="beforeOpen" class="modal-newemployeestep3" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-m">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <button aria-label="Close" class="box-title close" data-dismiss="modal" type="button" v-on:click="closeModal()"></button>
                    <div class="onboarding-slide">
                        <div class="onboarding-side-by-side">
                            <div class="headerimg">
                                <img class="img" alt="" src="/assets/img/img_step0.png">
                            </div>
                            <div class="onboarding-content with-gradient" style="width: 100%; padding-bottom: 0px">
                                <p class="title">
                                    Tax Details
                                </p>
                                <form class="ng-pristine ng-valid ng-valid-maxlength">
                                    <v-app>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6>Date of Birth</h6>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <v-select
                                                        :attach="true"
                                                        v-model="newEmployeeInfo.dobDay"
                                                        :items="days"
                                                        label="* Day"
                                                        required
                                                        outlined
                                                        color="success"
                                                        class="ng-day"
                                                    ></v-select>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <v-select
                                                        :attach="true"
                                                        v-model="newEmployeeInfo.dobMonth"
                                                        :items="months"
                                                        label="* Month"
                                                        required
                                                        outlined
                                                        color="success"
                                                        class="ng-month"
                                                    ></v-select>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select
                                                        :attach="true"
                                                        v-model="newEmployeeInfo.dobYear"
                                                        :items="years"
                                                        label="* Year"
                                                        required
                                                        outlined
                                                        color="success"
                                                        class="ng-year"
                                                    ></v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-Status-Residency" v-model="newEmployeeInfo.statusResidency" @change="statusResidencyChange()" label="Status of Residency" :items="listStatusResidency" required outlined color="success"></v-select>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group" style="margin-bottom:2px">
                                                    <v-text-field class="ng-tax-file-number" v-show="!isTaxExemption" v-model="newEmployeeInfo.taxNumber" label="* Tax File Number" type="text" maxlength="40" outlined color="success"></v-text-field>
                                                    <v-select :attach="true" v-show="isTaxExemption" v-model="newEmployeeInfo.tfnExemption" label="Tax File Number" :items="listTfnExemption" outlined color="success"></v-select>
                                                    
                                                </div>
                                                <div style="position: absolute; top: 50px">
                                                    <div class="small-label pointer text-primary" v-show="!isTaxExemption" v-on:click="clickTaxFileNumber()">No tax file number?</div>
                                                    <div class="small-label pointer text-primary" v-show="isTaxExemption" v-on:click="clickTaxFileNumber()">Has TFN?</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <h6>Additional Information</h6>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="newEmployeeInfo.claimTaxFreeThreshold" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" id="claimTaxFreeThreshold">
                                                    <label class="form-check-label" for="tx-o_ft">
                                                        Claim tax free threshold
                                                    </label>
                                                </div>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input
                                                        v-model="newEmployeeInfo.hasHecsOrHelpDebt"
                                                        name="hasHecsOrHelpDebt"
                                                        class="form-check-input ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="checkbox"
                                                        id="hasHecsOrHelpDebt"
                                                    />
                                                    <label class="form-check-label" for="hasHecsOrHelpDebt">
                                                        <!-- Has HECS or HELP debt -->
                                                        Has study and training support loan debt 
                                                        <i class="fa fa-info-circle" title="Any of the following-
                                                        Higher Education Loan Program (HELP) debt
                                                        VET Student Loan (VSL) debt
                                                        Financial Supplement (FS) debt
                                                        Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
                                                        Trade Support Loan (TSL) debt"></i>
                                                    </label>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-12">
                                                <div @click="checkOverwriteTax()" class="row text-left" style="margin-top: 15px;">
                                                    <div class="text-left" style="margin-left: 20px;">
                                                        <v-switch id="overwriteTax" v-model="newEmployeeInfo.hasTaxTemplate" inset style="margin-top: 0px;"></v-switch>
                                                    </div>
                                                    <div class="text-right" style="margin-top: 3px;">
                                                        <label for="overwriteTax">Overwrite tax</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="hasTaxTemplate" class="input-group col-6" style="margin-top: 5px;">
                                                <v-text-field class="ng-tax-file-number" v-model="newEmployeeInfo.taxTemplate" step="0.01" type="number" maxlength="40" outlined color="success" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                
                                            </div>
                                        </div>
                                    </v-app>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- </slick> -->
                    <div class="row" style="padding:12px">
                        <div class="col-6 text-left">
                            <button type="button" class="btn btn-link" v-on:click="gotoStep2()" style="">
                                <i class="icon-feather-arrow-left"></i> Previous
                            </button>
                        </div>
                        <div class="col-6 text-right">
                            <!-- ngIf: currentIndex == 0 -->
                            <button class="btn btn-primary ng-binding" v-on:click="openModalStep4()" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore"
import * as DataSelect from "@/utils/DataSelect.js";
import {
    DATA_STEP3,
    CHECK_TAX_NUMBER
} from "@/store/actionsType";

export default {
    name: "NewEmployeeStep3Modal",
    props: [],
    components: {},
    data() {
        return {
            newEmployeeInfo: {},
            isValidate: true,
            days: [...Array(32).keys()].slice(1),
            years: [...Array(new Date().getFullYear() + 1).keys()].slice(1900).reverse(),
            months: [
                {text: 'JAN', value: '01'},
                {text: 'FEB', value: '02'},
                {text: 'MAR', value: '03'},
                {text: 'APR', value: '04'},
                {text: 'MAY', value: '05'},
                {text: 'JUN', value: '06'},
                {text: 'JUL', value: '07'},
                {text: 'AUG', value: '08'},
                {text: 'SEP', value: '09'},
                {text: 'OCT', value: '10'},
                {text: 'NOV', value: '11'},
                {text: 'DEC', value: '12'},
            ],

            //Valadate
            rules: {
                required: value => !!value || '',
                animal: [val => (val || '').length > 0 || 'This field is required'],
                //min: v => v.length >= 0 || 'Min 8 characters',
                // emailMatch: () => (`The email and password you entered don't match`),
            },

            //Data
            listStatusResidency: DataSelect.getList('listStatusResidency'),
            listTfnExemption: DataSelect.getList('listTfnExemption'),
        };
    },
    methods: {
        beforeOpen(e) {
            if (e.params?.newEmployeeInfo) {
                this.newEmployeeInfo = e.params.newEmployeeInfo;
            }
        },
        openModal() {
            this.$modal.show("modal-newemployeestep3", {
                data: "data",
                newEmployeeInfo: this.newEmployeeInfo
            });
        },
        closeModal() {
            this.$modal.hide("modal-newemployeestep3");
        },
        gotoStep2() {
            this.closeModal()
            this.$modal.show('modal-newemployeestep2');
        },
        onTypeExemptionChange(event) {
            this.newEmployeeInfo.tfnExemption = event.target.value
        },
        statusResidencyChange() {
            if (this.newEmployeeInfo.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.newEmployeeInfo.statusResidency)
            }
        },
        checkStatusResidency(statusResidency) {
            if (statusResidency == 'AustraliaResident') {
                document.getElementById("claimTaxFreeThreshold").disabled = false;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                //document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'ForeignResident') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                this.newEmployeeInfo.claimTaxFreeThreshold = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                //document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'WorkingHoliday') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                document.getElementById("hasHecsOrHelpDebt").checked = false;
                //document.getElementById("hasTslOrStudentStart").checked = false;
                this.newEmployeeInfo.claimTaxFreeThreshold = false
                this.newEmployeeInfo.hasHecsOrHelpDebt = false
                //this.hasTslOrStudentStart = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = true;
                //document.getElementById("hasTslOrStudentStart").disabled = true;
            }
        },
        checkOverwriteTax() {
            if (this.newEmployeeInfo.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.newEmployeeInfo.statusResidency)
            }
        },
        disabled() {
            document.getElementById("claimTaxFreeThreshold").checked = false;
            document.getElementById("hasHecsOrHelpDebt").checked = false;
            this.newEmployeeInfo.claimTaxFreeThreshold = false
            this.newEmployeeInfo.hasHecsOrHelpDebt = false

            document.getElementById("claimTaxFreeThreshold").disabled = true;
            document.getElementById("hasHecsOrHelpDebt").disabled = true;
        },
        clickTaxFileNumber() {
            if (this.isTaxExemption === true) {
                this.newEmployeeInfo.isExemption = false
                this.newEmployeeInfo.tfnExemption = null
            } else {
                this.newEmployeeInfo.isExemption = true
                this.newEmployeeInfo.tfnExemption = "NoTFNQuoted"
                this.newEmployeeInfo.taxNumber = ""
            }
        },
        openModalStep4() {
            if (Validate.isOpenCheckValidate()) {
                this.checkValidate()
                if (this.isValidate) {
                    if (this.newEmployeeInfo.taxNumber != '') {
                        this.checkTaxNumber()
                    } else {
                        this.getDataStep3()
                        this.closeModal()
                        this.$modal.show("modal-newemployeestep4", {
                            isCheckStep4: true,
                            inviteEmployee: false,
                            newEmployeeInfo: this.newEmployeeInfo
                        });
                    }
                }
            } else {
                this.closeModal()
                this.$modal.show("modal-newemployeestep4", {
                    isCheckStep4: true,
                    newEmployeeInfo: this.newEmployeeInfo
                });
            }
        },
        getDataStep3() {
            this.$store.dispatch(DATA_STEP3, this.newEmployeeInfo)
        },
        checkValidate() {
            // var date = wwatindow.$(".ng-birth-date")
            var day = window.$(".ng-day")
            var month = window.$(".ng-month")
            var year = window.$(".ng-year")

            var tax = window.$(".ng-tax-file-number")
            var status = window.$(".ng-Status-Residency")

            if (Validate.isValidateDate("Day", this.newEmployeeInfo.dobDay)) {
                day.addClass("is-invalid");
            } else {
                day.removeClass("is-invalid");
            }
            // Check Month
            if (Validate.isValidateDate("Month", this.newEmployeeInfo.dobMonth)) {
                month.addClass("is-invalid");
            } else {
                month.removeClass("is-invalid");
            }
            // Check Year
            if (Validate.isValidateDate("Year", this.newEmployeeInfo.dobYear)) {
                year.addClass("is-invalid");
            } else {
                year.removeClass("is-invalid");
            }

            if (Validate.isFuture(this.newEmployeeInfo.dobDay, this.newEmployeeInfo.dobMonth, this.newEmployeeInfo.dobYear) ||
                Validate.isInvalidDate(this.newEmployeeInfo.dobDay, this.newEmployeeInfo.dobMonth, this.newEmployeeInfo.dobYear)) {
                day.addClass("is-invalid");
                month.addClass("is-invalid");
                year.addClass("is-invalid");
            } else {
                day.removeClass("is-invalid");
                month.removeClass("is-invalid");
                year.removeClass("is-invalid");
            }

            var checkTax = false
            var checkTaxEmpty = false
            if (!this.newEmployeeInfo.isExemption) {
                checkTax = Validate.CheckTFN(this.newEmployeeInfo.taxNumber)
                checkTaxEmpty = Validate.isEmpty(this.newEmployeeInfo.taxNumber)
            } else {
                checkTax = false;
                checkTaxEmpty = false;
            }

            //Check Tax File Number
            if (checkTaxEmpty || checkTax) {
                tax.addClass("is-invalid");
            } else {
                tax.removeClass("is-invalid");
            }
            //Check StatusResidency
            if (Validate.isEmpty(this.newEmployeeInfo.statusResidency)) {
                status.addClass("is-invalid");
            } else {
                status.removeClass("is-invalid");
            }
            
            this.newEmployeeInfo.birthDate = this.birthDate
            if (Validate.isEmpty(this.birthDate) || Validate.isEmpty(this.newEmployeeInfo.statusResidency) || checkTax || checkTaxEmpty) {
                this.isValidate = false
            } else {
                this.isValidate = true
            }
        },
        // Request API
        checkTaxNumber() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    "companyId": Session.get("currenCompanyId"),
                    "employeeId": 0,
                    "taxNumber": this.newEmployeeInfo.taxNumber,
                }
                this.$store.dispatch(CHECK_TAX_NUMBER, modelRequest)
                    .then(() => {
                        this.$root.$refs.AppCyrus.finish()
                        this.getDataStep3()
                        this.closeModal()
                        this.$modal.show("modal-newemployeestep4", {
                            isCheckStep4: true,
                            newEmployeeInfo: this.newEmployeeInfo
                        });
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }
        },
        isSingleCharacterOrDigit(value) {
            if (typeof value === 'string' && value.length === 1) {
                return true;
            }
            if (typeof value === 'number' && Number.isInteger(value) && value >= 0 && value < 10) {
                return true;
            }
            return false;
        }
    },
    computed: {
        birthDate: function() {
            const month = this.isSingleCharacterOrDigit(this.newEmployeeInfo.dobMonth) ? `0${this.newEmployeeInfo.dobMonth}` : this.newEmployeeInfo.dobMonth;
            const day = this.isSingleCharacterOrDigit(this.newEmployeeInfo.dobDay) ? `0${this.newEmployeeInfo.dobDay}` : this.newEmployeeInfo.dobDay;
            return this.newEmployeeInfo.dobYear + "-" + month + "-" + day
        },
        isTaxExemption: function() {
            return this.newEmployeeInfo?.isExemption === true;
        },
        hasTaxTemplate: function() {
            return this.newEmployeeInfo?.hasTaxTemplate === true;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
