<template>
<div>
    <nav class="header__nav" v-click-outside="more">
        <ul class="header__navbar" style="margin-bottom: 0px;">
            <!-- Dropdown Menu -->
            <transition name="dropdown">
                <div class="dropdown__menu" v-bind:class="{ active: show }" v-if="show">
                    <v-app style="margin-top: 10px;">
                        <div>
                            <v-text-field v-on:keyup="searchCompany()" v-model="keySearch" label="Search" append-icon="mdi-magnify" outlined style="margin: 15 20 0 20"></v-text-field>
                        </div>
                        <div class="disable-scrollbars" style="max-height: 55vh; overflow-y: scroll; overflow-x: hidden;">
                            <div v-if="isFavories">
                                <div class="txt-recent" style="margin-left: 15px;">STARRED</div>
                                <v-list>
                                    <v-list-item v-show="item.favories > 0" v-for="item in listCompany" :key="item.id" class="dropdown__menu-link" style="position: relative;">
                                        <div class="row" @click="onClickItemCompany(item)">
                                            <v-list-item-avatar>
                                                <vue-letter-avatar :name="item.businessName" size='35' :rounded=true />
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.businessName"></v-list-item-title>
                                            </v-list-item-content>
                                        </div>

                                        <v-list-item-icon class="box-title starred" @click="updateCompany('0', item)">
                                            <v-icon color="yellow" style="font-size:20px;">mdi-star</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </div>

                            <div class="txt-recent" style="margin-left: 15px;">RECENT</div>
                            <v-list>
                                <v-list-item v-for="item in getListUnFavories(listCompany)" :key="item.id" class="dropdown__menu-link">
                                    <div class="row" @click="onClickItemCompany(item)">
                                        <v-list-item-avatar>
                                            <vue-letter-avatar :name="item.businessName" size='35' :rounded=true />
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.businessName"></v-list-item-title>
                                        </v-list-item-content>
                                    </div>

                                    <v-list-item-icon class="box-title recent" @click="updateCompany('1', item)">
                                        <v-icon color="gray" style="font-size:20px;">mdi-star</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </div>

                        <div style="padding: 0px 5px;">
                            <div class="line" />
                        </div>
                        <div class=""  v-if="!$root.$refs.BaseLayout.isEmployee" style="padding: 8px 0px; z-index: 1;">
                            <div @click="gotoListCompany()" class="show-all-company os-dropdown-trigger">
                                <div class="text-create-all">View All Company</div>
                            </div>
                            <div @click="gotoCreateCompany()" class="show-all-company os-dropdown-trigger">
                                <div class="text-create-all">Set up a company</div>
                            </div>
                        </div>
                    </v-app>
                </div>
            </transition>
        </ul>
    </nav>
</div>
</template>

<script>
import * as Session from '../../src/utils/SessionStore'

export default {
    name: "ViewMoreMenu",
    props: {
        show: Boolean,
        listCompany: Array,
        isFavories: Boolean,
    },
    data() {
        return {
            Session: Session,
            keySearch: "",
            more: {
                handler: this.hideMenu,
                middleware: this.middlewareMore,
                events: ["dblclick", "click"]
            },
        };
    },
    methods: {
        getListUnFavories(listCompany) {
            var count = 0;
            return listCompany.filter(function (company) {
                if(count < 4 && (company.favories == null ||company.favories == 0)) {
                    count++
                    return company
                }
            })
        },
        searchCompany() {
            if (this.$root.$refs.BaseLayout != undefined) {
                if(this.keySearch != null && this.keySearch != "") {
                    this.$root.$refs.BaseLayout.searchCompany(this.keySearch)
                } else {
                    this.$root.$refs.BaseLayout.reloadListCompany()
                }
            }
        },
        onClickItemCompany(item) {
            if(!this.$root.$refs.BaseLayout.isEmployee){
                if (this.$root.$refs.BaseLayout != undefined) {
                    this.$root.$refs.BaseLayout.onClickItemCompany(item)
                    this.$root.$refs.BaseLayout.showMoreMenu()
                }
            }
        },
        gotoListCompany() {
            if (this.$root.$refs.BaseLayout != undefined) {
                this.$root.$refs.BaseLayout.gotoListCompany()
                this.$root.$refs.BaseLayout.showMoreMenu()
            }
        },
        gotoCreateCompany() {
            if (!this.$root.$refs.BaseLayout) return;
            this.$root.$refs.BaseLayout.gotoCreateCompany()
            this.$root.$refs.BaseLayout.showMoreMenu()
        },
        closeMenu() {
            if (this.$root.$refs.BaseLayout != undefined) {
                this.$root.$refs.BaseLayout.showMoreMenu()
            }
        },
        hideMenu() {
            if (this.$root.$refs.BaseLayout != undefined) {
                if (!this.$root.$refs.BaseLayout.isClickCompany) {
                    this.$root.$refs.BaseLayout.isClickCompany = true
                    this.$root.$refs.BaseLayout.isShowMoreMenu = false
                } else {
                    this.$root.$refs.BaseLayout.isClickCompany = false
                }
            }
        },
        middlewareMore() {
            return true;
        },
        updateCompany(favories, company) {
            if(!this.$root.$refs.BaseLayout.isEmployee){
            company.favories = favories
            this.$root.$refs.BaseLayout.updateFavoriesPage(favories, company.id);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
:root {
    --font-primary: 'Open Sans', sans-serif;
    --color-gray: #F5F5F5;
}

// Global
*,
::after,
::before {
    box-sizing: border-box;
}

body {
    font-size: 1rem;
    -webkit-text-size-adjust: 100%;
    font-family: var(--font-primary);
    overflow: hidden;
}

hr {
    border: 0;
    height: 0;
    margin: 1.5rem 0;
    border-top: 1px solid var(--color-gray);
}

// Header Menu
.header {
    padding: 2rem 5rem 2rem 5rem;

    &__nav {
        position: relative;
    }

    &__navbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__item {
        padding: 1rem;
    }

    &--icon {
        width: 1.65rem;
        height: 1.65rem;
    }

    &--button {
        top: 0;
        right: 0;
        position: absolute;
        margin: 0;
        padding: 0;
        color: gray;
        cursor: pointer;
        border: 1px solid transparent;
        background-color: transparent;

        &:focus {
            outline: 0;
        }
    }
}

// Dropdown Menu
.dropdown__menu {
    top: 100%;
    left: 5px;
    position: absolute;
    z-index: 10;
    // height: 25rem;
    min-width: 300px;
    margin-top: 0.1rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    background-color: white;
    border: solid 1px rgba(108, 125, 159, 0.24);
    background-clip: padding-box;

    &-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.6);
        padding: 0 20 0 30;

        &:hover {
            background: rgba(18, 95, 142, 0.075);
            cursor: pointer;
        }
    }

    &-text {
        font-weight: 300;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

// Animation Menu Icon
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.6s;
}

.slide-fade-enter,
.slide-fade-leave-active {
    opacity: 0;
}

.slide-fade-enter {
    transform: translateX(31px);
}

.slide-fade-leave-active {
    transform: translateX(-31px);
}

// Dropdown Menu Animation
.dropdown-enter-active,
.dropdown-leave-active {
    transition: all 1s;
}

.dropdown-enter,
.dropdown-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

// Wrapper
.main {
    margin: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__subtitle {
        color: #4f5959;
        font-weight: 100;
        margin-bottom: 2rem;
    }

    &__link {
        margin-right: 1.5rem;
        display: inline-block;

        &:last-child {
            margin-right: 0;
        }
    }

    &__svg {
        width: 1rem;
        height: 1rem;
        opacity: 0.6;
        color: #94a2b0;

        &:hover {
            color: #ffad1f;
        }
    }
}
</style>
