<template>
<div>
    <modal name="modal-editemployee" @before-open="beforeOpen" @opened="newDirtyForm" with="auto" height="auto">
        <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" uib-modal-transclude="">
                    <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span style="color:#334152">Employment Details - {{firstName + " " + lastName}}</span>
                                </h5>
                                <button v-on:click="closeModal()" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                            </div>
                            <v-app>
                                <div class="modal-body employee-modal">
                                    <div class="os-tabs-w">
                                        <div class="os-tabs-controls os-tabs-complex">
                                            <ul class="nav nav-tabs">
                                                <li class="nav-item">
                                                    <a aria-expanded="false" class="nav-link Profile tab-employee active" v-on:click="setStyleTab('Profile')">
                                                        <span class="tab-label">Profile</span>
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item disable-funtion disabled">
                                                    <a aria-expanded="true" class="nav-link Bank" v-on:click="setStyleTab('Bank')">
                                                        <span class="tab-label">Bank
                                                        </span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link Super" v-on:click="setStyleTab('Super')">
                                                        <span class="tab-label">Super
                                                        </span>
                                                    </a>
                                                </li> -->
                                                <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link tab-employee Employment" v-on:click="setStyleTab('Employment')">
                                                        <span class="tab-label">Employment</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link tab-employee Tax" v-on:click="setStyleTab('Tax')">
                                                        <span class="tab-label">Tax</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link tab-employee Opening" v-on:click="setStyleTab('Opening')">
                                                        <span class="tab-label">Opening balances</span>
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link tab-employee Template" v-on:click="setStyleTab('Template')">
                                                        <span class="tab-label">Pay Details</span>
                                                    </a>
                                                </li>
                                                <!-- <li class="nav-item">
                                                    <a aria-expanded="true" class="nav-link Notes" v-on:click="setStyleTab('Notes')">
                                                        <span class="tab-label">Notes</span>
                                                    </a>
                                                </li> -->
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- Form Profile -->
                                    <div v-show="this.styleTab == 'Profile'" name="ProfileForm" class="text-left ng-pristine ng-valid ng-valid-required ng-valid-email">
                                        <div class="row">
                                            <div class="col-sm-auto">
                                                <h5 style="margin-top: 10px;">Personal</h5>
                                            </div>
                                            <div v-if="!activeEmployee" class="col-sm-5">
                                                <button class="btn ng-binding ng-scope btn-success" @click="listPolicy.updateEmployee ? checkActiveEmployee() : gotoPayment()" style="margin: 10px 20px 10px 0;">
                                                    Activate
                                                </button>

                                                <button class="btn btn-danger ng-scope" style="margin-right: 20px;" v-on:click="listPolicy.deleteEmployee ? showPopupDelete() : gotoPayment()">
                                                    Delete Employee <i class="icon-feather-trash-2"></i>
                                                </button>
                                            </div>
                                            <div v-else class="col-sm-4">
                                                <button class="btn ng-binding ng-scope btn-danger" @click="listPolicy.updateEmployee ? (activeEmployee ? activeEmployee = false : activeEmployee = true) : gotoPayment()" style="margin: 10px 0 10px 0;">
                                                    Deactivate
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px">
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-first-name" v-model="firstName" name="firstName" placeholder="Kiri" label="First Name" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-last-name" v-model="lastName" name="lastName" placeholder="Tsitouridis" label="Last Name" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-select :attach="true" v-model="gender" @change="changeInfoEmployee('gender', gender)" :items="listGender" label="Gender" required outlined color="success" ></v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Contact details</h5>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group outlined">
                                                    <label class="valid" for="">Address</label>
                                                    <vue-google-autocomplete v-on:keyup="keyupInput('address')" v-on:blur="blurInput('address')" v-on:focus="focusInput('address')" type="text" v-bind:class="(isCheckFindMyAddress)?'disabled-address': ''" country="au" types="address" id="address" v-on:placechanged="getAddressData" ref="googlemap" v-model="address" class="form-control ng-address isNotNull"></vue-google-autocomplete>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-check" style="margin-bottom: 40px;">
                                            <input v-model="isCheckFindMyAddress" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="isCheckFindMyAddress" ng-model="companyData.le.lsl">
                                            <label class="form-check-label" for="isCheckFindMyAddress">
                                                I am unable to find my address
                                            </label>
                                        </div>
                                        <div v-show="isCheckFindMyAddress">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-address1" v-model="address1" name="address1" placeholder="1 Shoreline Dr" label="Address 1" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group outlined">
                                                        <label class="valid" for="">Address 2</label>
                                                        <vue-google-autocomplete v-on:keyup="keyupInput('address2')" v-on:blur="blurInput('address2')" v-on:focus="focusInput('address2')" placeholder="1 Shoreline Dr, Rhodes, NSW" id="address2" v-model="address2" ref="googlemap2" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="address2" type="text"></vue-google-autocomplete>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-suburb" v-model="suburb" name="suburb" placeholder="Rhodes" label="Suburb" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-post-code" v-model="postcode" name="postcode" placeholder="2138" label="Postcode" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <v-select :attach="true" class="ng-state" v-bind:disabled="countryCode != 'au'" v-model="state" @change="changeInfoEmployee('state', state)" :items="listState" item-text="text" item-value="value" label="State" :rules="[rules.required]" required outlined color="success" ></v-select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group outlined">
                                                        <label class="valid" style="z-index: 1;" for=""> Country</label>
                                                        <country-flag :country='countryCode' size='normal' class="flag-input" />
                                                        <el-autocomplete @focus="clickAutocomplete()" @blur="getBackAutocompete()" v-model="country" name="country" class="inline-input form-control" style="width: 100%; !important;" :fetch-suggestions="querySearch" placeholder="Please Input" @select="changeLanguage">
                                                            <template slot-scope="{ item }">
                                                                <div style="display: flex; width: max-content;">
                                                                    <country-flag :country='item.value' size='normal' />
                                                                    <div class="value">{{ item.text }}</div>
                                                                </div>
                                                            </template>
                                                        </el-autocomplete>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-email" v-model="email" name="email" placeholder="email@company.com" label="Email address" type="email" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-phone" v-model="contactNumber" name="contactNumber" placeholder="0400 000 000" label="Contact number" type="phone" maxlength="40" :rules="[rules.isPhone]" required outlined color="success"  @input="formatPhoneNumber"></v-text-field>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Notes</h5>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <textarea v-model="notes" name="notes" class="form-control" rows="3" placeholder="Use this space to keep track of any extra information about the employee. For example, leave dates, emergency contact details or skills and qualifications."></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Employment -->
                                    <div v-show="this.styleTab == 'Employment'" class="text-left" ng-show="currentTab == 'employment'" style="">
                                        <h5>Employment</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-job-title" v-model="job" name="job" placeholder="Product Manager" label="Job title" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-start-date" v-model="startDate" name="startDate.date" label="Start Date" type="date" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-employment" @change="onEmploymentTypeChange()" v-model="employmentType" :items="listEmploymentType" label="Employment Type" required outlined color="success" ></v-select>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-weekly-work-hours" v-show="employmentType != 'FullTime'" v-model="weeklyWorkHours" name="weeklyWorkHours" placeholder="40" label="Weekly work hours" step="0.01" type="number" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                    <v-text-field class="ng-weekly-work-hours" v-show="employmentType == 'FullTime'" :readonly="isLock" v-model="weeklyWorkHours" name="weeklyWorkHours" placeholder="40" label="Weekly work hours" step="0.01" type="number" maxlength="40" :rules="[rules.required]" required outlined color="success" :prepend-inner-icon="isLock ? 'mdi-lock' : 'mdi-lock-open'" @click:prepend-inner="isLock = !isLock"></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 ng-scope">
                                                <div v-show="employmentType != 'Casual'" class="form-group">
                                                    <!-- <label for="leaveLoading">Leave Loading <i class="icon-feather-help-circle text-primary" style="vertical-align: middle;"></i></label>
                                                    <div class="input-group">
                                                        <input v-model.number="leaveLoading" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" id="leaveLoading">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text">
                                                                %
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <v-text-field v-model.number="leaveLoading" name="leaveLoading" placeholder="0" label="Leave Loading (%)" step="0.01" type="number" maxlength="40" required outlined color="success"></v-text-field>
                                                </div>
                                                <div v-show="employmentType == 'Casual'" class="form-group">
                                                    <!-- <label for="leaveLoading">Casual Loading</label>
                                                    <div class="input-group">
                                                        <input v-model.number="casualLoading" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" id="leaveLoading">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text">
                                                                %
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <v-text-field v-model.number="casualLoading" name="casualLoading" placeholder="25" label="Casual Loading (%)" step="0.01" type="number" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <!-- <label for="payPeriod">Pay Period</label>
                                                    <select v-model="payPeriod" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state">
                                                        <option value="All" selected="selected">All</option>
                                                        <option value="Weekly">Weekly</option>
                                                        <option value="Fortnightly">Fortnightly</option>
                                                        <option value="Monthly">Monthly</option>
                                                    </select> -->
                                                    <v-select :attach="true" v-model="payPeriod" @change="changeInfoEmployee('payPeriod', payPeriod)" :items="listPayPeriod" label="Pay Period" required outlined color="success" ></v-select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-pay-rate" v-model="payRate" name="payRate" placeholder="40" label="Pay Rate" step="0.01" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                    <!-- <label for="payRate">Pay Rate <span class="text-danger">*</span></label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">$</div>
                                                        </div>
                                                        <input v-model.number="payRate" type="number" step=".00001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-pay-rate ng-valid-step" id="payRate">
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group mb-3">
                                                    <v-select :attach="true" v-model="typeRate" @change="changeInfoEmployee('typeRate', typeRate)" :items="listTypeRate" required outlined color="success" ></v-select>
                                                    <!-- <select v-model="typeRate" @change="getUnit($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state" ng-model="employeeDetails3_edit.pu">
                                                        <option value="PerHour" selected="selected">per hour</option>
                                                        <option value="PerDay">per day</option>
                                                        <option value="PerWeek">per week</option>
                                                        <option value="PerAnnum">per annum</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row" v-show="reFormatMoney(payRate) > 0">
                                            <div class="col-4"></div>
                                            <div class="col-8">
                                                <div v-if="typeRate != 'PerHour'" class="text-success ng-binding" style="position: absolute; top: -30px">
                                                    Equivalent to ${{payRateUI()}} per hour
                                                    <i class="icon-feather-help-circle text-primary ng-hide" ng-click="hourlyRateInfo()" ng-show="employeeDetails_new.ty === 'c'"></i>
                                                </div>
                                                <div v-else class="text-success ng-binding" style="position: absolute; top: -30px">
                                                    Equivalent to ${{payRateUI()}} per year
                                                    <i class="icon-feather-help-circle text-primary ng-hide" ng-click="hourlyRateInfo()" ng-show="employeeDetails_new.ty === 'c'"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Bank -->
                                        <h5 style="margin-top: 10px">Payment Method</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-bank" v-model="paymentMethod" @change="changeInfoEmployee('bank.paymentMethod', paymentMethod)" :items="listPaymentMethod" required outlined color="success" ></v-select>
                                                    <!-- <select v-model="paymentMethod" @change="onChangeMethod($event)" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-bank" name="state">
                                                        <option value="Bank">Bank</option>
                                                        <option value="Cash" selected="selected">Cash</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fade ng-scope" v-if="paymentMethod === 'Bank'" style="">
                                            <div class="col-sm-5">
                                                <div class="form-group">
                                                    <v-text-field class="ng-account-name" v-model="bankAccountName" name="bank.bankAccountName" placeholder="" label="Account Name" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Account Name <span class="text-danger">*</span></label>
                                                    <input v-model="bankAccountName" class="form-control ng-pristine ng-untouched ng-account-name ng-invalid ng-invalid-required ng-valid-maxlength" placeholder="" type="text" name="accountName" maxlength="32"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <v-text-field class="ng-bsb" v-model="bankAccountBSB" name="bank.bankAccountBSB" placeholder="" label="BSB" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    <!-- <label for="">BSB <span class="text-danger">*</span></label>
                                                    <input v-model="bankAccountBSB" class="form-control ng-pristine ng-untouched ng-bsb ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength" name="bsb" type="text" maxlength="6"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-account-number" v-model="bankAccountNumber" name="bank.bankAccountNumber" placeholder="" label="Acc. Number" type="text" maxlength="9" :rules="[rules.required]" required outlined color="success"  messages="6~9 Digits"></v-text-field>
                                                    <!-- <label for="">Acc. Number <span class="text-danger">*</span></label>
                                                    <input v-model="bankAccountNumber" class="form-control ng-pristine ng-untouched ng-account-number ng-invalid ng-invalid-required ng-valid-pattern ng-valid-maxlength" name="accountNumber" type="text" maxlength="9">
                                                    <small>6-9 Digits</small> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Tax -->
                                    <div v-show="this.styleTab == 'Tax'" class="text-left" ng-show="currentTab == 'tax'" style="">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <v-text-field class="ng-birth-date" v-model="birthDate" name="birthDate.date" placeholder="" label="Date of Birth" type="date" maxlength="40" :rules="[rules.required]" required outlined color="success" ></v-text-field>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-Status-Residency" v-model="statusResidency" @change="statusResidencyChange()" label="Status of Residency" :items="listStatusResidency" required outlined color="success" ></v-select>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group" style="margin-bottom:2px">
                                                    <v-text-field class="ng-tax-file-number" v-show="!isExemption" v-model="taxNumber" name="taxNumber" placeholder="" label="Tax File Number" type="text" maxlength="40" outlined color="success" ></v-text-field>
                                                    <v-select :attach="true" v-show="isExemption" v-model="tfnExemption" label="Tax File Number" :items="listTfnExemption" outlined color="success" ></v-select>
                                                    
                                                </div>
                                                <div style="position: absolute; top: 50px">
                                                    <div class="small-label os-dropdown-trigger text-primary" v-show="!isExemption" v-on:click="clickTaxFileNumber()">No tax file number?</div>
                                                    <div class="small-label os-dropdown-trigger text-primary" v-show="isExemption" v-on:click="clickTaxFileNumber()">Has TFN?</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <h6>Additional Information</h6>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="claimTaxFreeThreshold" name="claimTaxFreeThreshold" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="claimTaxFreeThreshold">
                                                    <label class="form-check-label" for="claimTaxFreeThreshold">
                                                        Claim tax free threshold
                                                    </label>
                                                </div>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="hasHecsOrHelpDebt" name="hasHecsOrHelpDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasHecsOrHelpDebt">
                                                    <label class="form-check-label" for="hasHecsOrHelpDebt">
                                                        Has HECS or HELP debt
                                                    </label>
                                                </div>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="hasTslOrStudentStart" name="hasTslOrStudentStart" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasTslOrStudentStart">
                                                    <label class="form-check-label" for="hasTslOrStudentStart">
                                                        Has TSL or Student Start-up Loan
                                                    </label>
                                                </div>
                                                <!-- <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="hasFinancialSupplementDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="hasFinancialSupplementDebt">
                                                    <label class="form-check-label" for="hasFinancialSupplementDebt">
                                                        Has Financial Supplement debt
                                                    </label>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-12">
                                                <div @click="checkOverwriteTax()" class="row text-left" style="margin-top: 15px;">
                                                    <v-app class="text-left" style="margin-left: 20px;">
                                                        <v-switch id="overwriteTax" v-model="hasTaxTemplate" name="hasTaxTemplate" inset style="margin-top: 0px;"></v-switch>
                                                    </v-app>
                                                    <div class="text-right" style="margin-top: 3px;">
                                                        <label for="overwriteTax">Overwrite tax</label>
                                                    </div>
                                                </div>
                                                <!-- <h6>Overwrite tax</h6>
                                                <div @click="checkOverwriteTax()" class="form-check" style="margin: 5px 0; width: 105px">
                                                    <input v-model="hasTaxTemplate" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty col-2" type="checkbox" value="" id="hasTaxTemplate">
                                                    <label class="form-check-label" for="hasTaxTemplate">
                                                        Overwrite tax
                                                    </label>
                                                </div> -->
                                            </div>
                                            <div v-show="hasTaxTemplate" class="input-group col-6" style="margin-top: 5px;">
                                                <v-text-field class="ng-taxTemplate" v-model="taxTemplate" name="taxTemplate" placeholder="" step="0.01" type="number" maxlength="40" outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                <!-- <div class="input-group-prepend">
                                                    <div class="input-group-text">$</div>
                                                </div>
                                                <input v-model.number="taxTemplate" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9"> -->
                                            </div>
                                        </div>
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-12">
                                                <h6>Tax File Number Declaration</h6>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="taxFileNumberDeclaration" name="taxFileNumberDeclaration" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="taxFileNumberDeclaration">
                                                    <label class="form-check-label" for="taxFileNumberDeclaration">
                                                        Tick this box if you would like to lodge TFN Declaration in the next STP lodgement. By ticking this, you also
                                                        declare that the information you have given is true and correct.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Opening balances -->
                                    <form v-show="this.styleTab == 'Opening'" name="bankForm" class="text-left ng-pristine ng-valid ng-valid-step" ng-show="currentTab == 'openingBalances'" style="">
                                        <h5>Leave Balances</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="annualLeaveBalance" name="annualLeaveBalance" label="Annual leave balance" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="annualLeaveBalance">Annual leave balance</label>
                                                    <input v-model.number="annualLeaveBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.00001" id="annualLeaveBalance" type="number"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6 ng-hide" ng-show="company.le.lsl">
                                                <div class="form-group">
                                                    <v-text-field v-model="annualLeaveBalance" name="annualLeaveBalance" label="Long service leave balance" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="longServiceLeave">Long service leave balance</label>
                                                    <input v-model="annualLeaveBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" id="longServiceLeave" step="0.00001" type="number"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="sickLeaveBalance" name="sickLeaveBalance" label="Sick leave balance" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Sick leave balance</label>
                                                    <input v-model.number="sickLeaveBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.00001" type="number"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Opening Balances</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-time1" v-model="asAtBalance" name="asAtBalance.date" label="As at" placeholder="0" type="date" :rules="[rules.required]" maxlength="40" outlined color="success" ></v-text-field>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="grossEarningsBalance" name="grossEarningsBalance" label="Gross Earnings" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success"  messages="(Subject to tax)"></v-text-field>
                                                    <!-- <label for="">Gross Earnings (Subject to tax)</label>
                                                    <input v-model.number="grossEarningsBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="taxBalance" name="taxBalance" label="Tax Balance" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Tax Balance</label>
                                                    <input v-model="taxBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="superGuaranteeBalance" name="superGuaranteeBalance" label="Super Guarantee" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Super Guarantee</label>
                                                    <input v-model.number="superGuaranteeBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="employerContributionBalance" name="employerContributionBalance" label="Employer Contribution" placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Employer Contribution</label>
                                                    <input v-model.number="employerContributionBalance" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <!-- Template -->
                                    <form v-show="this.styleTab == 'Template'" name="template" class="text-left ng-pristine ng-valid ng-valid-step" ng-show="currentTab == 'template'" style="">
                                        <h5>Earnings</h5>
                                        <div class="small pb-3">You can change ordinary earning settings in the <span class="text-primary pointer" v-on:click="setStyleTab('Employment')">Employment section</span>.</div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                Ordinary work hours
                                            </div>
                                            <div class="col-sm-3 pb-1">
                                                <div class="input-group">
                                                    <v-text-field :value="getWorkHourOrdinary()" label="Hour" disabled placeholder="0" step="0.01" type="number" maxlength="40" outlined color="success"></v-text-field>
                                                    <!-- <div class="form-control ng-binding" style="background:#f5f5f6">{{getWorkHourOrdinary()}}</div>
                                                    <div class="input-group-append">
                                                        <div class="input-group-text">hr</div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-3 pb-1">
                                                <div class="input-group">
                                                    <v-text-field :value="payRatePerHourUI()" label="Rate" disabled placeholder="0" step="0.01" type="text" maxlength="40" outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                    <!-- <div class="input-group-prepend">
                                                        <div class="input-group-text">$</div>
                                                    </div>
                                                    <div class="form-control ng-binding" style="background:#f5f5f6">{{payRatePerHourUI()}}</div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Add Extra hours  -->
                                        <div v-show="this.isAddExtrahours || extraHours.length >  0">
                                            <div v-show="extraHours.length >  0" v-for="(item, index) in extraHours" :key="item.id">
                                                <div class="row ng-scope" ng-repeat="extraHours in employeeDetails6_edit.tm.eh" style="margin-bottom: 5px;">
                                                    <div class="col-12" style="margin-top: 25px;">
                                                        <!-- <hr>
                                                        Extra hours &nbsp;
                                                        <span class="text-primary pointer ng-binding" style="font-size:11px" ng-click="extraHours.el = !extraHours.el" ng-show="!extraHours.ov">Leave accrued</span> -->
                                                        <div v-on:click="removeExtrahours(index)" class="text-danger text-right os-dropdown-trigger" style="margin-top: -21px;">
                                                            <i class="icon-feather-trash-2"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <v-select :attach="true" v-model="item.option" @change="changeInfoEmployee('listExtraHours.'+index+'.option', item.option)" label="Option" :items="listOptionExtraHours" outlined color="success" ></v-select>
                                                        <!-- <select v-model="item.option" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state" ng-model="extraHours.ov" style="margin-bottom:3px" ng-options="o.v as o.n for o in [{ n: 'Ordinary hours', v: false }, { n: 'Overtime - exempt from super', v: true }]">
                                                            <option label="Ordinary hours" value="ORDINARY_HOURS" selected="selected">Ordinary hours</option>
                                                            <option label="Overtime - exempt from super" value="OVERTIME_EXEMPT_FROM_SUPER">Overtime - exempt from super</option>
                                                        </select>
                                                        <div class="small-label">Option</div> -->
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <v-text-field v-model="item.name" :name="'listExtraHours.'+index+'.name'" label="Name" placeholder="Sunday" type="text" maxlength="40" outlined color="success" ></v-text-field>
                                                        <!-- <div class="input-group" style="margin-bottom:3px">
                                                            <input v-model="item.name" type="text" class="form-control ng-pristine ng-untouched ng-valid ng-empty" placeholder="eg. Sunday">
                                                        </div>
                                                        <div class="small-label">Name</div> -->
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div class="input-group" style="margin-bottom:3px">
                                                            <v-text-field v-model="item.hour" :name="'listExtraHours.'+index+'.hour'" label="Hour" placeholder="0" step=".01" type="number" maxlength="40" outlined color="success"></v-text-field>
                                                            <!-- <input v-model.number="item.hour" type="number" step=".01" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step">
                                                            <div class="input-group-append">
                                                                <div class="input-group-text">hr</div>
                                                            </div> -->
                                                        </div>
                                                        <!-- <div class="small-label">Hour</div> -->
                                                    </div>
                                                    <div class="col-sm-3">
                                                        <div class="input-group" style="margin-bottom:3px">
                                                            <v-text-field v-model="item.rate" :name="'listExtraHours.'+index+'.rate'" label="Rate"  placeholder="0" step=".01" type="number" maxlength="40" outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                            <!-- <div class="input-group-prepend">
                                                                <div class="input-group-text">$</div>
                                                            </div>
                                                            <input v-model.number="item.rate" type="number" step=".00001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step"> -->
                                                        </div>
                                                        <!-- <div class="small-label">Rate</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <hr ng-if="employeeDetails6_edit.tm.eh.length>0" class="ng-scope" style="">
                                        </div>
                                        <div class="col-12 mb-5 text-primary os-dropdown-trigger" v-on:click="addExtrahours()">
                                            + Add Extra hours
                                        </div>
                                        <!--Add Allowance  -->
                                        <h5>Allowance</h5>
                                        <div v-show="this.isAddAllowance || allowance.length >  0">
                                            <div v-show="allowance.length >  0" v-for="(item, index) in allowance" :key="item.id">
                                                <div class="row ng-scope" ng-repeat="allowance in employeeDetails6_edit.tm.alw" style="margin-bottom: 5px;">
                                                    <div class="col-12" style="margin-top: 25px;">
                                                        <!-- <hr>
                                                        Allowance -->
                                                        <div class="text-danger text-right os-dropdown-trigger" v-on:click="removeAllowance(index)" style="margin-top: -21px;">
                                                            <i class="icon-feather-trash-2"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="input-group" style="margin-bottom:6px">
                                                            <v-text-field v-model="item.unit" :name="'listAllowance.'+index+'.unit'" label="Unit" placeholder="0" step=".0001" type="number" maxlength="40" outlined color="success"></v-text-field>
                                                            <!-- <input v-model.number="item.unit" type="number" step=".0001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step">
                                                            <div class="input-group-append">
                                                                <div class="input-group-text">unit</div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="input-group">
                                                            <v-text-field v-model="item.cost" :name="'listAllowance.'+index+'.cost'" label="Rate" placeholder="0" step=".0001" type="number" maxlength="40" outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                            <!-- <div class="input-group-prepend">
                                                                <div class="input-group-text">$</div>
                                                            </div>
                                                            <input v-model.number="item.cost" type="number" step=".00001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step"> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <v-select :attach="true" v-model="item.option" @change="changeInfoEmployee('listAllowance.'+index+'.option', item.option)" label="Option" :items="listOptionAllowance" outlined color="success" ></v-select>
                                                        <!-- <select v-model="item.option" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state">
                                                            <option value="SUBJECT_TO_TAX_AND_SUPER" selected="selected">Subject to tax and super</option>
                                                            <option value="SUBJECT_TO_TAX_EXEMPT_FROM_SUPER">Subject to tax, Exempt from super</option>
                                                            <option value="EXEMPT_FROM_TAX_AND_SUPER">Exempt from tax and super</option>
                                                        </select>
                                                        <div class="small-label">Option</div> -->
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <v-select :attach="true" v-model="item.type" @change="changeInfoEmployee('listAllowance.'+index+'.type', item.type)" label="Type" :items="listTypeAllowance" outlined color="success" ></v-select>
                                                        <!-- <select v-model="item.type" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state">
                                                            <option value="CAR">Car</option>
                                                            <option value="TRANSPORT">Transport</option>
                                                            <option value="LAUNDRY">Laundry</option>
                                                            <option value="MEALS">Meals</option>
                                                            <option value="TRAVEL">Travel</option>
                                                            <option value="OTHER" selected="selected">Other</option>
                                                        </select>
                                                        <div class="small-label">Type</div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-5 text-primary os-dropdown-trigger" v-on:click="addAllowance()">
                                            + Add Allowance
                                        </div>
                                        <!-- Add Deduction -->
                                        <h5>Deduction</h5>
                                        <div v-show="this.isAddDeduction || deduction.length">
                                            <div v-show="deduction.length >  0" v-for="(item, index) in deduction" :key="item.id">
                                                <div class="row ng-scope" ng-repeat="deduction in employeeDetails6_edit.tm.ded" style="margin-bottom: 5px;">
                                                    <div class="col-12" style="margin-top: 25px;">
                                                        <!-- Deduction -->
                                                        <div class="text-danger text-right os-dropdown-trigger" v-on:click="removeDeduction(index)" style="margin-top: -21px;">
                                                            <i class="icon-feather-trash-2"></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <v-select :attach="true" v-model="item.option" @change="changeInfoEmployee('listDeduction.'+index+'.option', item.option)" label="Option" :items="listOptionDeduction" outlined color="success" ></v-select>
                                                        <!-- <select v-model="item.option" class="form-control ng-pristine ng-untouched ng-valid ngempty" name="state">
                                                            <option value="POST_TAX_DEDUCTION">Post tax deduction</option>
                                                            <option value="PRE_TAX_DEDUCTION" selected="selected">Pre tax deduction</option>
                                                        </select>
                                                        <div class="small-label">Option</div> -->
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <v-select :attach="true" v-model="item.type" @change="changeInfoEmployee('listDeduction.'+index+'.type', item.type)" label="Type" :items="listTypeDeduction" outlined color="success" ></v-select>
                                                        <!-- <select v-model="item.type" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="state">
                                                            <option value="FEES" selected="selected">Fees</option>
                                                            <option value="WORKPLACE_GIVING">Workplace Giving</option>
                                                        </select>
                                                        <div class="small-label">Type</div> -->
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="input-group">
                                                            <v-text-field v-model="item.amountDeduction" :name="'listDeduction.'+index+'.amountDeduction'" label="Rate" placeholder="0" step=".0001" type="number" maxlength="40" outlined color="success"  prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                            <!-- <div class="input-group-prepend">
                                                                <div class="input-group-text">$</div>
                                                            </div>
                                                            <input v-model.number="item.amountDeduction" type="number" step=".00001" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step"> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <hr>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-5 text-primary os-dropdown-trigger" v-on:click="addDeduction()">
                                            + Add Deduction
                                        </div>
                                        <h5>Superannuation</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" v-model="superFundType" @change="changeInfoEmployee('fund.superFundType', superFundType)" label="Type" :items="listTypeSuper" outlined color="success" ></v-select>
                                                    <!-- <label for="">Type</label>
                                                    <div class="form-group">
                                                        <select class="form-control ng-pristine ng-untouched ng-valid ng-not-empty" name="state" v-model="superFundType">
                                                            <option value="Regulated Superannuation Fund" selected="selected">Regulated Superannuation Fund</option>
                                                            <option value="Self Managed Superannuation Fund">Self Managed Superannuation Fund</option>
                                                        </select>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-Contribution-Rate" v-model="contributionRate" name="fund.contributionRate" label="Contribution Rate (%)" placeholder="9.5" step=".01" type="number" maxlength="40" :rules="[rules.required]" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Contribution Rate (%)<span class="text-danger">*</span></label>
                                                    <input v-model.number="contributionRate" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-Contribution-Rate" placeholder="9.5" type="text"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="employerContribution" name="employerContribution" label="Employer Contribution" placeholder="0" step=".01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Employer Contribution</label>
                                                    <input v-model.number="employerContribution" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" ng-model="employeeDetails6_edit.tm.s_ec" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="superSalarySacrifice" name="superSalarySacrifice" label="Super Salary Sacrifice" placeholder="0" step=".01" type="number" maxlength="40" outlined color="success" ></v-text-field>
                                                    <!-- <label for="">Super Salary Sacrifice</label>
                                                    <input v-model.number="superSalarySacrifice" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-step" ng-model="employeeDetails6_edit.tm.s_ss" step="0.01" type="number"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="superFundType === 'Self Managed Superannuation Fund'" class="ng-scope">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Name" v-model="fundName" name="fund.fundName" label="Name" placeholder="" type="text" maxlength="40" :rules="[rules.required]" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">Name <span class="text-danger">*</span></label>
                                                        <input v-model="fundName" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-Sf-Name" type="text" name="self-managed-fund-name"> -->
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-ABN" v-model="fundABN" name="fund.fundABN" label="ABN" placeholder="" type="text" maxlength="11" :rules="[rules.required]" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">ABN <span class="text-danger">*</span></label>
                                                        <input v-model="fundABN" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-maxlength ng-Sf-ABN" type="text" name="self-managed-fund-abn" maxlength="11"> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field v-model="fundAddress" name="fund.fundAddress" label="Electronic Service Address" placeholder="" type="text" maxlength="40" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">Electronic Service Address</label>
                                                        <input v-model="fundAddress" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text" name="self-managed-fund-electronic-service-address" ng-model="employeeDetails_new.su.sm.esa"> -->
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field v-model="memberNumber" name="fund.memberNumber" label="Employer Number (optional)" placeholder="" type="text" maxlength="40" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">Employer Number (optional)</label>
                                                        <input v-model="fundEmployerNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text" name="self-managed-fund-employer-number" ng-model="employeeDetails_new.su.sm.en"> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Bank details</h6>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Account-Name" v-model="fundBankName" name="fund.fundBankName" label="Account Name" placeholder="" type="text" maxlength="40" :rules="[rules.required]" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">Account Name <span class="text-danger">*</span></label>
                                                        <input v-model="fundBankName" class="form-control ng-pristine ng-untouched ng-not-empty ng-invalid ng-invalid-required ng-valid-maxlength ng-Sf-Account-Name" placeholder="" type="text" name="selfSuperAccountName" required="" maxlength="32"> -->
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <v-text-field ng-Sf-BSB v-model="fundBankBSB" name="fund.fundBankBSB" label="BSB" placeholder="" type="text" maxlength="6" :rules="[rules.required]" outlined color="success" ></v-text-field>
                                                        <!-- <label for="">BSB <span class="text-danger">*</span></label>
                                                        <input v-model="fundBankBSB" class="form-control ng-pristine ng-untouched ng-not-empty ng-invalid ng-invalid-required ng-valid-maxlength ng-Sf-BSB" name="selfSuperBsb" type="text" required="" maxlength="6"> -->
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Account-Number" v-model="fundBankNumber" name="fund.fundBankNumber" label="Account Number" placeholder="" type="text" maxlength="9" :rules="[rules.required]" outlined color="success"  messages="6~9 digits"></v-text-field>
                                                        <!-- <label for="">Account Number <span class="text-danger">*</span> <small>6~9 digits</small></label>
                                                        <input v-model="fundBankNumber" class="form-control ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-pattern ng-valid-maxlength ng-Sf-Account-Number" name="selfSuperAccountNumber" type="text" ng-pattern="^[0-9-]{1,9}$" maxlength="9"> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="superFundType == 'Regulated Superannuation Fund'" class="ng-scope">
                                            <form v-on:submit.prevent="searchSupperFund()" action="" method="post">
                                                <div class="row">
                                                    <!-- <div class="col-sm-12">
                                                        <label for="">Search Super Company by Name, or ABN</label>
                                                    </div> -->
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <v-text-field v-model="edtSearchSupperFund" label="Search Super Company by Name, or ABN" placeholder="" type="text" maxlength="40" outlined color="success" ></v-text-field>
                                                            <!-- <input v-model="edtSearchSupperFund" class="form-control ng-pristine ng-untouched ng-valid ng-empty" type="text"> -->
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <button type="submit" class="btn btn-primary" style="margin-top:3px" ng-disabled="superSearching">
                                                                <span class="spinner-grow spinner-grow-sm ng-hide"></span>
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                            <div class="alert alert-green ng-hide" role="alert">
                                                <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                <table class="table table-sm table-border-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">ABN</th>
                                                            <th scope="col">USI</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-show="isShowListSupperFund && listSupperFund.length > 0" class="alert alert-green" role="alert" style="">
                                                <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                <table class="table table-sm table-border-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">ABN</th>
                                                            <th scope="col">USI</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item) in listSupperFund" :key="item.id" @click="detailSupperFund(item.identifierValue)" ng-repeat="superFundDetails in superList" class="text-primary-hover pointer ng-scope" style="">
                                                            <td class="ng-binding">{{item.organisationName}}</td>
                                                            <td class="ng-binding">{{item.identifierValue}}</td>
                                                            <td class="ng-binding">{{item.fundProductUsi}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <el-pagination class="pagination" @current-change="handleCurrentChange" v-show="isShowListSupperFund && totalPage > 1" background layout="prev, pager, next" :page-size=pageSize :total=totalPage>
                                            </el-pagination>
                                            <table v-show="isShowListSupperFund && listSupperFund.length == 0" style="width:100%" class="fade ng-scope text-danger">
                                                <tbody>
                                                    <tr>
                                                        <td><b>Sorry! We cannot find this Super Company.</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div v-show="isShowDetailSupperFund" class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Super Fund Name</label>
                                                        <div class="small-label text-danger text-right os-dropdown-trigger" style="margin-top: -26px;" @click="remoteSupperFund()">
                                                            <i class="icon-feather-trash-2"></i>
                                                        </div>
                                                        <div style="margin-top:10px" class="">
                                                            <b class="ng-binding"></b>
                                                            <b class="ng-binding" style="margin-top: 20px;">{{organisationName}}</b>
                                                        </div>
                                                        <div style="margin-top:10px" class="">
                                                            <b class="ng-binding"></b>
                                                            <div class="ng-binding">ABN : {{identifierValue}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field v-model="memberNumber" class="ng-number" label="Number" type="text" maxlength="40" outlined color="success" :rules="[rules.required]"></v-text-field>
                                                        <!-- <label for="">Number</label>
                                                        <input v-model="memberNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="superfund-number" placeholder="Member Number" type="text"> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </v-app>
                            <div class="modal-footer">
                                <div class=" col-lg-12 text-right">
                                    <button class="btn btn-light" data-dismiss="modal" type="button" v-on:click="closeModal()"> Close</button>
                                    <button class="btn btn-primary" type="button" v-on:click="listPolicy.updateEmployee ? updateEmployee() : gotoPayment()" ng-disabled="loading">
                                        <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span> Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

    <!--------------------
    START - popup Confirm discard changes
    -------------------->
    <div id="popupConfirm" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box521106" tabindex="-1" style="margin-top: 50%; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Confirm discard changes</span></div>
                            <div class="ng-confirm-content-pane" style="height: 43.2px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box521106">
                                    <div class="ng-scope">Your changes have not been saved. Discard changes?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons"><button v-on:click="this.clickOk" type="button" class="btn btn-primary"><span class="ng-confirm-btn-text">OK</span></button><button v-on:click="hidePopupConfirm()" type="button" class="btn btn-default"><span class="ng-confirm-btn-text">close</span></button></div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup Confirm discard changes
    -------------------->

    <!--------------------
    START - popup Delete Employee
    -------------------->
    <div id="popupDeleteemployee" class="ng-confirm ng-confirm-white ng-confirm-type-default hide-popup">
        <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
        <div v-on:click="this.hilight" class="ng-confirm-scrollpane">
            <div class="ng-bs3-container container">
                <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                    <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                        <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box271355" tabindex="-1" style="margin-top: 50%; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                            <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                            <div class="ng-confirm-title-c"><span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span><span class="ng-confirm-title">Delete your employee</span></div>
                            <div class="ng-confirm-content-pane" style="height: 86.4px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-content" id="ng-confirm-box271355">
                                    <div class="ng-scope">Please note that the employee data cannot be restored once you delete it. Would you like to proceed?</div>
                                </div>
                            </div>
                            <div class="ng-confirm-buttons">
                                <button v-on:click="deleteEmPloyee()" type="button" id="btnDeleteemployee" class="btn btn-primary">
                                    <span class="ng-confirm-btn-text">Yes!</span>
                                </button>
                                <button v-on:click="this.hidePopupDelete" type="button" id="btnCancelDeleteemployee" class="btn btn-default">
                                    <span class="ng-confirm-btn-text">close</span>
                                </button>
                            </div>
                            <div class="ng-confirm-clear"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------
    END - popup Confirm discard changes
    -------------------->
</div>
</template>

<script>
// import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Logs from "@/utils/Logs.js";
import * as Session from "@/utils/SessionStore"
import * as Countries from "@/utils/Countries"
import * as DataSelect from "@/utils/DataSelect.js";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import CountryFlag from 'vue-country-flag'

import {
    DELETE_EMPLOYEE,
    SEARCH_SUPERFUND,
    DETAIL_SUPERFUND,
    UPDATE_EMPLOYEE,
    CHECK_EMAIL,
    CHECK_CONTACT_NUMBER,
    CHECK_TAX_NUMBER
} from "@/store/actionsType";

export default {
    name: "EditEmployeeModal",
    props: [],
    components: {
        // DatePicker,
        VueGoogleAutocomplete,
        CountryFlag
    },
    data() {
        return {
            listPolicy: {
                updateEmployee: true,
                deleteEmployee: true
            },
            arrayChange: {
                add: {},
                remove: [],
                changes: []
            },
            ogirinData: null,
            countries:[],
            isLock: true,
            isValidate: true,
            isClickTax: false,
            employee: "",
            styleTab: "Profile",
            time1: null,
            address: "",
            isCheckFindMyAddress: false,
            //******Profile******
            firstName: "",
            lastName: "",
            gender: "",
            avatar: "",
            email: "",
            contactNumber: "",
            address1: "",
            address2: "",
            suburb: "",
            postcode: "",
            state: "",
            countryCode: "",
            country: "",
            active: "",
            activeEmployee: "",
            //******Bank and Super******
            //===> Bank
            paymentMethod: "Cash",
            bankAccountName: "",
            bankAccountBSB: "",
            bankAccountNumber: "",
            //===> Supper Fund
            superFundType: "Regulated Superannuation Fund",
            contributionRate: 9.5,
            fundName: "",
            fundABN: "",
            fundAddress: "",
            fundUSI: "",
            fundEmployerNumber: "",
            memberNumber: "",
            fundBankName: "",
            fundBankBSB: "",
            fundBankNumber: "",
            totalPage: 0,
            pageSize: 10,
            currenPage: 1,
            //===> isCheck
            listSupperFund: [],
            listSupperFundFull: [],
            isShowListSupperFund: false,
            edtSearchSupperFund: "",
            isShowDetailSupperFund: false,
            organisationName: "",
            identifierValue: "",
            //******Employment******
            job: "",
            startDate: "",
            // startDateDay: "",
            // startDateMonth: "",
            // startDateYear: "",
            employmentType: "",
            weeklyWorkHours: "",
            leaveLoading: 0,
            casualLoading: 0,
            payPeriod: "",
            payRatePerHour: "",
            payRate: "",
            typeRate: "PerHour",
            //******Tax******
            birthDate: "",
            // birthDateDay: "",
            // birthDateMonth: "",
            // birthDateYear: "",
            statusResidency: "",
            isExemption: false,
            tfnExemption: "NoTFNQuoted",
            taxNumber: "",
            claimTaxFreeThreshold: false,
            hasHecsOrHelpDebt: false,
            hasTslOrStudentStart: false,
            hasFinancialSupplementDebt: false,
            taxFileNumberDeclaration: false,
            hasTaxTemplate: false,
            taxTemplate: 0,
            //******Opening balances******
            annualLeaveBalance: 0,
            sickLeaveBalance: 0,
            asAtBalance: "",
            grossEarningsBalance: 0,
            taxBalance: 0,
            superGuaranteeBalance: 0,
            employerContributionBalance: 0,
            //******Template******
            // data for earnings
            isAddExtrahours: false,
            extraHours: [],
            // data for allowance
            isAddAllowance: false,
            allowance: [],
            // data for deduction
            isAddDeduction: false,
            deduction: [],
            bonus: [],
            termination: [],
            superGuarantee: 0,
            employerContribution: 0,
            superSalarySacrifice: 0,
            // note
            notes: null,
            weeklyWorkHoursDefault: 0,
            rules: {
                required: value => !!value || '',
                animal: [val => (val || '').length > 0 || 'This field is required'],
                isPhone: val => !isNaN(val.replace(/[\s()+]+/g, '')) && 
                (
                    ((val.indexOf('0') == 0 && val.replace(/\s+/g, '').length <= 10) || 
                    (val.indexOf('+') == 1 && val.replace(/\s+/g, '').length <= 14)
                    ) || 'Phone numbers have a maximum of 10 numbers'
                )
                || "phone number must be number",
                //min: v => v.length >= 0 || 'Min 8 characters',
                // emailMatch: () => (`The email and password you entered don't match`),
            },
            //Data
            listGender: DataSelect.getList('listGender'),
            listState: DataSelect.getList('listState'),
            listEmploymentType: DataSelect.getList('listEmploymentType'),
            listPayPeriod: DataSelect.getList('listPayPeriod'),
            listTypeRate: DataSelect.getList('listTypeRate'),
            listPaymentMethod: DataSelect.getList('listPaymentMethod'),
            listStatusResidency: DataSelect.getList('listStatusResidency'),
            listTfnExemption: DataSelect.getList('listTfnExemption'),
            listOptionExtraHours: DataSelect.getList('listOptionExtraHours'),
            listOptionAllowance: DataSelect.getList('listOptionAllowance'),
            listTypeAllowance: DataSelect.getList('listTypeAllowance'),
            listOptionDeduction: DataSelect.getList('listOptionDeduction'),
            listTypeDeduction: DataSelect.getList('listTypeDeduction'),
            listTypeSuper: DataSelect.getList('listTypeSuper'),
        };
    },
    methods: {
        checkActiveEmployee() {
            if(this.birthDate != "" && this.statusResidency != null && (this.taxNumber != "" || this.tfnExemption != null && this.contactNumber != "" && this.address1 != "" && this.suburb != "" && this.postcode != "" && this.state != null)) {
                this.activeEmployee = true
            } else {
                this.closeModal()
                this.$modal.show("modal-taxemployee", {
                    data: this.employee
                });
            }
        },
        focusInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            label.classList.add('focus')
            label.classList.add('valid')
        },
        blurInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            label.classList.remove('focus')
        },
        keyupInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            if(input.classList.contains('isNotNull')) {
                if(input.value == undefined || input.value == "") {
                    input.classList.add('is-invalid')
                    label.classList.add('text-danger')
                } else {
                    input.classList.remove('is-invalid')
                    label.classList.remove('text-danger')
                }
            }
        },
        showStatus(status) {
            if (this.$root.$refs.BaseLayout != undefined) {
                return this.$root.$refs.BaseLayout.showStatus(status);
            }
        },
        
        /**
         * Sets address data based on Google Autocomplete API response.
         * 
         * @param {object} addressData - Address data object returned from Google Autocomplete API.
         * 
         * Extracts relevant address information from the API response,
         * updates the component's address fields, and triggers a change event.
         */
        getAddressData(addressData) {
            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.address1 = street_number + addressData.route;
            this.suburb = addressData.locality;
            this.postCode = addressData.postal_code;
            this.countryCode = "au"
            this.country = Countries.getCountry("AU")
            this.state = addressData.administrative_area_level_1;
            this.address = this.address1 + ", " + this.suburb + ", " + this.state
            this.changeInfoEmployee("address", this.address)
        },

        newDirtyForm() {
            var inputs = window.$('input')
            for(var i = 0; i < inputs.length; i++) {
                if(inputs[i].name != "") {
                    inputs[i].addEventListener('change', (event) => {
                        this.changeInfoEmployee(event.target.name, event.target.value ? event.target.value : event.target.checked)
                    })
                }
            }
        },
        getOgirinValue(name) {
            var array = name.split('.')
            var json = this.ogirinData
            array.forEach(element => {
                if(element == 'date'){
                    json = this.getDate(json)
                } else {
                    json = json[element]
                }
            });
            return json
        },

        changeInfoEmployee(name, value) {
            var ogirinValue = this.getOgirinValue(name)
            var index = this.arrayChange.changes.indexOf(name)
            if(value != ogirinValue) {
                if(index == -1) {
                    this.arrayChange.changes.push(name)
                }
            } else {
                if (index != -1) {
                    this.arrayChange.changes.splice(index, 1)
                }
            }
        },

        /**
         * Checks if there are any unsaved changes and prompts the user to confirm discarding them.
         * 
         * If there are changes, shows a warning dialog asking the user to confirm discarding the changes.
         * If confirmed, resets the array of changes.
         * 
         * @return {boolean} true if no changes or changes are discarded, false if the user cancels
         */
        checkChangeInfoEmployee() {
            if (this.arrayChange.changes.length > 0) {
                return this.$swal({
                    title: 'Confirm discard changes',
                    text: "Your changes have not been saved. Discard changes?",
                    type: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Yes',
                    showCancelButton: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.arrayChange = {
                            add: {},
                            remove: [],
                            changes: []
                        }
                        return true
                    } else {
                        return false
                    }
                });
            } else {
                return true
            }
        },

        querySearch(queryString, cb) {
            var listDrop = this.countries;
            queryString = this.country
            var results = queryString ? listDrop.filter(this.createFilter(queryString)) : listDrop;
            cb(results);
        },
        clickAutocomplete() {
            this.country = ""
        },
        getBackAutocompete() {
            var timeout = setTimeout(() => {
                if(this.country == "") {
                    this.country = Countries.getCountry(this.countryCode)
                }
                clearTimeout(timeout)
            }, 300);
        },
        createFilter(queryString) {
            return (country) => {
                return (country.text.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        },
        changeLanguage(item) {
            this.country = item.text
            this.countryCode = item.value
            if(this.countryCode != "au") {
                this.state = "OTHER"
                window.$('.ng-state')[0].disabled = true
            } else {
                window.$('.ng-state')[0].disabled = false
            }
        },
        beforeOpen(event) {
            this.listPolicy = this.$root.$refs.BaseLayout.listPolicy
            this.countries = []
            var keys = Object.keys(Countries.getList());
            for(var i = 0; i < keys.length; i++) {
                this.countries.push({
                        "text": Countries.getCountry(keys[i]),
                        "value": keys[i].toLowerCase()
                    })
            }
            this.isLock = true;
            this.styleTab = "Profile",
            this.employee = event.params.employee
            Session.set('ogirinData', this.employee)
            this.ogirinData = Session.get('ogirinData')
            Session.remove('ogirinData')
            // Logs.json('employee', this.employee)
            // set data for Profile
            this.firstName = this.employee.firstName
            this.lastName = this.employee.lastName
            this.gender = this.employee.gender

            this.avatar = this.employee.avatar
            this.email = this.employee.email
            this.contactNumber = this.employee.contactNumber
            this.active = this.employee.active
            Logs.string('active', this.active)
            if (this.active == 'ACTIVE') {
                this.activeEmployee = true
            } else {
                Logs.stringKey('active false')
                this.activeEmployee = false
            }
            // set data for Employment
            this.job = this.employee.job
            if (this.employee.startDate != undefined && this.employee.startDate != '') {
                this.startDate = this.getDate(this.employee.startDate)
                // this.startDateDay = this.employee.startDate.substring(8, 10)
                // this.startDateMonth = this.employee.startDate.substring(5, 7)
                // this.startDateYear = this.employee.startDate.substring(0, 4)
            }
            this.employmentType = this.employee.employmentType
            this.weeklyWorkHours = this.employee.weeklyWorkHours
            this.leaveLoading = this.employee.leaveLoading
            this.casualLoading = this.employee.casualLoading
            this.payPeriod = this.employee.payPeriod
            this.payRatePerHour = this.employee.payRatePerHour
            this.payRate = this.employee.payRate
            this.typeRate = this.employee.typeRate
            this.weeklyWorkHoursDefault = Session.get("workHours") * 5

            if (this.active == 'PENDING') {
                this.address = ""
                this.address1 = ""
                this.address2 = ""
                this.suburb = ""
                this.postcode = ""
                this.state = null
                this.countryCode = "au"
                this.country = Countries.getCountry(this.countryCode)
                // set data for Bank and Super
                this.paymentMethod = "Cash"
                this.bankAccountName = ""
                this.bankAccountBSB = ""
                this.bankAccountNumber = ""
                this.superFundType = "Regulated Superannuation Fund"
                this.listSupperFund = [];
                this.isShowListSupperFund = false;
                this.remoteSupperFund();
                this.contributionRate = 9.5
                this.fundName = ""
                this.fundABN = ""
                this.fundAddress = ""
                this.fundUSI = ""
                this.fundEmployerNumber = ""
                this.memberNumber = ""
                this.fundBankName = ""
                this.fundBankBSB = ""
                this.fundBankNumber = ""
                // set data for Tax
                this.birthDate = ""
                // this.birthDateDay = ""
                // this.birthDateMonth = ""
                // this.birthDateYear = ""
                this.statusResidency = null
                this.isExemption = false
                this.tfnExemption = null
                this.taxNumber = ""
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                this.hasTslOrStudentStart = false
                this.hasFinancialSupplementDebt = false
                this.taxFileNumberDeclaration = false
                this.taxTemplate = 0
                this.hasTaxTemplate = false
                // set data for Opening balances
                this.annualLeaveBalance = 0
                this.sickLeaveBalance = 0
                this.asAtBalance = ""
                this.grossEarningsBalance = 0
                this.taxBalance = 0
                this.superGuaranteeBalance = 0
                this.employerContributionBalance = 0
                // set data for Template
                this.extraHours = []
                this.allowance = []
                this.deduction = []
                this.bonus = []
                this.termination = []
                // this.superGuarantee = this.employee.superGuarantee
                this.employerContribution = 0
                this.superSalarySacrifice = 0
                this.notes = ""
            } else {
                this.address1 = this.employee.address1
                this.address2 = this.employee.address2
                this.suburb = this.employee.suburb
                this.postcode = this.employee.postcode
                this.state = this.employee.state
                this.countryCode = this.employee.country
                if(this.countryCode == "au") {
                    this.address = this.address1 + ", " + this.suburb + ", " + this.state
                } else {
                    this.address = this.address1 + ", " + this.suburb
                }
                this.ogirinData.address = this.address
                this.country = Countries.getCountry(this.countryCode)
                // set data for Bank and Super
                this.paymentMethod = this.employee.bank.paymentMethod
                this.bankAccountName = this.employee.bank.bankAccountName
                this.bankAccountBSB = this.employee.bank.bankAccountBSB
                this.bankAccountNumber = this.employee.bank.bankAccountNumber
                this.listSupperFund = [];
                this.isShowListSupperFund = false;
                this.superFundType = this.employee.fund.superFundType
                this.remoteSupperFund();
                if (this.superFundType == 'Regulated Superannuation Fund' && (this.employee.fund.fundName != '' || this.employee.fund.fundABN != '')) {
                    this.organisationName = this.employee.fund.fundName
                    this.identifierValue = this.employee.fund.fundABN
                    this.isShowDetailSupperFund = true
                }
                this.contributionRate = this.employee.fund.contributionRate
                this.fundName = this.employee.fund.fundName
                this.fundABN = this.employee.fund.fundABN
                this.fundAddress = this.employee.fund.fundAddress
                this.fundUSI = this.employee.fund.fundUSI
                this.fundEmployerNumber = this.employee.fund.fundEmployerNumber
                this.memberNumber = this.employee.fund.memberNumber
                this.fundBankName = this.employee.fund.fundBankName
                this.fundBankBSB = this.employee.fund.fundBankBSB
                this.fundBankNumber = this.employee.fund.fundBankNumber
                // set data for Tax
                if (this.employee.birthDate != undefined && this.employee.birthDate != '') {
                    this.birthDate = this.getDate(this.employee.birthDate)
                    // this.birthDateDay = this.employee.birthDate.substring(8, 10)
                    // this.birthDateMonth = this.employee.birthDate.substring(5, 7)
                    // this.birthDateYear = this.employee.birthDate.substring(0, 4)
                }
                this.statusResidency = this.employee.statusResidency
                this.isExemption = this.employee.isExemption
                this.tfnExemption = this.employee.tfnExemption
                this.taxNumber = this.employee.taxNumber
                this.claimTaxFreeThreshold = this.employee.claimTaxFreeThreshold
                this.hasHecsOrHelpDebt = this.employee.hasHecsOrHelpDebt
                this.hasTslOrStudentStart = this.employee.hasTslOrStudentStart
                this.hasFinancialSupplementDebt = this.employee.hasFinancialSupplementDebt
                this.taxFileNumberDeclaration = this.employee.taxFileNumberDeclaration
                this.taxTemplate = this.employee.taxTemplate
                this.hasTaxTemplate = this.employee.hasTaxTemplate
                // set data for Opening balances
                this.annualLeaveBalance = this.employee.annualLeaveBalance
                this.sickLeaveBalance = this.employee.sickLeaveBalance

                if (this.getDate(this.employee.asAtBalance) === "1-01-01") {
                    this.asAtBalance = this.getDate(new Date())
                    this.ogirinData.asAtBalance = this.getDate(new Date())
                } else {
                    this.asAtBalance = this.getDate(this.employee.asAtBalance)
                }
                this.grossEarningsBalance = this.employee.grossEarningsBalance
                this.taxBalance = this.employee.taxBalance
                this.superGuaranteeBalance = this.employee.superGuaranteeBalance
                this.employerContributionBalance = this.employee.employerContributionBalance
                // set data for Template
                this.extraHours = this.employee.listExtraHours
                this.allowance = this.employee.listAllowance
                this.deduction = this.employee.listDeduction
                this.bonus = this.employee.listBonous
                this.termination = this.employee.listTermination
                // this.superGuarantee = this.employee.superGuarantee
                this.employerContribution = this.employee.employerContribution
                this.superSalarySacrifice = this.employee.superSalarySacrifice
                this.notes = this.employee.notes
            }
        },
        getDate(inputDate) {
            var date = new Date(inputDate);
            var day = date.getDate()
            var mount = date.getMonth() + 1
            var year = date.getFullYear()
            if (day.toString().length == 1) {
                day = "0" + day
            }
            if (mount.toString().length == 1) {
                mount = "0" + mount
            }
            console.log("Date " + day + "-" + mount + "-" + year)
            return year + "-" + mount + "-" + day
        },
        setStyleTab(style) {
            this.styleTab = style
            window.$('.tab-employee.active').removeClass('active')
            window.$('.' + style).addClass('active')
            if (style == 'Tax') {
                this.isClickTax = true
                if (this.hasTaxTemplate) {
                    this.disabled()
                } else {
                    this.checkStatusResidency(this.statusResidency)
                }
            } else {
                this.isClickTax = false
            }
            this.checkEmail()
        },
        addListPay(name) {
            var index = this.arrayChange.changes.indexOf(name)
            if(index == -1){
                this.arrayChange.add[name] = 1
                this.arrayChange.changes.push(name)
            } else {
                this.arrayChange.add[name]++
            }
        },
        removeListPay(name, index) {
            if(this[name][index].isAdd) {
                i = this.arrayChange.changes.indexOf(name)
                this.arrayChange.add[name]--
                if(this.arrayChange.add[name] == 0) {
                    this.arrayChange.changes.splice(i, 1)
                }
            } else {
                var i = this.arrayChange.changes.indexOf(name)
                if(i == -1) {
                    this.arrayChange.changes.push(name)
                    this.arrayChange.remove.push(name)
                }
            }
        },
        addExtrahours() {
            this.isAddExtrahours = true
            this.extraHours.push({
                isAdd: true,
                id: 0,
                option: "ORDINARY_HOURS",
                name: "",
                hour: 0,
                rate: 0,
            })
            this.addListPay('extraHours')
        },
        removeExtrahours(index) {
            this.removeListPay('extraHours', index)
            this.extraHours.splice(index, 1)
            if (this.extraHours.length == 0) {
                this.isAddExtrahours = false
            }
        },
        addAllowance() {
            this.isAddAllowance = true
            this.allowance.push({
                isAdd: true,
                id: 0,
                unit: 0,
                cost: 0,
                option: "SUBJECT_TO_TAX_AND_SUPER",
                type: "CAR",
                noteJobKeeper: "",
                noteStarted: "",
                noteFinished: ""
            })
            this.addListPay('allowance')
        },
        removeAllowance(index) {
            this.removeListPay('allowance', index)
            this.allowance.splice(index, 1)
            if (this.allowance.length == 0) {
                this.isAddAllowance = false
            }
        },
        addDeduction() {
            this.isAddDeduction = true
            this.deduction.push({
                isAdd: true,
                id: 0,
                option: "POST_TAX_DEDUCTION",
                type: "FEES",
                amountDeduction: 0
            })
            this.addListPay('deduction')
        },
        removeDeduction(index) {
            this.removeListPay('deduction', index)
            this.deduction.splice(index, 1)
            if (this.deduction.length == 0) {
                this.isAddDeduction = false
            }
        },
        gotoPayment() {
            this.closeModal()
            this.$root.$refs.BaseLayout.gotoPayment()
        },
        openModal() {
            this.$modal.show("modal-editemployee", {
                data: "data"
            });
        },
        async closeModal() {
            if(await this.checkChangeInfoEmployee()) {
                this.$modal.hide("modal-editemployee");
            }
        },
        showPopupConfirm() {
            var elem = window.$("#popupConfirm")
            elem.removeClass("hide-popup")
        },
        hidePopupConfirm() {
            var elem = window.$("#popupConfirm")
            elem.addClass("hide-popup")
        },
        showPopupDelete() {
            
             var elem1 = window.$("#popupConfirm")
            elem1.addClass("hide-popup")
            var elem = window.$("#popupDeleteemployee")
            elem.removeClass("hide-popup")
        },
        hidePopupDelete() {
            var elem = window.$("#popupDeleteemployee")
            elem.addClass("hide-popup")
        },
        clickOk() {
            this.hidePopupConfirm()
            this.closeModal()
        },
        clickYes() {
            this.hidePopupDelete()
            this.closeModal()
        },
        hilight() {
            var elem = window.$(".ng-confirm-type-animated")
            if (elem.hasClass("ng-confirm-hilight")) {
                elem.removeClass("ng-confirm-hilight")
                elem.addClass("ng-confirm-hilight");
            } else {
                elem.addClass("ng-confirm-hilight");
                setTimeout(function () {
                    elem.removeClass("ng-confirm-hilight")
                }, 500);
            }
        },
        payRateUI() {
            var payRatePerHour = 0;
            var payRate = this.reFormatMoney(this.payRate)
            if (this.typeRate === 'PerHour') {
                var rateh = payRate * (this.weeklyWorkHours * 52)
                if (this.casualLoading > 0) {
                    payRatePerHour = rateh * 1 + (this.casualLoading * rateh) / 100
                }
                payRatePerHour = rateh * 1
            } else if (this.typeRate === 'PerDay') {
                var rated = payRate / Session.get("workHours")
                if (this.casualLoading > 0) {
                    rated = rated + (this.casualLoading * rated) / 100
                }
                payRatePerHour = rated
            } else if (this.typeRate === 'PerWeek') {
                var perWeek = payRate / this.weeklyWorkHours
                if (this.casualLoading > 0) {
                    perWeek = perWeek + (this.casualLoading * perWeek) / 100
                }
                payRatePerHour = perWeek
            } else if (this.typeRate === 'PerAnnum') {
                var ratey = payRate / (this.weeklyWorkHours * 52)
                if (this.casualLoading > 0) {
                    ratey = ratey + (this.casualLoading * ratey) / 100
                }
                payRatePerHour = ratey
            }
            return Utils.roundNumber(payRatePerHour)
        },
        payRatePerHourUI() {
            var payRatePerHour = 0;
            var payRate = this.reFormatMoney(this.payRate)
            if (this.typeRate === 'PerHour') {
                var rateh = payRate
                if (this.casualLoading > 0) {
                    payRatePerHour = rateh * 1 + (this.casualLoading * rateh) / 100
                }
                payRatePerHour = rateh * 1
            } else if (this.typeRate === 'PerDay') {
                var rated = payRate / Session.get("workHours")
                if (this.casualLoading > 0) {
                    rated = rated + (this.casualLoading * rated) / 100
                }
                payRatePerHour = rated
            } else if (this.typeRate === 'PerWeek') {
                var perWeek = payRate / this.weeklyWorkHours
                if (this.casualLoading > 0) {
                    perWeek = perWeek + (this.casualLoading * perWeek) / 100
                }
                payRatePerHour = perWeek
            } else if (this.typeRate === 'PerAnnum') {
                var ratey = payRate / (this.weeklyWorkHours * 52)
                if (this.casualLoading > 0) {
                    ratey = ratey + (this.casualLoading * ratey) / 100
                }
                payRatePerHour = ratey
            }
            return Utils.roundNumber(payRatePerHour)
        },
        payRatePerHourServer() {
            var payRatePerHour = 0
            var payRate = this.reFormatMoney(this.payRate)
            if (this.typeRate === 'PerHour') {
                payRatePerHour = payRate * 1
            } else if (this.typeRate === 'PerDay') {
                payRatePerHour = payRate / Session.get("workHours")
            } else if (this.typeRate === 'PerWeek') {
                payRatePerHour = payRate / Session.get("workHours")
            } else if (this.typeRate === 'PerAnnum') {
                payRatePerHour = payRate / (this.weeklyWorkHours * 52)
            }
            return Number(payRatePerHour)
        },
        getWorkHourOrdinary() {
            var workHourOrdinary = 0
            if (this.payPeriod === 'All') {
                if (Session.has("payPeriod")) {
                    if (Session.get("payPeriod") == 'Weekly') {
                        workHourOrdinary = this.weeklyWorkHours
                    } else if (Session.get("payPeriod") == 'Fortnightly') {
                        workHourOrdinary = this.weeklyWorkHours * 2
                    } else if (Session.get("payPeriod") == 'Monthly') {
                        workHourOrdinary = this.weeklyWorkHours * 52 / 12
                    }
                } else {
                    workHourOrdinary = this.weeklyWorkHours
                }
            } else if (this.payPeriod === 'Weekly') {
                workHourOrdinary = this.weeklyWorkHours
            } else if (this.payPeriod === 'Fortnightly') {
                workHourOrdinary = this.weeklyWorkHours * 2
            } else if (this.payPeriod === 'Monthly') {
                workHourOrdinary = this.weeklyWorkHours * 52 / 12
            }
            return Utils.roundNumber(workHourOrdinary)
        },
        onChangeGender(event) {
            this.gender = event.target.value
        },
        // method for tab Bank and Super
        onChangeMethod(event) {
            this.paymentMethod = event.target.value
        },
        statusResidencyChange(event) {
            this.statusResidency = event.target.value
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
            this.changeInfoEmployee('statusResidency', this.statusResidency)
        },
        checkStatusResidency(statusResidency) {
            if (statusResidency == 'AustraliaResident') {
                document.getElementById("claimTaxFreeThreshold").disabled = false;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'ForeignResident') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                this.claimTaxFreeThreshold = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'WorkingHoliday') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                document.getElementById("hasHecsOrHelpDebt").checked = false;
                document.getElementById("hasTslOrStudentStart").checked = false;
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                this.hasTslOrStudentStart = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = true;
                document.getElementById("hasTslOrStudentStart").disabled = true;
            }
        },
        checkOverwriteTax() {
            if (this.hasTaxTemplate) {
                this.disabled()
                this.taxTemplate = 0
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        disabled() {
            document.getElementById("claimTaxFreeThreshold").checked = false;
            document.getElementById("hasHecsOrHelpDebt").checked = false;
            document.getElementById("hasTslOrStudentStart").checked = false;
            this.claimTaxFreeThreshold = false
            this.hasHecsOrHelpDebt = false
            this.hasTslOrStudentStart = false

            document.getElementById("claimTaxFreeThreshold").disabled = true;
            document.getElementById("hasHecsOrHelpDebt").disabled = true;
            document.getElementById("hasTslOrStudentStart").disabled = true;
        },
        onTypeExemptionChange(event) {
            this.tfnExemption = event.target.value
        },
        clickTaxFileNumber() {
            if (this.isExemption) {
                this.isExemption = false
                this.tfnExemption = null
            } else {
                this.isExemption = true
                this.tfnExemption = "NoTFNQuoted"
                this.taxNumber = ""
            }
        },
        onEmploymentTypeChange(event) {
            this.employmentType = event.target.value
            this.weeklyWorkHours = this.employee.weeklyWorkHours
            if (this.employmentType != 'Casual') {
                this.casualLoading = 0
                this.leaveLoading = 0
                if (this.employmentType == "FullTime") {
                    // Logs.string('default', this.weeklyWorkHoursDefault)
                    if (this.employee.employmentType != "FullTime") {
                        this.weeklyWorkHours = this.weeklyWorkHoursDefault;
                    }
                }
            } else {
                this.leaveLoading = 0
                this.casualLoading = 25
            }
            this.changeInfoEmployee('employmentType', this.employmentType)
        },
        getUnit(event) {
            this.typeRate = event.target.value
        },
        //===> Request API
        deleteEmPloyee() {
            var employeeId = 0
            if (this.employee.id == undefined) {
                employeeId = Session.get("PeopleId")
            } else {
                employeeId = this.employee.id
            }
            var request = {
                "companyId": Session.get("currenCompanyId"),
                "employeeId": employeeId
            }
            this.$store.dispatch(DELETE_EMPLOYEE, request)
                .then(() => {
                    this.$root.$refs.BaseLayout.AfterEmployeeCheck();
                    Session.remove("PeopleId");
                    Utils.toastSuccess("Delete employee successful!");
                    if (this.$global.path == '/dashboard') {
                        this.$root.$refs.BaseLayout.getListEmployee();
                    }
                    if (this.$global.path == '/employees') {
                        this.$root.$refs.People.getListEmployee();
                    }
                    this.hidePopupDelete()
                    this.closeModal()
                });
        },
        searchSupperFund() {
            this.isShowDetailSupperFund = false
            this.$root.$refs.AppCyrus.start()
            var request = {
                "keySearch": this.edtSearchSupperFund,
                "page": "",
                "lenght": ""
            }
            this.$store.dispatch(SEARCH_SUPERFUND, request)
                .then((response) => {
                    this.listSupperFund = []
                    this.isShowListSupperFund = true
                    this.listSupperFundFull = response.listSuperFund
                    if (response.listSuperFund != undefined) {
                        this.totalPage = response.listSuperFund.length
                    }
                    if (this.listSupperFundFull.length < 10) {
                        this.pageSize = this.listSupperFundFull.length
                    } else {
                        this.pageSize = 10
                    }
                    for (var i = 0; i < this.pageSize; i++) {
                        this.listSupperFund.push(this.listSupperFundFull[i])
                    }
                    this.$root.$refs.AppCyrus.finish()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        handleCurrentChange(page) {
            this.currenPage = page
            this.listSupperFund = []
            var from = (this.currenPage - 1) * this.pageSize + 1
            var to = 0
            if (this.pageSize * this.currenPage <= this.totalPage) {
                to = this.pageSize * this.currenPage
            } else {
                to = this.totalPage
            }
            for (var i = from; i < to; i++) {
                this.listSupperFund.push(this.listSupperFundFull[i])
            }
        },
        detailSupperFund(keyABN) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_SUPERFUND, keyABN)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.isShowListSupperFund = false
                    this.isShowDetailSupperFund = true

                    this.organisationName = response.organisationName
                    this.identifierValue = response.identifierValue
                    this.fundName = response.organisationName
                    this.fundABN = response.identifierValue
                    this.fundAddress = response.addressLine1
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        remoteSupperFund() {
            this.isShowDetailSupperFund = false
            this.organisationName = ""
            this.identifierValue = ""
            this.memberNumber = ""
            this.fundName = ""
            this.fundABN = ""
            this.fundAddress = ""
            this.edtSearchSupperFund = ""
        },
        updateEmployee() {
            var checkActive = ""
            if (this.activeEmployee) {
                checkActive = "ACTIVE"
            } else {
                checkActive = "INACTIVE"
            }
            var request = {
                "id": this.employee.id,
                "companyId": this.employee.companyId,
                "active": checkActive,
                //******Profile******
                "firstName": this.firstName,
                "lastName": this.lastName,
                "gender": this.gender,
                "avatar": this.avatar,
                "email": this.email,
                "contactNumber": this.contactNumber.replace(/[\s()]+/g, ''),
                "address1": this.address1,
                "address2": this.address2,
                "suburb": this.suburb,
                "postcode": this.postcode,
                "state": this.state,
                "country": this.countryCode,
                //******Bank and Super******
                //===> Bank 
                "bank": {
                    "paymentMethod": this.paymentMethod,
                    "bankAccountName": this.bankAccountName,
                    "bankAccountBSB": this.bankAccountBSB,
                    "bankAccountNumber": this.bankAccountNumber,
                },
                //===> Supper Fund
                "fund": {
                    "superFundType": this.superFundType,
                    "contributionRate": this.contributionRate,
                    "fundName": this.fundName,
                    "fundABN": this.fundABN,
                    "fundAddress": this.fundAddress,
                    "fundUSI": this.fundUSI,
                    "fundEmployerNumber": this.fundEmployerNumber,
                    "memberNumber": this.memberNumber,
                    "fundBankName": this.fundBankName,
                    "fundBankBSB": this.fundBankBSB,
                    "fundBankNumber": this.fundBankNumber,
                },
                //******Employment******
                "job": this.job,
                // "startDate": this.startDateYear + "-" + this.startDateMonth + "-" + this.startDateDay,
                "startDate": this.startDate,
                "employmentType": this.employmentType,
                "weeklyWorkHours": this.weeklyWorkHours,
                "leaveLoading": this.leaveLoading,
                "casualLoading": this.casualLoading,
                "payPeriod": this.payPeriod,
                "payRatePerHour": this.payRatePerHourServer(),
                "payRate": this.reFormatMoney(this.payRate),
                "typeRate": this.typeRate,
                //******Tax******
                // "birthDate": this.birthDateYear + "-" + this.birthDateMonth + "-" + this.birthDateDay,
                "birthDate" : this.birthDate,
                "statusResidency": this.statusResidency,
                "isExemption": this.isExemption,
                "tfnExemption": this.tfnExemption,
                "taxNumber": this.taxNumber,
                "claimTaxFreeThreshold": this.claimTaxFreeThreshold,
                "hasHecsOrHelpDebt": this.hasHecsOrHelpDebt,
                "hasTslOrStudentStart": this.hasTslOrStudentStart,
                "hasFinancialSupplementDebt": this.hasFinancialSupplementDebt,
                "taxFileNumberDeclaration": this.taxFileNumberDeclaration,
                "hasTaxTemplate": this.hasTaxTemplate,
                "taxTemplate": this.taxTemplate,
                //******Opening balances******
                "annualLeaveBalance": this.annualLeaveBalance,
                "sickLeaveBalance": this.sickLeaveBalance,
                "asAtBalance": this.asAtBalance,
                "grossEarningsBalance": this.grossEarningsBalance,
                "taxBalance": this.taxBalance,
                "superGuaranteeBalance": this.superGuaranteeBalance,
                "employerContributionBalance": this.employerContributionBalance,
                "listExtraHours": this.extraHours,
                "listAllowance": this.allowance,
                "listDeduction": this.deduction,
                "listBonous": this.bonus,
                "listTermination": this.termination,
                "superGuarantee": this.superGuarantee,
                "employerContribution": this.employerContribution,
                "superSalarySacrifice": this.superSalarySacrifice,
                "notes": this.notes,
                'terminationDate': null
            }
            this.checkValidate()
            if (this.isClickTax) {
                if (this.isValidate) {
                    this.$root.$refs.AppCyrus.start()
                    this.$store.dispatch(UPDATE_EMPLOYEE, request)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            Utils.toastSuccess("Update employee successful!");
                            this.arrayChange = {
                                add: {},
                                remove: [],
                                changes: []
                            }
                            this.$root.$refs.People.getListEmployee()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                if (this.isValidate) {
                    this.$store.dispatch(UPDATE_EMPLOYEE, request)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            Utils.toastSuccess("Update employee successful!");
                            this.arrayChange = {
                                add: {},
                                remove: [],
                                changes: []
                            }
                            this.$root.$refs.People.getListEmployee()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            }

        },
        checkEmail() {
            if (this.email != this.employee.email) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "email": this.email,
                    }
                    this.$store.dispatch(CHECK_EMAIL, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            this.checkContactNumber()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                this.checkContactNumber()
            }

        },
        checkContactNumber() {
            if (this.contactNumber.replace(/[\s()]+/g, '') != this.employee.contactNumber) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "contactNumber": this.contactNumber.replace(/[\s()]+/g, ''),
                    }
                    this.$store.dispatch(CHECK_CONTACT_NUMBER, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()
                            this.checkTaxNumber()
                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            } else {
                this.checkTaxNumber()
            }
        },
        checkTaxNumber() {
            if (this.employee.active != "PENDING" && this.taxNumber != this.employee.taxNumber && this.taxNumber) {
                if (Session.has("currenCompanyId")) {
                    this.$root.$refs.AppCyrus.start()
                    var modelRequest = {
                        "companyId": Session.get("currenCompanyId"),
                        "employeeId": 0,
                        "taxNumber": this.taxNumber,
                    }
                    this.$store.dispatch(CHECK_TAX_NUMBER, modelRequest)
                        .then(() => {
                            this.$root.$refs.AppCyrus.finish()

                        }).catch(() => {
                            this.$root.$refs.AppCyrus.fail()
                        });
                }
            }
        },
        checkValidate() {
            this.isValidate = true;
            window.$('.is-invalid').removeClass('is-invalid')
            // profile
            var firstName = window.$('.ng-first-name')
            if (Validate.isEmpty(this.firstName)) {
                firstName.addClass('is-invalid')
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                firstName.removeClass('is-invalid')
            }
            var lastName = window.$('.ng-last-name')
            if (Validate.isEmpty(this.lastName)) {
                lastName.addClass('is-invalid')
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                lastName.removeClass('is-invalid')
            }
            var email = window.$('.ng-email')
            if (!Validate.isEmail(this.email) || Validate.isEmpty(this.email)) {
                email.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            } else {
                email.removeClass("is-invalid");
            }
            var phone = window.$(".ng-phone")
            if (Validate.isPhone(this.contactNumber.replace(/[\s()]+/g, ''))) {
                phone.removeClass("is-invalid");
            } else {
                phone.addClass("is-invalid");
                window.$('.Profile').addClass('is-invalid')
                this.isValidate = false;
            }
            if (this.isCheckFindMyAddress) {
                var address = window.$('.ng-address1')
                if (Validate.isEmpty(this.address1)) {
                    address.addClass("is-invalid");
                    window.$('.Profile').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    address.removeClass("is-invalid");
                }
                var suburb = window.$('.ng-suburb')
                if (Validate.isEmpty(this.suburb)) {
                    suburb.addClass("is-invalid");
                    window.$('.Profile').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    suburb.removeClass("is-invalid");
                }
                var post = window.$('.ng-post-code')
                if (Validate.isEmpty(this.postcode) || Validate.isNumber(this.postcode)) {
                    post.addClass("is-invalid");
                    window.$('.Profile').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    post.removeClass("is-invalid");
                }
                var state = window.$('.ng-state')
                if (Validate.isEmpty(this.state)) {
                    state.addClass("is-invalid");
                    window.$('.Profile').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    state.removeClass("is-invalid");
                }
            } else {
                address = window.$('.ng-address')
                if (Validate.isEmpty(this.address)) {
                    address.addClass("is-invalid");
                    window.$('.Profile').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    address.removeClass("is-invalid");
                }
            }
            // Bank
            if (this.paymentMethod === "Bank") {
                var accName = window.$('.ng-account-name')
                if (Validate.isEmpty(this.bankAccountName)) {
                    accName.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    accName.removeClass("is-invalid");
                }
                var bsb = window.$('.ng-bsb')
                if (Validate.isEmpty(this.bankAccountBSB) || Validate.isNumber(this.bankAccountBSB)) {
                    bsb.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    bsb.removeClass("is-invalid");
                }
                var accNumber = window.$('.ng-account-number')
                if (Validate.isEmpty(this.bankAccountNumber) || Validate.isNumber(this.bankAccountNumber) || this.bankAccountNumber.length < 6) {
                    accNumber.addClass("is-invalid");
                    window.$('.Bank').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    accNumber.removeClass("is-invalid");
                }
            }
            // super
            var ConRate = window.$(".ng-Contribution-Rate")
            if (Validate.isEmpty(this.contributionRate) || Validate.isNumber(this.contributionRate)) {
                ConRate.addClass("is-invalid");
                window.$('.Super').addClass('is-invalid')
                this.isValidate = false;
            } else {
                ConRate.removeClass("is-invalid");
            }
            if (this.superFundType === 'Self Managed Superannuation Fund') {
                var sfName = window.$('.ng-Sf-Name')
                var sfABN = window.$('.ng-Sf-ABN')
                var sfaccName = window.$('.ng-Sf-Account-Name')
                var sfBSB = window.$('.ng-Sf-BSB')
                var sfACCNumber = window.$('.ng-Sf-Account-Number')
                if (Validate.isEmpty(this.fundName)) {
                    sfName.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfName.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundABN)) {
                    sfABN.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfABN.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankName)) {
                    sfaccName.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfaccName.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankBSB) || Validate.isNumber(this.fundBankBSB)) {
                    sfBSB.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfBSB.removeClass("is-invalid");
                }
                if (Validate.isEmpty(this.fundBankNumber) || Validate.isNumber(this.fundBankNumber) || this.fundBankNumber.length < 6) {
                    sfACCNumber.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    sfACCNumber.removeClass("is-invalid");
                }
            } else if(this.isShowDetailSupperFund) {
                var number = window.$('.ng-number')
                if(Validate.isEmpty(this.memberNumber)) {
                    number.addClass("is-invalid");
                    window.$('.Super').addClass('is-invalid')
                    this.isValidate = false;
                } else {
                    number.removeClass("is-invalid");
                }
            }
            // employment
            var job = window.$('.ng-job-title')
            if (Validate.isEmpty(this.job)) {
                job.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                job.removeClass("is-invalid")
            }
            var payRate = window.$('.ng-pay-rate')
            if (Validate.isEmpty(this.payRate)) {
                payRate.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                payRate.removeClass("is-invalid")
            }
            var weeklyWorkHours = window.$('.ng-weekly-work-hours')
            if (Validate.isEmpty(this.weeklyWorkHours)) {
                weeklyWorkHours.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                weeklyWorkHours.removeClass("is-invalid")
            }
            var employment = window.$('.ng-employment')
            if (Validate.isEmpty(this.employmentType)) {
                employment.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                employment.removeClass("is-invalid")
            }
            // var startDay = window.$('.ng-start-day')
            // var startMonth = window.$('.ng-start-month')
            // var startYear = window.$('.ng-start-year')
            var startDate = window.$('ng-start-date')
            if (Validate.isEmpty(this.startDate)) {
                startDate.addClass("is-invalid")
                window.$('.Employment').addClass('is-invalid')
                this.isValidate = false
            } else {
                startDate.removeClass("is-invalid")
            }
            // if (Validate.isEmpty(this.startDateDay) || Validate.isValidateDate("Day", this.startDateDay)) {
            //     startDay.addClass("is-invalid")
            //     window.$('.Employment').addClass('is-invalid')
            //     this.isValidate = false
            // } else {
            //     startDay.removeClass("is-invalid")
            // }
            // if (Validate.isEmpty(this.startDateMonth) || Validate.isValidateDate("Month", this.startDateMonth)) {
            //     startMonth.addClass("is-invalid")
            //     window.$('.Employment').addClass('is-invalid')
            //     this.isValidate = false
            // } else {
            //     startMonth.removeClass("is-invalid")
            // }
            // if (Validate.isEmpty(this.startDateYear) || Validate.isValidateDate("Year", this.startDateYear)) {
            //     startYear.addClass("is-invalid")
            //     window.$('.Employment').addClass('is-invalid')
            //     this.isValidate = false
            // } else {
            //     startYear.removeClass("is-invalid")
            // }
            // tax
            // var day = window.$(".ng-Day")
            // var month = window.$(".ng-Month")
            // var year = window.$(".ng-Year")
            var tax = window.$(".ng-tax-file-number")
            var status = window.$(".ng-Status-Residency")

            //Check Day
            var birthDate = window.$('.ng-birth-date')
            if (Validate.isEmpty(this.birthDate)) {
                birthDate.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                birthDate.removeClass("is-invalid");
            }
            // if (Validate.isValidateDate("Day", this.birthDateDay)) {
            //     day.addClass("is-invalid");
            //     window.$('.Tax').addClass('is-invalid')
            //     this.isValidate = false;
            // } else {
            //     day.removeClass("is-invalid");
            // }
            // // Check Month
            // if (Validate.isValidateDate("Month", this.birthDateMonth)) {
            //     month.addClass("is-invalid");
            //     window.$('.Tax').addClass('is-invalid')
            //     this.isValidate = false;
            // } else {
            //     month.removeClass("is-invalid");
            // }
            // // Check Year
            // if (Validate.isValidateDate("Year", this.birthDateYear)) {
            //     year.addClass("is-invalid");
            //     window.$('.Tax').addClass('is-invalid')
            //     this.isValidate = false;
            // } else {
            //     year.removeClass("is-invalid");
            // }

            // if (Validate.isFuture(this.birthDateDay, this.birthDateMonth, this.birthDateYear)) {
            //     day.addClass("is-invalid");
            //     month.addClass("is-invalid");
            //     year.addClass("is-invalid");
            //     this.isValidate = false;
            // } else {
            //     day.removeClass("is-invalid");
            //     month.removeClass("is-invalid");
            //     year.removeClass("is-invalid");
            // }

            var checkTax = false
            var checkTaxEmpty = false
            if (!this.isExemption) {
                checkTax = Validate.CheckTFN(this.taxNumber)
                checkTaxEmpty = Validate.isEmpty(this.taxNumber)
            } else {
                checkTax = false;
                checkTaxEmpty = false;
            }

            //Check Tax File Number
            if (checkTaxEmpty || checkTax) {
                tax.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                tax.removeClass("is-invalid");
            }
            //Check StatusResidency
            if (Validate.isEmpty(this.statusResidency)) {
                status.addClass("is-invalid");
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                status.removeClass("is-invalid");
            }
            
            var taxTemplate = window.$('.ng-taxTemplate')
            if(this.hasTaxTemplate && (this.taxTemplate == 0 || Validate.isEmpty(this.taxTemplate))) {
                taxTemplate.addClass("is-invalid")
                window.$('.Tax').addClass('is-invalid')
                this.isValidate = false;
            } else {
                taxTemplate.removeClass("is-invalid")
            }

            // Opening balances
            var asAt = window.$('.ng-time1')
            if (Validate.isEmpty(this.asAtBalance)) {
                asAt.addClass('is-invalid')
                window.$('.Opening').addClass('is-invalid')
                this.isValidate = false;
            } else {
                asAt.removeClass('is-invalid')
            }

        },
        formatPhoneNumber() {
            var x = this.contactNumber.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.contactNumber = !x[2] ? '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        },
        reFormatMoney(val) {
            val = val + ""
            return val.replace(/,/g, '')
        },
        formatMoney(val) {
            val = val + ""
            if(Number(val) == 0) {
                return 0
            } else {
                val = val.replace(/^0*/, '')
            }
            var x = val.replace(/[^,\d]/g, '').match(/\d/g);
            var money = ""
            var count = 0
            for(var i = x.length - 1; i >= 0; i--) {
                count++
                if(count == 4) {
                    money = x[i] + ',' + money
                    count = 1
                } else {
                    money = x[i] + money
                }
            }
            return money
        },
    },
    watch: {
        "contactNumber": function(val) {
            var x = val.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.contactNumber = !x[2] ? '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        },
        "payRate": function (val) {
            this.payRate = this.formatMoney(val)
        }
    }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
$default-color: #555;
$primary-color: #1284e7;
$input-color: #495057;
$input-border-color: transparent;
$input-hover-border-color: transparent;
@import '~vue2-datepicker/scss/index.scss'; //https://github.com/mengxiong10/vue2-datepicker/blob/master/src/style/index.scss

.date-picker {
    padding: 0px 0px 0px 0px !important;
    width: 100%;
    height: auto;
}

.date-picker .mx-input-wrapper .mx-input {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}
</style><style lang="css" scoped>
.hide-popup {
    display: none
}
</style>
