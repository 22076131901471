<template>
  <modal
    name="modal-newemployeestep4"
    @before-open="beforeOpen"
    with="auto"
    height="auto"
  >
    <div
      uib-modal-window="modal-window"
      class="modal fade ng-scope ng-isolate-scope show"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      size="md"
      index="0"
      animate="animate"
      ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
      tabindex="-1"
      uib-modal-animation-class="fade"
      modal-in-class="show"
      modal-animation="true"
      style="z-index: 1050; display: block; overflow: auto"
    >
      <div class="modal-dialog modal-m">
        <div class="modal-content" uib-modal-transclude="">
          <div
            class="
              modal-content
              text-center
              onboarding-modal
              modal-centered
              ng-scope
            "
          >
            <button
              aria-label="Close"
              class="box-title close"
              data-dismiss="modal"
              type="button"
              v-on:click="closeModal()"
            ></button>
            <div
              class="onboarding-slide"
              ng-class="{'hide' : currentIndex != 4 }"
              style=""
            >
              <div class="onboarding-side-by-side">
                <div class="headerimg">
                  <img class="img" alt="" src="/assets/img/img_step0.png" />
                </div>
                <div
                  class="onboarding-content with-gradient"
                  style="padding-bottom: 0px"
                >
                  <p class="title">Employment Details</p>
                  <div class="onboarding-text">
                    <!--    Finally, just enter the employment details agreed upon with your employee here and you’re ready to go! -->
                  </div>
                  <form class="ng-pristine ng-valid ng-valid-step">
                    <v-app>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-select
                              :attach="true"
                              @change="onEmploymentTypeChange()"
                              class="ng-employment"
                              v-model="newEmployeeInfo.employmentType"
                              :items="listEmploymentType"
                              label="Employment Type"
                              required
                              outlined
                              color="success"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-select
                              :attach="true"
                              v-model="newEmployeeInfo.payPeriod"
                              :items="listPayPeriod"
                              label="Pay Period"
                              required
                              outlined
                              color="success"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              class="ng-weekly-work-hours"
                              v-show="employmentType != 'FullTime'"
                              v-model="newEmployeeInfo.weeklyWorkHours"
                              label="Weekly work hours"
                              step="0.01"
                              type="number"
                              maxlength="40"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>
                            <v-text-field
                              class="ng-weekly-work-hours"
                              v-show="employmentType == 'FullTime'"
                              :readonly="isLock"
                              v-model="newEmployeeInfo.weeklyWorkHours"
                              label="Weekly work hours"
                              step="0.01"
                              type="number"
                              maxlength="40"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                              :prepend-inner-icon="
                                isLock ? 'mdi-lock' : 'mdi-lock-open'
                              "
                              @click:prepend-inner="isLock = !isLock"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div
                            v-show="employmentType != 'Casual'"
                            class="form-group"
                          >
                            <v-text-field
                              v-model.number="newEmployeeInfo.leaveLoading"
                              label="Leave Loading (%)"
                              step="0.01"
                              type="number"
                              maxlength="40"
                              required
                              outlined
                              color="success"
                            ></v-text-field>
                          </div>
                          <div
                            v-show="employmentType == 'Casual'"
                            class="form-group"
                          >
                            <v-text-field
                              v-model.number="newEmployeeInfo.casualLoading"
                              label="Casual Loading (%)"
                              step="0.01"
                              type="number"
                              maxlength="40"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-text-field
                              class="ng-PayRay"
                              v-model="newEmployeeInfo.payRate"
                              label="* Pay Rate"
                              step="0.01"
                              type="number"
                              maxlength="40"
                              :rules="[rules.required]"
                              required
                              outlined
                              color="success"
                              @blur="roundPayRate"
                              prepend-inner-icon="mdi-currency-usd"
                            ></v-text-field>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group">
                            <v-select
                              :menu-props="{ top: true }"
                              :attach="true"
                              v-model="newEmployeeInfo.typeRate"
                              :items="listTypeRate"
                              required
                              outlined
                              color="success"
                            ></v-select>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-show="payRate > 0">
                        <div class="col-12">
                          <div
                            v-if="newEmployeeInfo.typeRate != 'PerHour'"
                            class="text-success ng-binding"
                            style="position: absolute; top: -30px"
                          >
                            Equivalent to ${{ payRateUI() }} per hour
                            <i
                              class="
                                icon-feather-help-circle
                                text-primary
                                ng-hide
                              "
                              ng-click="hourlyRateInfo()"
                              ng-show="employeeDetails_new.ty === 'c'"
                            ></i>
                          </div>
                          <div
                            v-else
                            class="text-success ng-binding"
                            style="position: absolute; top: -30px"
                          >
                            Equivalent to ${{ payRateUI() }} per year
                            <i
                              class="
                                icon-feather-help-circle
                                text-primary
                                ng-hide
                              "
                              ng-click="hourlyRateInfo()"
                              ng-show="employeeDetails_new.ty === 'c'"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </v-app>
                  </form>
                </div>
              </div>
            </div>
            <!-- </slick> -->
            <div class="row" style="padding: 12px">
              <div class="col-6 text-left">
                <button
                  type="button"
                  class="btn btn-link"
                  v-on:click="gotoStep3()"
                  style=""
                >
                  <i class="icon-feather-arrow-left"></i> Previous
                </button>
              </div>
              <div class="col-6 text-right">
                <!-- ngIf: currentIndex == 0 -->
                <button
                  v-if="this.inviteEmployee"
                  class="btn btn-primary ng-binding"
                  v-on:click="addNewEmployeeByEmail()"
                  ng-disabled="loading"
                >
                  <span
                    class="spinner-grow spinner-grow-sm ng-hide"
                    ng-show="loading"
                  ></span>
                  Send email
                </button>
                <button
                  v-else
                  class="btn btn-primary ng-binding"
                  v-on:click="addNewEmployee()"
                  ng-disabled="loading"
                >
                  <span
                    class="spinner-grow spinner-grow-sm ng-hide"
                    ng-show="loading"
                  ></span>
                  Add Employee
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Session from "@/utils/SessionStore";
import ApiManager from "@/api/ApiManager";
import * as DataSelect from "@/utils/DataSelect.js";
import {
  DATA_STEP4,
  DELETEDATA,
  ADD_EMPLOYEE,
  ADD_EMPLOYEE_BY_EMAIL,
  HOLD_SUBSCRIPTION
} from "@/store/actionsType";
export default {
  name: "NewEmployeeStep4Modal",
  props: {},
  components: {},
  data() {
    return {
      newEmployeeInfo: {},
      TriggerForHoldSubscription: false,
      isCheckStep4: undefined,
      isLock: true,
      isValidate: false,
      inviteEmployee: false,
      //Valadate
      rules: {
        required: (value) => !!value || "",
        animal: [(val) => (val || "").length > 0 || "This field is required"],
        //min: v => v.length >= 0 || 'Min 8 characters',
        // emailMatch: () => (`The email and password you entered don't match`),
      },

      //Data
      listEmploymentType: DataSelect.getList("listEmploymentType"),
      listPayPeriod: DataSelect.getList("listPayPeriod"),
      listTypeRate: DataSelect.getList("listTypeRate"),
    };
  },
  methods: {
    beforeOpen(event) {
      this.isLock = true;
      this.isCheckStep4 = event.params.isCheckStep4;
      this.inviteEmployee = event.params.inviteEmployee;
      
      this.TriggerForHoldSubscription = this.$root.$refs.People.TriggerForHoldSubscription;

      if (event.params?.newEmployeeInfo) {
          this.newEmployeeInfo = event.params.newEmployeeInfo;
      }
    },
    unlock() {
      this.isLock = !this.isLock;
    },
    openModal() {
      this.$modal.show("modal-newemployeestep4", {
        data: "data",
        newEmployeeInfo: this.newEmployeeInfo,
      });
    },
    closeModal() {
      if (this.$global.path == "/dashboard") {
        this.$root.$refs.BaseLayout.getListEmployee();
      }
      if (this.$global.path == "/employees") {
        this.$root.$refs.People.getListEmployee();
      }
      this.$modal.hide("modal-newemployeestep4");
    },
    gotoStep3() {
      if (this.isCheckStep4) {
        this.closeModal();
        if (this.inviteEmployee) {
          this.$modal.show("modal-newemployeestep0", { newEmployeeInfo: this.newEmployeeInfo });
        } else {
          this.$modal.show("modal-newemployeestep3", { newEmployeeInfo: this.newEmployeeInfo });
        }
      } else {
        this.closeModal();
      }
    },
    getDataStep4() {
      this.newEmployeeInfo.payRatePerHour = this.payRatePerHourServer();
      this.$store.dispatch(DATA_STEP4, this.newEmployeeInfo);
    },
    checkValidate() {
      var payRay = window.$(".ng-PayRay");
      this.isValidate = true;
      //Check payRay
      if (Validate.isEmpty(this.newEmployeeInfo.payRate)) {
        payRay.addClass("is-invalid");
        this.isValidate = false;
      } else {
        payRay.removeClass("is-invalid");
      }
      var weeklyWorkHours = window.$(".ng-weekly-work-hours");
      if (Validate.isEmpty(this.newEmployeeInfo.weeklyWorkHours)) {
        weeklyWorkHours.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        weeklyWorkHours.removeClass("is-invalid");
      }
    },
    onEmploymentTypeChange() {
      if (this.newEmployeeInfo.employmentType != "Casual") {
        this.newEmployeeInfo.casualLoading = 0;
        this.newEmployeeInfo.leaveLoading = 0;
        if (this.newEmployeeInfo.employmentType == "FullTime") {
          // Logs.string('default', this.weeklyWorkHoursDefault)
          this.newEmployeeInfo.weeklyWorkHours = Session.get("workHours") * 5;
        }
      } else {
        this.newEmployeeInfo.leaveLoading = 0;
        this.newEmployeeInfo.casualLoading = 25;
      }
    },
    getUnit(event) {
      this.newEmployeeInfo.typeRate = event.target.value;
    },
    payRateUI() {
      let payRatePerHour = 0;
      const payRate = this.newEmployeeInfo.payRate;
      if (this.newEmployeeInfo.typeRate === "PerHour") {
        var rateh = payRate * (this.newEmployeeInfo.weeklyWorkHours * 52);
        if (this.newEmployeeInfo.casualLoading > 0) {
          payRatePerHour = rateh * 1 + (this.newEmployeeInfo.casualLoading * rateh) / 100;
        }
        payRatePerHour = rateh * 1;
      } else if (this.newEmployeeInfo.typeRate === "PerDay") {
        let rated = payRate / Session.get("workHours");
        if (this.newEmployeeInfo.casualLoading > 0) {
          rated = rated + (this.newEmployeeInfo.casualLoading * rated) / 100;
        }
        payRatePerHour = rated;
      } else if (this.newEmployeeInfo.typeRate === "PerWeek") {
        let perWeek = payRate / this.newEmployeeInfo.weeklyWorkHours;
        if (this.newEmployeeInfo.casualLoading > 0) {
          perWeek = perWeek + (this.newEmployeeInfo.casualLoading * perWeek) / 100;
        }
        payRatePerHour = perWeek;
      } else if (this.newEmployeeInfo.typeRate === "PerAnnum") {
        let ratey = payRate / (this.newEmployeeInfo.weeklyWorkHours * 52);
        if (this.newEmployeeInfo.casualLoading > 0) {
          ratey = ratey + (this.newEmployeeInfo.casualLoading * ratey) / 100;
        }
        payRatePerHour = ratey;
      }
      return Utils.roundNumber(payRatePerHour);
    },
    payRatePerHourServer() {
      let payRatePerHour = 0;
      const payRate = this.newEmployeeInfo.payRate;
      if (this.newEmployeeInfo.typeRate === "PerHour") {
        payRatePerHour = payRate * 1;
      } else if (this.newEmployeeInfo.typeRate === "PerDay") {
        payRatePerHour = payRate / Session.get("workHours");
      } else if (this.newEmployeeInfo.typeRate === "PerWeek") {
        payRatePerHour = payRate / this.newEmployeeInfo.weeklyWorkHours;
      } else if (this.newEmployeeInfo.typeRate === "PerAnnum") {
        payRatePerHour = payRate / (this.newEmployeeInfo.weeklyWorkHours * 52);
      }
      return Number(payRatePerHour);
    },

    HoldCurrentSubscription() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(HOLD_SUBSCRIPTION)
        .then(() => {
          this.$root.$refs.AppCyrus.finish();
          this.$root.$refs.BaseLayout.AfterEmployeeCheck();
          // this.OpenPopUp()
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    // Request API
    addNewEmployee() {
      if (Validate.isOpenCheckValidate()) {
        this.checkValidate();
        if (this.isValidate) {
          this.getDataStep4();
          if (this.TriggerForHoldSubscription == true) {
            this.HoldCurrentSubscription();
          }
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(ADD_EMPLOYEE)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
              this.$root.$refs.BaseLayout.AfterEmployeeCheck();
              Utils.toastSuccess("Add employee successful!");
              this.closeModal();
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      } else {
        this.closeModal();
      }
    },
    addNewEmployeeByEmail() {
      if (Validate.isOpenCheckValidate()) {
        this.checkValidate();
        if (this.isValidate) {
          this.$store.dispatch(DELETEDATA);
          this.getDataStep4();
          this.$modal.hide("modal-newemployeestep4");
          this.$swal({
            title: "Invitation is sending to employee's email.",
            imageUrl: "assets/img/send-email.gif",
            imageWidth: 400,
            imageHeight: 200,
            showConfirmButton: false,
            padding: 0,
            customClass: {
              header: "not-padding",
              image: "not-margin image-swal",
            },
            didOpen: () => {
              if (this.TriggerForHoldSubscription == true) {
                  this.HoldCurrentSubscription();
              }
              this.$store
                .dispatch(
                  ADD_EMPLOYEE_BY_EMAIL,
                  ApiManager.getUrlDomainUI() + "/activeemployee"
                )
                .then((response) => {
                  if(response){
                      Session.set("registrationLink",response.registrationLink);
                  }
                  this.$root.$refs.BaseLayout.AfterEmployeeCheck();
                  this.$swal({
                    icon: "success",
                    title: "Invitation sent to employee’s email!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.closeModal();
                    }
                  });
                })
                .catch(() => {
                  this.$swal({
                    icon: "error",
                    title: "Invitation sent to employee’s email has error!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.$modal.show("modal-newemployeestep4", { newEmployeeInfo: this.newEmployeeInfo });
                    }
                  });
                });
            },
          });
        }
      } else {
        this.closeModal();
      }
    },
    roundPayRate() {
      if (isNaN(this.newEmployeeInfo.payRate)) return
      this.newEmployeeInfo.payRate = parseFloat(this.newEmployeeInfo.payRate).toFixed(2)
    },
  },
  computed: {
    employmentType() {
      return this.newEmployeeInfo.employmentType;
    },
    payRate() {
      return this.newEmployeeInfo.payRate;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
