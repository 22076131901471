<template>
  <modal
    name="modal-newemployeestep1"
    @before-open="beforeOpen"
    with="auto"
    height="auto"
    overflow="hidden"
  >
    <div
      uib-modal-window="modal-window"
      class="modal fade ng-scope ng-isolate-scope show"
      role="dialog"
      aria-labelledby="modal-title"
      aria-describedby="modal-body"
      size="md"
      index="0"
      animate="animate"
      ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}"
      tabindex="-1"
      uib-modal-animation-class="fade"
      modal-in-class="show"
      modal-animation="true"
      style="z-index: 1050; display: block; overflow: auto"
    >
      <div class="modal-dialog modal-m">
        <div class="modal-content" uib-modal-transclude="">
          <div
            class="
              modal-content
              text-center
              onboarding-modal
              modal-centered
              ng-scope
            "
          >
            <button
              aria-label="Close"
              class="box-title close"
              data-dismiss="modal"
              type="button"
              v-on:click="this.closeModalAndClearData"
            ></button>
            <div
              class="onboarding-slide"
              ng-class="{'hide' : currentIndex != 1 }"
              style=""
            >
              <div class="onboarding-side-by-side">
                <div class="headerimg">
                  <img class="img" alt="" src="/assets/img/img_step0.png" />
                </div>
                <div
                  class="onboarding-content with-gradient"
                  style="width: 100%; padding-bottom: 0px"
                >
                  <p class="title">Personal Details</p>
                  <form>
                    <v-app>
                      <div class="text-left">
                        <h5>Address</h5>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group outlined">
                              <label class="valid" for="">* Address</label>
                              <vue-google-autocomplete
                                v-on:keyup="keyupInput('address')"
                                v-on:blur="blurInput('address')"
                                v-on:focus="focusInput('address')"
                                type="text"
                                v-bind:class="isCheckFindMyAddress ? 'disabled-address' : ''"
                                country="au"
                                types="address"
                                id="address"
                                v-on:placechanged="getAddressData"
                                ref="googlemap"
                                v-model="address"
                                name="address"
                                class="form-control ng-newaddress isNotNull">
                              </vue-google-autocomplete>
                            </div>
                          </div>
                        </div>
                        <div class="form-check" style="margin-bottom: 40px">
                          <input
                            v-model="isCheckFindMyAddress"
                            @click="otherAddress"
                            class="
                              form-check-input
                              ng-pristine ng-untouched ng-valid ng-empty
                            "
                            type="checkbox"
                            value=""
                            id="isCheckFindMyAddress"
                          />
                          <label
                            class="form-check-label"
                            for="isCheckFindMyAddress"
                          >
                            I am unable to find my address
                          </label>
                        </div>
                        <div v-show="isCheckFindMyAddress">
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <v-text-field
                                  v-model="newEmployeeInfo.address1"
                                  class="ng-newaddress1"
                                  label="* Address 1"
                                  type="text"
                                  maxlength="40"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>

                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group outlined">
                                <label class="valid" for="">Address 2</label>
                                <vue-google-autocomplete
                                  v-on:keyup="keyupInput('address2')"
                                  v-on:blur="blurInput('address2')"
                                  v-on:focus="focusInput('address2')"
                                  id="address2"
                                  v-model="newEmployeeInfo.address2"
                                  class="
                                    form-control
                                    ng-pristine ng-untouched ng-valid ng-empty
                                  "
                                  name="address2"
                                  type="text"
                                ></vue-google-autocomplete>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <v-text-field
                                  v-model="newEmployeeInfo.suburb"
                                  class="ng-newsuburb"
                                  label="* Suburb"
                                  type="text"
                                  maxlength="40"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <v-text-field
                                  v-model="newEmployeeInfo.postcode"
                                  class="ng-newpostcode"
                                  label="* Postcode"
                                  type="text"
                                  maxlength="40"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                ></v-text-field>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <v-select
                                  :menu-props="{ top: true }"
                                  :attach="true"
                                  v-bind:disabled="newEmployeeInfo.countryCode != 'au'"
                                  class="ng-newstate"
                                  v-model="newEmployeeInfo.state"
                                  :items="listState"
                                  item-text="text"
                                  item-value="value"
                                  label="* State"
                                  :rules="[rules.required]"
                                  required
                                  outlined
                                  color="success"
                                ></v-select>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group outlined">
                                <label class="valid" style="z-index: 1" for="">
                                  Country</label
                                >
                                <country-flag
                                  :country="newEmployeeInfo.countryCode"
                                  size="normal"
                                  class="flag-input"
                                />
                                <el-autocomplete
                                  @focus="clickAutocomplete()"
                                  @blur="getBackAutocompete()"
                                  v-model="newEmployeeInfo.country"
                                  class="inline-input form-control"
                                  style="width: 100%; !important;"
                                  :fetch-suggestions="querySearch"
                                  @select="changeLanguage"
                                  auto
                                >
                                  <template slot-scope="{ item }">
                                    <div
                                      style="display: flex; width: max-content"
                                    >
                                      <country-flag
                                        :country="item.value"
                                        size="normal"
                                      />
                                      <div class="value">{{ item.text }}</div>
                                    </div>
                                  </template>
                                </el-autocomplete>
                              </div>

                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-12">
                            <div class="form-group">
                              <v-text-field
                                v-model="newEmployeeInfo.contactNumber"
                                class="ng-newphone"
                                label="* Contact number"
                                type="phone"
                                placeholder="Start with 0 Or +"
                                :rules="[rules.required, rules.isPhone]"
                                required
                                outlined
                                color="success"
                                @input="formatPhoneNumber"
                              ></v-text-field>
                            </div>
                     </div>
                        </div>
                      </div>
                    </v-app>
                  </form>
                </div>
              </div>
            </div>
            <!-- </slick> -->
            <div class="row" style="padding: 12px">
              <div class="col-8 text-left">
                <button
                  type="button"
                  class="btn btn-link"
                  v-on:click="Previous()"
                  ng-show="currentIndex > 0"
                  style=""
                >
                  <i class="icon-feather-arrow-left"></i> Previous
                </button>
              </div>
              <div class="col-2 text-center">
                <!-- ngIf: currentIndex == 0 -->
                <button
                  class="btn btn-warning ng-binding"
                  v-on:click="skipAddress()"
                  ng-disabled="loading"
                >
                  <span
                    class="spinner-grow spinner-grow-sm ng-hide"
                    ng-show="loading"
                  ></span>
                  Skip
                </button>
              </div>
              <div class="col-2 text-right">
                <!-- ngIf: currentIndex == 0 -->
                <button
                  class="btn btn-primary ng-binding"
                  v-on:click="openModalStep2()"
                  ng-disabled="loading"
                >
                  <span
                    class="spinner-grow spinner-grow-sm ng-hide"
                    ng-show="loading"
                  ></span>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore";
import * as Countries from "@/utils/Countries";
import * as DataSelect from "@/utils/DataSelect.js";
import * as Logs from "@/utils/Logs";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import CountryFlag from "vue-country-flag";

import {
  DATA_STEP1,
  CHECK_CONTACT_NUMBER,
} from "@/store/actionsType";

export default {
  name: "NewEmployeeStep1Modal",
  props: [],
  components: {
    VueGoogleAutocomplete,
    CountryFlag,
  },
  data() {
    return {
      newEmployeeInfo: {},
      countries: [],
      isValidate: true,
      address: "",
      isCheckFindMyAddress: false,
      listState: DataSelect.getList("listState"),
      rules: {
        required: (value) => !!value || "",
        animal: [(val) => (val || "").length > 0 || "This field is required"],
        isPhone: (val) =>
          (!isNaN(val.replace(/[\s()+]+/g, "")) &&
            ((val.indexOf("0") == 0 && val.replace(/\s+/g, "").length <= 11) ||
              (val.indexOf("+") == 1 && val.replace(/\s+/g, "").length <= 14) ||
              "Phone numbers have a maximum of 11 numbers")) ||
          "phone number must be number",
      },
    };
  },
  
  methods: {
    beforeOpen(e) {
      this.countries = [];
      var keys = Object.keys(Countries.getList());
      for (var i = 0; i < keys.length; i++) {
        this.countries.push({
          text: Countries.getCountry(keys[i]),
          value: keys[i].toLowerCase(),
        });
      }
      
      if (e.params?.newEmployeeInfo) {
        this.newEmployeeInfo = e.params.newEmployeeInfo;
        this.newEmployeeInfo.country = Countries.getCountry(this.newEmployeeInfo.countryCode);
      }
    },
    focusInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      label.classList.add("focus");
      label.classList.add("valid");
    },
    blurInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      label.classList.remove("focus");
    },
    keyupInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      if (input.classList.contains("isNotNull")) {
        if (input.value == undefined || input.value == "") {
          input.classList.add("is-invalid");
          label.classList.add("text-danger");
        } else {
          input.classList.remove("is-invalid");
          label.classList.remove("text-danger");
        }
      }
    },
    otherAddress() {
      var input = document.getElementById("address");
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      label.classList.remove("text-danger");
    },
    getAddressData(addressData) {
      Logs.logDebug("getAddressData", addressData);

      const streetNumber2 = addressData.street_number_2 ? " - " + addressData.street_number_2 : "";
      this.address = `${addressData.street_number}${streetNumber2} ${addressData.route}, ${addressData.locality}, ${addressData.administrative_area_level_1} ${addressData.postal_code}`;
      this.newEmployeeInfo.address1 = `${addressData.street_number} ${addressData.route}`;
      this.newEmployeeInfo.suburb = addressData.locality;
      this.newEmployeeInfo.postcode = addressData.postal_code;
      this.newEmployeeInfo.state = addressData.administrative_area_level_1;
      this.newEmployeeInfo.countryCode = Countries.getCountryCode(addressData.country);
      this.newEmployeeInfo.country = addressData.country;

      window.$(".ng-newstate")[0].disabled = false;

      Logs.logDebug("getAddressData address=", this.address);
    },
    querySearch(queryString, cb) {
      var listDrop = this.countries;
      queryString = this.newEmployeeInfo.country;
      var results = queryString
        ? listDrop.filter(this.createFilter(queryString))
        : listDrop;
      cb(results);
    },
    clickAutocomplete() {
      this.newEmployeeInfo.country = "";
    },
    getBackAutocompete() {
      var timeout = setTimeout(() => {
        this.newEmployeeInfo.countryCode = Countries.getCountryCode(this.newEmployeeInfo.country);
        clearTimeout(timeout);
      }, 300);
    },
    createFilter(queryString) {
      return (country) => {
        return (
          country.text.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },
    changeLanguage(item) {
      this.newEmployeeInfo.country = item.text;
      this.newEmployeeInfo.countryCode = item.value;
      if (this.newEmployeeInfo.countryCode != "au") {
        this.newEmployeeInfo.state = "OTHER";
        window.$(".ng-newstate")[0].disabled = true;
      } else {
        window.$(".ng-newstate")[0].disabled = false;
      }
    },
    openModal() {
      this.$modal.show("modal-newemployeestep1", {
        data: "data",
        newEmployeeInfo: this.newEmployeeInfo
      });
    },
    closeModal() {
      this.$modal.hide("modal-newemployeestep1");
    },
    closeModalAndClearData() {
      this.$modal.hide("modal-newemployeestep1");
    },
    Previous() {
      this.closeModal();
      this.$modal.show("modal-newemployeestep0", {
        newEmployeeInfo: this.newEmployeeInfo
      });
    },
    skipAddress() {
       this.$swal({
                title: "Skip Personal Details!",
                text: "I would like to skip personal details of employee for now and add later.",
                type: "warning",
                confirmButtonColor:"#ffcb0c", // "#3085d6",
                confirmButtonText: "Skip",
                confirmButtonClass:'custom_swal',
                showCancelButton: true,
                }).then((result) => {
                if (result.isConfirmed) {
                    this.getDataStep1();
                        this.closeModal();
                        this.$modal.show("modal-newemployeestep2", {
                          data: "data",
                          newEmployeeInfo: this.newEmployeeInfo
                    });
                    return true;
                } else {
                    return false;
                }
      });
      
    },
    openModalStep2() {
      if (Validate.isOpenCheckValidate()) {
        this.checkValidate();
        if (this.isValidate) {
          //this.checkContactNumber()
          this.getDataStep1();
          this.closeModal();
          this.$modal.show("modal-newemployeestep2", {
            data: "data",
            newEmployeeInfo: this.newEmployeeInfo
          });
        }
      } else {
        this.closeModal();
        this.$modal.show("modal-newemployeestep2", {
          data: "data",
          newEmployeeInfo: this.newEmployeeInfo
        });
      }
    },
    getDataStep1() {
      this.newEmployeeInfo.contactNumber = this.newEmployeeInfo.contactNumber.replace(/[\s()]+/g, "");
      this.$store.dispatch(DATA_STEP1, this.newEmployeeInfo);
    },
    checkValidate() {
      this.isValidate = true;
      window.$(".is-invalid").removeClass("is-invalid");
      var address = window.$(".ng-newaddress");
      var address1 = window.$(".ng-newaddress1");
      var suburb = window.$(".ng-newsuburb");
      var postcode = window.$(".ng-newpostcode");
      var stale = window.$(".ng-newstate");
      var phone = window.$(".ng-newphone");
      if (this.isCheckFindMyAddress) {
        //Check Address 1
        if (Validate.isEmpty(this.newEmployeeInfo.address1)) {
          address1.addClass("is-invalid");
          this.isValidate = false;
        }
        // Check Suburb
        if (Validate.isEmpty(this.newEmployeeInfo.suburb)) {
          suburb.addClass("is-invalid");
          this.isValidate = false;
        }
        // Check PostCode
        if (Validate.isEmpty(this.newEmployeeInfo.postcode)) {
          postcode.addClass("is-invalid");
          this.isValidate = false;
        }
        // Check Stale
        if (Validate.isEmpty(this.newEmployeeInfo.state)) {
          stale.addClass("is-invalid");
          this.isValidate = false;
        }
      } else {
        if (Validate.isEmpty(this.address)) {
          address.addClass("is-invalid");
          this.isValidate = false;
        }
      }
      if (!Validate.isPhone(this.newEmployeeInfo.contactNumber.replace(/[\s()]+/g, ""))) {
        phone.addClass("is-invalid");
        this.isValidate = false;
      }
    },
    onChange(event) {
      this.newEmployeeInfo.state = event.target.value;
    },
    // Request API
    checkContactNumber() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        var modelRequest = {
          companyId: Session.get("currenCompanyId"),
          employeeId: 0,
          contactNumber: this.newEmployeeInfo.contactNumber.replace(/[\s()]+/g, ""),
        };
        this.$store
          .dispatch(CHECK_CONTACT_NUMBER, modelRequest)
          .then(() => {
            this.$root.$refs.AppCyrus.finish();
            this.getDataStep1();
            this.closeModal();
            this.$modal.show("modal-newemployeestep2", {
              data: "data",
              newEmployeeInfo: this.newEmployeeInfo
            });
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    formatPhoneNumber() {
      var x = this.newEmployeeInfo.contactNumber
        .replace(/[^+\d]/g, "")
        .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
      if (x[1].indexOf("+") == 0) {
        this.newEmployeeInfo.contactNumber = !x[2]
          ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
          : "(" +
            x[1].slice(0, 3) +
            ") " +
            x[1].slice(3) +
            " " +
            x[2] +
            (x[3] ? " " + x[3] : "");
      } else {
        this.newEmployeeInfo.contactNumber = !x[2]
          ? x[1]
          : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
      }
    },
  },
  watch: {
    'newEmployeeInfo.contactNumber': function (val) {
      var x = val
        .replace(/[^+\d]/g, "")
        .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
      if (x[1].indexOf("+") == 0) {
        this.newEmployeeInfo.contactNumber = !x[2]
          ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
          : "(" +
            x[1].slice(0, 3) +
            ") " +
            x[1].slice(3) +
            " " +
            x[2] +
            (x[3] ? " " + x[3] : "");
      } else {
        this.newEmployeeInfo.contactNumber = !x[2]
          ? x[1]
          : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

</style>
