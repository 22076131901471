// https://github.com/apertureless/vue-chartjs
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'DoughnutChart',
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    var chartData = this.chartData;
    if (chartData === undefined) {
      chartData = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
          {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
              'rgba(255, 99, 132, 1.0)',
              'rgba(54, 162, 235, 1.0)',
              'rgba(255, 206, 86, 1.0)',
              'rgba(75, 192, 192, 1.0)',
              'rgba(153, 102, 255, 1.0)',
              'rgba(255, 159, 64, 1.0)'
            ],
            borderColor: [
              'rgba(255, 99, 132, 1.0)',
              'rgba(54, 162, 235, 1.0)',
              'rgba(255, 206, 86, 1.0)',
              'rgba(75, 192, 192, 1.0)',
              'rgba(153, 102, 255, 1.0)',
              'rgba(255, 159, 64, 1.0)'
            ],
            borderWidth: 1
          }
        ]
      }
    }
    var options = this.options;
    if (options === undefined) {
      options = {
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              display: false,
              drawOnChartArea: false
            },
            ticks: {
              display: false,
              beginAtZero: true
            }
          }],
          yAxes: [{
            gridLines: {
              color: 'rgba(0, 0, 0, 0)',
              display: false,
              drawOnChartArea: false
            },
            ticks: {
              display: false,
              beginAtZero: true
            }
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'top',
            formatter: Math.round,
            font: {
              weight: 'bold'
            }
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 0
          }
        },
        legend: {
          'display': true,
          'position': 'bottom',
          'align': 'center',
          'rtl': false
        },
        tooltips: {
          'enabled': true
        },
        maintainAspectRatio: false
      }
    }
    this.renderChart(chartData, options)
  },
  methods: {
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    }
  }
}
