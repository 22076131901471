<template>
<modal name="modal-taxemployee" @before-open="beforeOpen" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-m">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <button aria-label="Close" class="box-title close" data-dismiss="modal" type="button" v-on:click="closeModal()"></button>
                    <div class="onboarding-slide">
                        <div class="onboarding-side-by-side">
                            <div class="headerimg">
                                <h3 class="bold text-center" style="padding: 2.5rem; color: white">Active Employee</h3>
                            </div>
                            <div class="onboarding-content with-gradient" style="width: 100%; padding-bottom: 0px">
                                <v-app>
                                    <p class="title">
                                        Contact details
                                    </p>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group outlined">
                                                <label class="valid" for="">Address</label>
                                                <vue-google-autocomplete v-on:keyup="keyupInput('address')" v-on:blur="blurInput('address')" v-on:focus="focusInput('address')" type="text" v-bind:class="(isCheckFindMyAddress)?'disabled-address': ''" country="au" types="address" id="address" v-on:placechanged="getAddressData" ref="googlemap" v-model="address" name="address" class="form-control ng-address isNotNull"></vue-google-autocomplete>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check text-left" style="margin-bottom: 40px;">
                                        <input v-model="isCheckFindMyAddress" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="isCheckFindMyAddress" ng-model="companyData.le.lsl">
                                        <label class="form-check-label" for="isCheckFindMyAddress">
                                            I am unable to find my address
                                        </label>
                                    </div>
                                    <div v-show="isCheckFindMyAddress">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="address1" class="ng-address1" label="Address 1" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                    <!-- <label for="">Address 1<span class="text-danger">*</span></label> -->
                                                    <!-- <input types="address" id="address1" v-model="address1" name="address1" type="text" class="form-control ng-address1"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group outlined">
                                                    <label class="valid" for="">Address 2</label>
                                                    <vue-google-autocomplete v-on:keyup="keyupInput('address2')" v-on:blur="blurInput('address2')" v-on:focus="focusInput('address2')" id="address2" v-model="address2" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="address2" type="text"></vue-google-autocomplete>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="suburb" class="ng-suburb" label="Suburb" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                    <!-- <label for="">Suburb <span class="text-danger">*</span></label>
                                                    <input v-model="suburb" class="form-control ng-pristine ng-untouched ng-valid ng-suburb ng-valid-required" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" name="suburb" required="" type="text"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field v-model="postcode" class="ng-post-code" label="Postcode" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                    <!-- <label for="">Postcode <span class="text-danger">*</span></label>
                                                    <input v-model="postcode" class="form-control ng-pristine ng-untouched ng-valid ng-post-code ng-valid-required" name="postcode" required="" type="text"> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <!-- <label for=""> State <span class="text-danger">*</span></label>
                                                    <select v-bind:disabled="countryCode != 'au'" v-model="state" class="form-control ng-pristine ng-untouched ng-valid ng-state ng-valid-required" name="state" required="">
                                                        <option value="ACT">ACT</option>
                                                        <option value="NSW" selected="selected">NSW</option>
                                                        <option value="NT">NT</option>
                                                        <option value="QLD">QLD</option>
                                                        <option value="SA">SA</option>
                                                        <option value="TAS">TAS</option>
                                                        <option value="VIC">VIC</option>
                                                        <option value="WA">WA</option>
                                                        <option value="OTHER">Other</option>
                                                    </select> -->
                                                    <v-select :attach="true" v-bind:disabled="countryCode != 'au'" class="ng-state" v-model="state" :items="listState" item-text="text" item-value="value" label="State" :rules="[rules.required]" required outlined color="success"></v-select>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group outlined">
                                                    <label class="valid" style="z-index: 1;" for=""> Country</label>
                                                    <country-flag :country='countryCode' size='normal' class="flag-input" />
                                                    <el-autocomplete @focus="clickAutocomplete()" @blur="getBackAutocompete()" v-model="country" class="inline-input form-control" style="width: 100%; !important;" :fetch-suggestions="querySearch" placeholder="Please Input" @select="changeLanguage">
                                                        <template slot-scope="{ item }">
                                                            <div style="display: flex; width: max-content;">
                                                                <country-flag :country='item.value' size='normal' />
                                                                <div class="value">{{ item.text }}</div>
                                                            </div>
                                                        </template>
                                                    </el-autocomplete>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <v-text-field v-model="contactNumber" class="ng-phone" label="Contact number" type="phone" maxlength="40" :rules="[rules.isPhone]" required outlined color="success" @input="formatPhoneNumber"></v-text-field>
                                                <!-- <label for="">Contact number</label>
                                                <input v-model="contactNumber" class="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-required ng-phone" placeholder="" type="phone" name="phone" @input="formatPhoneNumber"> -->
                                            </div>
                                        </div>
                                    </div>
                                    <p class="title">
                                        Tax Details
                                    </p>
                                    <form class="ng-pristine ng-valid ng-valid-maxlength">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    
                                                    <v-menu v-model="newEmployeeTaxInformationMenu"  :close-on-content-click="false" nudge-left="400px" origin="top left" min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field :value="computedNewEmployeeTaxInfoBirthDateFormatted" class="ng-birth-date" label="Date of Birth" v-bind="attrs" v-on="on" @click:clear="birthDate = null" :rules="[rules.required]" required outlined color="success" append-icon="mdi-calendar"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="birthDate" @change="newEmployeeTaxInformationMenu = false" no-title></v-date-picker>
                                                    </v-menu>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-Status-Residency" v-model="statusResidency" @change="statusResidencyChange()" label="Status of Residency" :items="listStatusResidency" required outlined color="success"></v-select>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group" style="margin-bottom:2px">
                                                    <v-text-field class="ng-tax-file-number" v-show="!isExemption" v-model="edtTaxFileNumber" label="Tax File Number" type="text" maxlength="40" outlined color="success"></v-text-field>
                                                    <v-select :attach="true" v-show="isExemption" v-model="tfnExemption" label="Tax File Number" :items="listTfnExemption" outlined color="success"></v-select>
                                                    
                                                </div>
                                                <div style="position: absolute; top: 50px">
                                                    <div class="small-label pointer text-primary" v-show="!isExemption" v-on:click="clickTaxFileNumber()">No tax file number?</div>
                                                    <div class="small-label pointer text-primary" v-show="isExemption" v-on:click="clickTaxFileNumber()">Has TFN?</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <h6>Additional Information</h6>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="claimTaxFreeThreshold" class="form-check-input ng-pristine ng-untouched ng-valid ng-not-empty" type="checkbox" id="claimTaxFreeThreshold">
                                                    <label class="form-check-label" for="tx-o_ft">
                                                        Claim tax free threshold
                                                    </label>
                                                </div>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <!-- <input v-model="hasHecsOrHelpDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="hasHecsOrHelpDebt">
                                                    <label class="form-check-label" for="tx-o_hp">
                                                        Has HELP or TSL debt
                                                    </label> -->
                                                    <input
                                                        v-model="hasHecsOrHelpDebt"
                                                        name="hasHecsOrHelpDebt"
                                                        class="form-check-input ng-pristine ng-untouched ng-valid ng-empty"
                                                        type="checkbox"                                                        
                                                        id="hasHecsOrHelpDebt"
                                                    />
                                                    <label class="form-check-label" for="hasHecsOrHelpDebt">
                                                        <!-- Has HECS or HELP debt -->
                                                        Has study and training support loan debt 
                                                        <i class="fa fa-info-circle" title="Any of the following-
                                                        Higher Education Loan Program (HELP) debt
                                                        VET Student Loan (VSL) debt
                                                        Financial Supplement (FS) debt
                                                        Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
                                                        Trade Support Loan (TSL) debt"></i>
                                                    </label>
                                                </div>
                                                <!-- <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="hasTslOrStudentStart" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="hasTslOrStudentStart">
                                                    <label class="form-check-label" for="tx-o_sl">
                                                        Has Student Start-up Loan
                                                    </label>
                                                </div> -->
                                                <!-- <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="hasFinancialSupplementDebt" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" id="tx-o_fs" >
                                                    <label class="form-check-label" for="tx-o_fs">
                                                        Has Financial Supplement debt
                                                    </label>
                                                </div> -->
                                            </div>
                                        </div>
                                        <!-- <hr>
                                        <div class="row">
                                            <div class="col-12">
                                                <h6>Tax File Number Declaration</h6>
                                                <div class="form-check" style="margin: 5px 0;">
                                                    <input v-model="taxFileNumberDeclaration" class="form-check-input ng-pristine ng-untouched ng-valid ng-empty" type="checkbox" value="" id="tfnd-p" ng-model="employeeDetails_new.tfnd.p">
                                                    <label class="form-check-label" for="tfnd-p">
                                                        Tick this box if you would like to lodge TFN Declaration in the next STP lodgement. By ticking
                                                        this, you also declare that the information you have given is true and correct.
                                                    </label>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="row" style="margin-top: 10px;">
                                            <div class="col-12">
                                                <div @click="checkOverwriteTax()" class="row text-left" style="margin-top: 15px;">
                                                    <div class="text-left" style="margin-left: 20px;">
                                                        <v-switch id="overwriteTax" v-model="hasTaxTemplate" inset style="margin-top: 0px;"></v-switch>
                                                    </div>
                                                    <div class="text-right" style="margin-top: 3px;">
                                                        <label for="overwriteTax">Overwrite tax</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="hasTaxTemplate" class="input-group col-6" style="margin-top: 5px;">
                                                <v-text-field class="ng-tax-file-number" v-model="taxTemplate" step="0.01" type="number" maxlength="40" outlined color="success" prepend-inner-icon="mdi-currency-usd"></v-text-field>
                                                <!-- <div class="input-group-prepend">
                                                    <div class="input-group-text">$</div>
                                                </div>
                                                <input v-model.number="taxTemplate" type="number" class="form-control ng-tax-file-number" id="payRate" maxlength="9"> -->
                                            </div>
                                        </div>
                                    </form>
                                </v-app>
                            </div>
                        </div>
                    </div>
                    <!-- </slick> -->
                    <div class="row" style="padding:12px">
                        <div class="col-6 text-left">
                            <button type="button" class="btn" v-on:click="closeModal()" style="">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Cancel
                            </button>
                        </div>
                        <div class="col-6 text-right">
                            <!-- ngIf: currentIndex == 0 -->
                            <button class="btn btn-primary ng-binding" v-on:click="activateEmployee()" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Activate
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Session from "@/utils/SessionStore"
import * as DataSelect from "@/utils/DataSelect.js";
import * as Utils from "@/utils/Utils";
import * as Countries from "@/utils/Countries"
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import CountryFlag from 'vue-country-flag'
import {
    CHECK_TAX_NUMBER,
    UPDATE_EMPLOYEE
} from "@/store/actionsType";
import moment from 'moment';

export default {
    name: "TaxEmployeeModal",
    props: [],
    components: {
        VueGoogleAutocomplete,
        CountryFlag
    },
    data() {
        return {
            isValidate: true,
            currentEmployee: null,

            //Contact
            contactNumber: "",
            address: "",
            isCheckFindMyAddress: false,
            address1: "",
            address2: "",
            suburb: "",
            postcode: "",
            state: "",
            countries: [],
            countryCode: "",
            country: "",

            //tax
            isExemption: false,
            tfnExemption: null,
            birthDate: "",
            // edtDay: "",
            // edtMonth: "",
            // edtYear: "",
            statusResidency: null,
            edtTaxFileNumber: "",
            claimTaxFreeThreshold: false,
            hasHecsOrHelpDebt: false,
            // hasTslOrStudentStart: false,
            hasFinancialSupplementDebt: false,
            taxFileNumberDeclaration: false,
            hasTaxTemplate: false,
            taxTemplate: 0,
            
            //Valadate
            rules: {
                required: value => !!value || '',
                animal: [val => (val || '').length > 0 || 'This field is required'],
                isPhone: val => !isNaN(val.replace(/[\s()+]+/g, '')) && 
                (
                    ((val.indexOf('0') == 0 && val.replace(/\s+/g, '').length <= 10) || 
                    (val.indexOf('+') == 1 && val.replace(/\s+/g, '').length <= 14)
                    ) || 'Phone numbers have a maximum of 10 numbers'
                )
                || "phone number must be number",
                //min: v => v.length >= 0 || 'Min 8 characters',
                // emailMatch: () => (`The email and password you entered don't match`),
            },

            //Data
            listState: DataSelect.getList('listState'),
            listStatusResidency: DataSelect.getList('listStatusResidency'),
            listTfnExemption: DataSelect.getList('listTfnExemption'),

            newEmployeeTaxInformationMenu: false
        };
    },
    
    methods: {
        beforeOpen(event) {
            var keys = Object.keys(Countries.getList());
            for (var i = 0; i < keys.length; i++) {
                this.countries.push({
                    "text": Countries.getCountry(keys[i]),
                    "value": keys[i].toLowerCase()
                })
            }
            this.currentEmployee = event.params.data
            if(this.currentEmployee.active != 'PENDING') {
                this.contactNumber = this.currentEmployee.contactNumber
                this.address1 = this.currentEmployee.address1
                this.address2 = this.currentEmployee.address2
                this.suburb = this.currentEmployee.suburb
                this.postcode = this.currentEmployee.postcode
                this.state = this.currentEmployee.state
                this.countryCode = this.currentEmployee.country
                if (this.countryCode == "au") {
                    this.address = this.address1 + ", " + this.suburb + ", " + this.state
                } else {
                    this.address = this.address1 + ", " + this.suburb
                }
                this.country = Countries.getCountry(this.countryCode)
                this.isExemption = this.currentEmployee.isExemption
                this.tfnExemption = this.currentEmployee.tfnExemption
                this.birthDate = this.currentEmployee.birthDate
                this.statusResidency = this.currentEmployee.statusResidency
                this.edtTaxFileNumber = this.currentEmployee.taxNumber
                this.claimTaxFreeThreshold = this.currentEmployee.claimTaxFreeThreshold
                this.hasHecsOrHelpDebt = this.currentEmployee.hasHecsOrHelpDebt
                // this.hasTslOrStudentStart = this.currentEmployee.hasTslOrStudentStart
                this.hasFinancialSupplementDebt = this.currentEmployee.hasFinancialSupplementDebt
                this.taxFileNumberDeclaration = this.currentEmployee.taxFileNumberDeclaration
                this.hasTaxTemplate = this.currentEmployee.hasTaxTemplate
                this.taxTemplate = this.currentEmployee.taxTemplate
            } else {
                this.contactNumber = ""
                this.address = ""
                this.isCheckFindMyAddress = false
                this.address1 = ""
                this.address2 = ""
                this.suburb = ""
                this.postcode = ""
                this.state = null
                this.countryCode = "au"
                this.country = Countries.getCountry(this.countryCode)
                this.isExemption = false
                this.tfnExemption = null
                this.birthDate = ""
                this.statusResidency = null
                this.edtTaxFileNumber = ""
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                // this.hasTslOrStudentStart = false
                this.hasFinancialSupplementDebt = false
                this.taxFileNumberDeclaration = false
                this.hasTaxTemplate = false
                this.taxTemplate = 0
            }
        },
        openModal() {
            this.$modal.show("modal-taxemployee", {
                data: "data"
            });
        },
        closeModal() {
            this.$modal.hide("modal-taxemployee");
            if (this.$global.path == '/dashboard') {
                this.$modal.show('modal-editemployee', {
                    employee: this.currentEmployee
                });
            }
            if (this.$global.path == '/employees') {
                var pageEmployee = this.$root.$refs.People
                if(pageEmployee.isChangeView) {
                    this.$modal.show('modal-editemployee', {
                        employee: this.currentEmployee
                    });
                } else {
                    this.$root.$refs.NewEditEmployee.currentEmployee = this.currentEmployee
                    this.$root.$refs.NewEditEmployee.setDataDetail()
                }
            }
        },
        focusInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            label.classList.add('focus')
            label.classList.add('valid')
        },
        blurInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            label.classList.remove('focus')
        },
        keyupInput(id) {
            var input = document.getElementById(id);
            var parent = input.parentElement
            var label = parent.querySelector('label')
            if (input.classList.contains('isNotNull')) {
                if (input.value == undefined || input.value == "") {
                    input.classList.add('is-invalid')
                    label.classList.add('text-danger')
                } else {
                    input.classList.remove('is-invalid')
                    label.classList.remove('text-danger')
                }
            }
        },
        getAddressData(addressData) {
            var street_number = ""
            if (addressData.street_number != undefined) {
                street_number = addressData.street_number + " "
            }
            this.address1 = street_number + " " + addressData.route;
            this.suburb = addressData.locality;
            this.postcode = addressData.postal_code;
            this.countryCode = "au"
            this.country = Countries.getCountry("AU")
            this.state = addressData.administrative_area_level_1;
        },
        querySearch(queryString, cb) {
            var listDrop = this.countries;
            queryString = this.country
            var results = queryString ? listDrop.filter(this.createFilter(queryString)) : listDrop;
            cb(results);
        },
        createFilter(queryString) {
            return (country) => {
                return (country.text.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
            };
        },
        clickAutocomplete() {
            this.country = ""
        },
        getBackAutocompete() {
            var timeout = setTimeout(() => {
                if (this.country == "") {
                    this.country = Countries.getCountry(this.countryCode)
                }
                clearTimeout(timeout)
            }, 300);
        },
        changeLanguage(item) {
            this.country = item.text
            this.countryCode = item.value
            if(this.countryCode != "au") {
                this.state = "OTHER"
                window.$('.ng-state')[0].disabled = true
            } else {
                window.$('.ng-state')[0].disabled = false
            }
        },
        onTypeExemptionChange(event) {
            this.tfnExemption = event.target.value
        },
        statusResidencyChange() {
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        checkStatusResidency(statusResidency) {
            if (statusResidency == 'AustraliaResident') {
                document.getElementById("claimTaxFreeThreshold").disabled = false;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'ForeignResident') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                this.claimTaxFreeThreshold = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = false;
                // document.getElementById("hasTslOrStudentStart").disabled = false;
            } else if (statusResidency == 'WorkingHoliday') {
                document.getElementById("claimTaxFreeThreshold").checked = false;
                document.getElementById("hasHecsOrHelpDebt").checked = false;
                // document.getElementById("hasTslOrStudentStart").checked = false;
                this.claimTaxFreeThreshold = false
                this.hasHecsOrHelpDebt = false
                // this.hasTslOrStudentStart = false

                document.getElementById("claimTaxFreeThreshold").disabled = true;
                document.getElementById("hasHecsOrHelpDebt").disabled = true;
                // document.getElementById("hasTslOrStudentStart").disabled = true;
            }
        },
        checkOverwriteTax() {
            if (this.hasTaxTemplate) {
                this.disabled()
            } else {
                this.checkStatusResidency(this.statusResidency)
            }
        },
        disabled() {
            document.getElementById("claimTaxFreeThreshold").checked = false;
            document.getElementById("hasHecsOrHelpDebt").checked = false;
            // document.getElementById("hasTslOrStudentStart").checked = false;
            this.claimTaxFreeThreshold = false
            this.hasHecsOrHelpDebt = false
            // this.hasTslOrStudentStart = false

            document.getElementById("claimTaxFreeThreshold").disabled = true;
            document.getElementById("hasHecsOrHelpDebt").disabled = true;
            // document.getElementById("hasTslOrStudentStart").disabled = true;
        },
        clickTaxFileNumber() {
            if (this.isExemption) {
                this.isExemption = false
                this.tfnExemption = null
            } else {
                this.isExemption = true
                this.tfnExemption = "NoTFNQuoted"
                this.taxNumber = ""
            }
        },
        activateEmployee() {
            if (Validate.isOpenCheckValidate()) {
                this.checkValidate()
                if (this.isValidate) {
                    this.$swal({
                        title: 'Are you sure?',
                        text: "This employee was emailed to self-activate later. Are you sure want to activate this employee now?",
                        type: 'message',
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {
                            if (this.edtTaxFileNumber != '') {
                                this.checkTaxNumber()
                            } else {
                                this.updateEmployee()
                            }
                        }
                    });
                }
            } else {
                this.closeModal()
                this.$modal.show("modal-newemployeestep4", {
                    isCheckStep4: true
                });
            }
        },
        updateEmployee() {
            this.$root.$refs.AppCyrus.start()
            var request = this.currentEmployee
            request.contactNumber = this.contactNumber
            request.address1 = this.address1
            request.address2 = this.address2
            request.suburb = this.suburb
            request.postcode = this.postcode
            request.state = this.state
            request.country = this.countryCode
            request.isExemption = this.isExemption
            request.tfnExemption = this.tfnExemption
            request.birthDate = this.birthDate
            request.statusResidency = this.statusResidency
            request.taxNumber = this.edtTaxFileNumber
            request.claimTaxFreeThreshold = this.claimTaxFreeThreshold
            request.hasHecsOrHelpDebt = this.hasHecsOrHelpDebt
            // request.hasTslOrStudentStart = this.hasTslOrStudentStart
            request.hasFinancialSupplementDebt = this.hasFinancialSupplementDebt
            request.taxFileNumberDeclaration = this.taxFileNumberDeclaration
            request.hasTaxTemplate = this.hasTaxTemplate
            request.taxTemplate = this.taxTemplate
            request.active = "ACTIVE"
            request.bank = {
                paymentMethod: "Cash",
                bankAccountName: "",
                bankAccountBSB: "",
                bankAccountNumber: ""
            }
            request.fund = {
                superFundType: "Regulated Superannuation Fund",
                contributionRate: 9.5,
                fundName: "",
                fundABN: "",
                fundAddress: "",
                fundUSI: "",
                fundEmployerNumber: "",
                memberNumber: "",
                fundBankName: "",
                fundBankBSB: "",
                fundBankNumber: ""
            }
            request.superGuarantee = 0
            request.employerContribution = 0
            request.superSalarySacrifice = 0
            this.$store.dispatch(UPDATE_EMPLOYEE, request).then(() => {
                this.$root.$refs.AppCyrus.finish()
                Utils.toastSuccess("Update employee successful!")
                this.closeModal()
            }).catch(() => {
                this.$root.$refs.AppCyrus.fail()
            })
        },
        checkValidate() {
            var date = window.$(".ng-birth-date")
            // var month = window.$(".ng-Month")
            // var year = window.$(".ng-Year")
            var tax = window.$(".ng-tax-file-number")
            var status = window.$(".ng-Status-Residency")
            //Check Day
            if (Validate.isEmpty(this.birthDate)) {
                date.addClass("is-invalid");
            } else {
                date.removeClass("is-invalid");
            }
            // if (Validate.isValidateDate("Day", this.edtDay)) {
            //     day.addClass("is-invalid");
            // } else {
            //     day.removeClass("is-invalid");
            // }
            // // Check Month
            // if (Validate.isValidateDate("Month", this.edtMonth)) {
            //     month.addClass("is-invalid");
            // } else {
            //     month.removeClass("is-invalid");
            // }
            // // Check Year
            // if (Validate.isValidateDate("Year", this.edtYear)) {
            //     year.addClass("is-invalid");
            // } else {
            //     year.removeClass("is-invalid");
            // }

            // if (Validate.isFuture(this.edtDay, this.edtMonth, this.edtYear)) {
            //     day.addClass("is-invalid");
            //     month.addClass("is-invalid");
            //     year.addClass("is-invalid");
            // } else {
            //     day.removeClass("is-invalid");
            //     month.removeClass("is-invalid");
            //     year.removeClass("is-invalid");
            // }

            var checkTax = false
            var checkTaxEmpty = false
            if (!this.isExemption) {
                checkTax = Validate.CheckTFN(this.edtTaxFileNumber)
                checkTaxEmpty = Validate.isEmpty(this.edtTaxFileNumber)
            } else {
                checkTax = false;
                checkTaxEmpty = false;
            }

            //Check Tax File Number
            if (checkTaxEmpty || checkTax) {
                tax.addClass("is-invalid");
            } else {
                tax.removeClass("is-invalid");
            }
            //Check StatusResidency
            if (Validate.isEmpty(this.statusResidency)) {
                status.addClass("is-invalid");
            } else {
                status.removeClass("is-invalid");
            }
            // 
            if (Validate.isEmpty(this.birthDate) || Validate.isEmpty(this.statusResidency) || checkTax || checkTaxEmpty) {
                this.isValidate = false
            } else {
                this.isValidate = true
            }
        },
        // Request API
        checkTaxNumber() {
            if (Session.has("currenCompanyId")) {
                this.$root.$refs.AppCyrus.start()
                var modelRequest = {
                    "companyId": Session.get("currenCompanyId"),
                    "employeeId": 0,
                    "taxNumber": this.edtTaxFileNumber,
                }
                this.$store.dispatch(CHECK_TAX_NUMBER, modelRequest)
                    .then(() => {
                        this.$root.$refs.AppCyrus.finish()
                        this.updateEmployee()
                    }).catch(() => {
                        this.$root.$refs.AppCyrus.fail()
                    });
            }
        },
    },
    watch: {
        "contactNumber": function (val) {
            var x = val.replace(/[^+\d]/g, '').match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
            if (x[1].indexOf('+') == 0) {
                this.contactNumber = !x[2] ? '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) : '(' + x[1].slice(0,3) + ') ' + x[1].slice(3) + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            } else {
                this.contactNumber = !x[2] ? x[1] : '' + x[1] + ' ' + x[2] + (x[3] ? ' ' + x[3] : '');
            }
        }
    },
    computed: {
        computedNewEmployeeTaxInfoBirthDateFormatted () {
            return this.birthDate ? moment(this.birthDate).format('DD/MM/YYYY') : '';
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>