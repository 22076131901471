<template>
<modal name="modal-newemployeestep2" @before-open="beforeOpen" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="z-index: 1050; display: block; overflow: auto;">
        <div class="modal-dialog modal-xl">
            <div class="modal-content" uib-modal-transclude="">
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <button aria-label="Close" class="box-title close" data-dismiss="modal" type="button" v-on:click="closeModal()"></button>
                    <div class="onboarding-slide" ng-class="{'hide' : currentIndex != 2 }" style="">
                        <div class="onboarding-side-by-side">
                            <div class="headerimg">
                                <img class="img" alt="" src="/assets/img/img_step0.png">
                            </div>
                            <div class="onboarding-content with-gradient" style="padding-bottom: 0px">
                                <p class="title">
                                    Bank &amp; Super Details
                                </p>
                                <div class="onboarding-text">
                                    Retirement is more wonderful with super, help your employees transfering super whenever they recieve their wages
                                </div>
                                <form name="bankForm" class="ng-pristine ng-valid">
                                    <v-app>
                                        <h5>Payment Method</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" class="ng-bank" v-model="newEmployeeInfo.paymentMethod" :items="listPaymentMethod" required outlined color="success"></v-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row fade ng-scope" v-if="newEmployeeInfo.paymentMethod === 'Bank'" style="">
                                            <div class="col-sm-5">
                                                <div class="form-group">
                                                    <v-text-field class="ng-account-name" v-model="newEmployeeInfo.bankAccountName" label="Account Name" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <div class="form-group">
                                                    <v-text-field class="ng-bsb" v-model="newEmployeeInfo.bankAccountBSB" label="BSB" type="text" maxlength="40" :rules="[rules.required]" required outlined color="success"></v-text-field>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="form-group">
                                                    <v-text-field class="ng-account-number" v-model="newEmployeeInfo.bankAccountNumber" label="Acc. Number" type="text" minlength="6" maxlength="9" :rules="[rules.required]" required outlined color="success" messages="6~9 Digits"></v-text-field>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <h5>Super Fund</h5>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-select :attach="true" v-model="newEmployeeInfo.superFundType" label="Type" :items="listTypeSuper" outlined color="success"></v-select>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <v-text-field class="ng-Contribution-Rate" v-model="newEmployeeInfo.contributionRate" label="Contribution Rate (%)" step=".01" type="number" maxlength="40" :rules="[rules.required]" outlined color="success"></v-text-field>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="newEmployeeInfo.superFundType === 'Self Managed Superannuation Fund'" class="ng-scope">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Name" v-model="newEmployeeInfo.fundName" label="Name" type="text" maxlength="40" :rules="[rules.required]" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-ABN" v-model="newEmployeeInfo.fundABN" label="ABN" type="text" maxlength="11" :rules="[rules.required]" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field v-model="newEmployeeInfo.fundAddress" label="Electronic Service Address" type="text" maxlength="40" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field v-model="newEmployeeInfo.memberNumber" label="Employer Number (optional)" type="text" maxlength="40" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <h6>Bank details</h6>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Account-Name" v-model="newEmployeeInfo.fundBankName" label="Account Name" type="text" maxlength="40" :rules="[rules.required]" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-BSB" v-model="newEmployeeInfo.fundBankBSB" label="BSB" type="text" maxlength="6" :rules="[rules.required]" outlined color="success"></v-text-field>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-Sf-Account-Number" v-model="newEmployeeInfo.fundBankNumber" label="Account Number" type="text" maxlength="9" :rules="[rules.required]" outlined color="success" messages="6~9 digits"></v-text-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="newEmployeeInfo.superFundType === 'Regulated Superannuation Fund'" class="ng-scope">
                                            <form v-on:submit.prevent="searchSupperFund()" action="" method="post">
                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <v-text-field v-model="edtSearchSupperFund" label="Search Super Company by Name, or ABN" type="text" maxlength="40" outlined color="success"></v-text-field>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <div class="form-group">
                                                            <button type="submit" class="btn btn-primary" style="margin-top:3px" ng-disabled="superSearching">
                                                                <span class="spinner-grow spinner-grow-sm ng-hide"></span>
                                                                Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="alert alert-green ng-hide" role="alert" ng-show="superList &amp;&amp; superList.length>0">
                                                <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                <table class="table table-sm table-border-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">ABN</th>
                                                            <th scope="col">USI</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div v-if="isShowListSupperFund && listSupperFund.length > 0" class="alert alert-green" role="alert" ng-show="superList &amp;&amp; superList.length>0" style="">
                                                <h6 style="margin-bottom:10px">Select your super fund</h6>
                                                <table class="table table-sm table-border-white">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">ABN</th>
                                                            <th scope="col">USI</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item) in listSupperFund" :key="item.id" @click="detailSupperFund(item.identifierValue)" ng-repeat="superFundDetails in superList" class="text-primary-hover pointer ng-scope" style="">
                                                            <td class="ng-binding">{{item.organisationName}}</td>
                                                            <td class="ng-binding">{{item.identifierValue}}</td>
                                                            <td class="ng-binding">{{item.fundProductUsi}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <el-pagination class="pagination" @current-change="handleCurrentChange" v-show="isShowListSupperFund && totalPage > 1" background layout="prev, pager, next" :page-size=pageSize :total=totalPage>
                                            </el-pagination>
                                            <table v-show="isShowListSupperFund && listSupperFund.length == 0" style="width:100%" class="fade ng-scope text-danger">
                                                <tbody>
                                                    <tr>
                                                        <td><b>Sorry! We cannot find this Super Company.</b></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div v-show="isShowDetailSupperFund" class="row">
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <label for="">Super Fund Name</label>
                                                        <div class="small-label text-danger text-right os-dropdown-trigger" style="margin-top: -26px;" @click="remoteSupperFund()">
                                                            <i class="icon-feather-trash-2"></i>
                                                        </div>
                                                        <div style="margin-top:10px" class="">
                                                            <b class="ng-binding"></b>
                                                            <b class="ng-binding" style="margin-top: 20px;">{{newEmployeeInfo.organisationName}}</b>
                                                        </div>
                                                        <div style="margin-top:10px" class="">
                                                            <b class="ng-binding"></b>
                                                            <div class="ng-binding">ABN : {{newEmployeeInfo.identifierValue}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <div class="form-group">
                                                        <v-text-field class="ng-number" v-model="newEmployeeInfo.memberNumber" label="Number" type="text" maxlength="40" outlined color="success" :rules="[rules.required]"></v-text-field>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </v-app>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="padding:12px">
                        <div class="col-6 text-left">
                            <button type="button" class="btn btn-link" v-on:click="gotoStep1()" style="">
                                <i class="icon-feather-arrow-left"></i> Previous
                            </button>
                        </div>
                        <div class="col-6 text-right">
                            <button class="btn btn-primary ng-binding" v-on:click="openModalStep3()" ng-disabled="loading">
                                <span class="spinner-grow spinner-grow-sm ng-hide" ng-show="loading"></span>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Loading -->
                <div v-if="isLoading">
                    <Loading></Loading>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as DataSelect from "@/utils/DataSelect";
import Loading from '@/components/Loading'
import {
    DATA_STEP2,
    SEARCH_SUPERFUND,
    DETAIL_SUPERFUND,
} from "@/store/actionsType";
export default {
    name: "NewEmployeeStep2Modal",
    props: [],
    components: {
        Loading
    },
    data() {
        return {
            newEmployeeInfo: {},
            isValidate: true,
            isLoading: false,
            // Supper Fund
            isShowListSupperFund: false,
            edtSearchSupperFund: "",
            listSupperFund: [],
            listSupperFundFull: [],
            isShowDetailSupperFund: false,
            totalPage: 0,
            pageSize: 10,
            currenPage: 1,

            //Valadate
            rules: {
                required: value => !!value || '',
                animal: [val => (val || '').length > 0 || 'This field is required'],
                //min: v => v.length >= 0 || 'Min 8 characters',
                // emailMatch: () => (`The email and password you entered don't match`),
            },
            
            listPaymentMethod: DataSelect.getList('listPaymentMethod'),
            listTypeSuper: DataSelect.getList('listTypeSuper'),
        };
    },
    methods: {
        beforeOpen(e) {
            if (e.params?.newEmployeeInfo) {
                this.newEmployeeInfo = e.params.newEmployeeInfo;
            }
        },
        openModal() {
            this.$modal.show("modal-newemployeestep2", {
                data: "data",
                newEmployeeInfo: this.newEmployeeInfo
            });
        },
        closeModal() {
            this.$modal.hide("modal-newemployeestep2");
        },
        gotoStep1() {
            this.closeModal()
            this.$modal.show('modal-newemployeestep1');
        },
        openModalStep3() {
            if (Validate.isOpenCheckValidate()) {
                this.checkValidate()
                if (this.isValidate) {
                    this.getDataStep2()
                    this.closeModal()
                    this.$modal.show("modal-newemployeestep3", {
                        data: "data",
                        newEmployeeInfo: this.newEmployeeInfo
                    });
                }
            } else {
                this.closeModal()
                this.$modal.show("modal-newemployeestep3", {
                    data: "data",
                    newEmployeeInfo: this.newEmployeeInfo
                });
            }
        },
        onChange(event) {
            this.newEmployeeInfo.paymentMethod = event.target.value
        },
        onChangeType(event) {
            this.newEmployeeInfo.superFundType = event.target.value
        },
        getDataStep2() {
            this.$store.dispatch(DATA_STEP2, this.newEmployeeInfo)
        },
        checkValidate() {
            this.isValidate = true
            var accountName = window.$(".ng-account-name")
            var bSB = window.$(".ng-bsb")
            var accNumber = window.$(".ng-account-number")
            var contributionRate = window.$(".ng-Contribution-Rate")
            var SfName = window.$(".ng-Sf-Name")
            var SfABN = window.$(".ng-Sf-ABN")
            var SfAccountName = window.$(".ng-Sf-Account-Name")
            var SfBSB = window.$(".ng-Sf-BSB")
            var SfAccountNumber = window.$(".ng-Sf-Account-Number")
            var number = window.$('.ng-number')

            if (this.newEmployeeInfo.paymentMethod == 'Bank') {
                //Check Account Name
                if (Validate.isEmpty(this.newEmployeeInfo.bankAccountName)) {
                    accountName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    accountName.removeClass("is-invalid");
                }
                // Check BSB
                if (Validate.isEmpty(this.newEmployeeInfo.bankAccountBSB) || Validate.isNumber(this.newEmployeeInfo.bankAccountBSB)) {
                    bSB.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    bSB.removeClass("is-invalid");
                }
                // Check ACC Number
                if (Validate.isEmpty(this.newEmployeeInfo.bankAccountNumber) || Validate.isNumber(this.newEmployeeInfo.bankAccountNumber)) {
                    accNumber.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    accNumber.removeClass("is-invalid");
                }
            }
            // Contribution Rate
            if (Validate.isEmpty(this.newEmployeeInfo.contributionRate)) {
                contributionRate.addClass("is-invalid");
                this.isValidate = false
            } else {
                contributionRate.removeClass("is-invalid");
            }
            if (this.newEmployeeInfo.superFundType === "Self Managed Superannuation Fund") {
                //Check ng-Sf-Name
                if (Validate.isEmpty(this.newEmployeeInfo.fundName)) {
                    SfName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfName.removeClass("is-invalid");
                }
                // Check ng-Sf-ABN
                if (Validate.isEmpty(this.newEmployeeInfo.fundABN) || Validate.isNumber(this.newEmployeeInfo.fundABN)) {
                    SfABN.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfABN.removeClass("is-invalid");
                }
                // Check ng-Sf-Account-Name
                if (Validate.isEmpty(this.newEmployeeInfo.fundBankName)) {
                    SfAccountName.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfAccountName.removeClass("is-invalid");
                }
                // Check ng-Sf-BSB
                if (Validate.isEmpty(this.newEmployeeInfo.fundBankBSB) || Validate.isNumber(this.newEmployeeInfo.fundBankBSB)) {
                    SfBSB.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfBSB.removeClass("is-invalid");
                }
                // Check ng-Sf-AccountNumber
                if (Validate.isEmpty(this.newEmployeeInfo.fundBankNumber) || Validate.isNumber(this.newEmployeeInfo.fundBankNumber)) {
                    SfAccountNumber.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    SfAccountNumber.removeClass("is-invalid");
                }
            } else if(this.isShowDetailSupperFund) {
                if(Validate.isEmpty(this.newEmployeeInfo.memberNumber)) {
                    number.addClass("is-invalid");
                    this.isValidate = false
                } else {
                    number.removeClass("is-invalid");
                }
            }
        },

        // Request API
        searchSupperFund() {
            this.isShowDetailSupperFund = false
            this.$root.$refs.AppCyrus.start()
            var request = {
                "keySearch": this.edtSearchSupperFund,
                "page": "",
                "lenght": ""
            }
            this.$store.dispatch(SEARCH_SUPERFUND, request)
                .then((response) => {
                    this.listSupperFund = []
                    this.isShowListSupperFund = true
                    this.listSupperFundFull = response.listSuperFund
                    if (response.listSuperFund != undefined) {
                        this.totalPage = response.listSuperFund.length
                    }
                    if (this.listSupperFundFull.length < 10) {
                        this.pageSize = this.listSupperFundFull.length
                    } else {
                        this.pageSize = 10
                    }
                    for (var i = 0; i < this.pageSize; i++) {
                        this.listSupperFund.push(this.listSupperFundFull[i])
                    }
                    this.$root.$refs.AppCyrus.finish()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        handleCurrentChange(page) {
            this.currenPage = page
            this.listSupperFund = []
            var from = (this.currenPage - 1) * this.pageSize + 1
            var to = 0
            if (this.pageSize * this.currenPage <= this.totalPage) {
                to = this.pageSize * this.currenPage
            } else {
                to = this.totalPage
            }
            for (var i = from; i < to; i++) {
                this.listSupperFund.push(this.listSupperFundFull[i])
            }
        },
        detailSupperFund(keyABN) {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_SUPERFUND, keyABN)
                .then((response) => {
                    this.$root.$refs.AppCyrus.finish()
                    this.isShowListSupperFund = false
                    this.isShowDetailSupperFund = true

                    this.newEmployeeInfo.organisationName = response.organisationName
                    this.newEmployeeInfo.identifierValue = response.identifierValue
                    this.newEmployeeInfo.fundName = response.organisationName
                    this.newEmployeeInfo.fundABN = response.identifierValue
                    this.newEmployeeInfo.fundAddress = response.addressLine1
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },
        remoteSupperFund() {
            this.isShowDetailSupperFund = false
            this.newEmployeeInfo.organisationName = ""
            this.newEmployeeInfo.identifierValue = ""
            this.newEmployeeInfo.memberNumber = ""
            this.edtSearchSupperFund = ""
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
