<template>
<modal name="modal-detailemployee" @before-open="beforeOpen" with="auto" height="auto">
    <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog" aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1" uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true" style="display: block; overflow: auto;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" uib-modal-transclude>
                <div class="modal-content text-center onboarding-modal modal-centered ng-scope">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title bold" id="exampleModalLabel" style="text-align:center;">
                                <span style="color:#334152" ng-bind-html="employee.f + ' ' + employee.l" class="ng-binding">
                                    {{employee.firstName + " " + employee.lastName}}
                                </span>
                                <button class="btnedit pointer box-title edit-employe" v-on:click="openModalEdit(employee)">
                                    <img class="iconedit" src="/assets/img/edit.png" />
                                </button>
                            </h5>
                            <button v-on:click="closeModal()" aria-label="Close" class="box-title close" data-dismiss="modal" type="button"></button>
                        </div>
                        <div class="modal-body text-left element-wrapper">
                            <div class="element-box">
                                <h6 class="element-header bold" style="margin-bottom: 1rem;">Leave Balance</h6>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group leave annual">
                                            <div style="padding: 4.5%">
                                                <div>
                                                    <b class="ng-binding bold" style="font-size:25px">{{Math.round((detailLeave.totalAnnualLeave - detailLeave.totalAnnualLeaveUsed) * 100)/100}}</b>
                                                    <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">hours</span>
                                                </div>
                                                <label class="small-label">Annual leave</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group leave sick">
                                            <div style="padding: 4.5%">
                                                <div>
                                                    <b class="ng-binding bold" style="font-size:25px">{{Math.round((detailLeave.totalSickLeave - detailLeave.totalSickLeaveUsed) * 100)/100}}</b>
                                                    <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">hours</span>
                                                </div>
                                                <label class="small-label">Sick leave</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <h6 class="element-header bold" style="margin-bottom: 1rem;">Leave Entitlements</h6>
                                <div class="table-responsive ng-scope">
                                    <div v-show="listScheduledLeaves.length == 0" style="margin-bottom:15px" class="ng-scope">
                                        No leave submitted yet!
                                    </div>
                                    <table v-show="listScheduledLeaves.length > 0" class="table table-padded">
                                        <thead>
                                            <tr>
                                                <th>Status</th>
                                                <th class="text-center">Date</th>
                                                <th class="text-center">Type</th>
                                                <th class="text-right">Leave</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item) in listScheduledLeaves" :key="item.id" class="animatedUp ng-scope">
                                                <td class="nowrap">
                                                    <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                                                    <span v-bind:class="(item.status == 'Reject')?'badge status ng-binding bg-grey': (item.status == 'Pending')? 'badge status ng-binding bg-yellow' : 'badge status ng-binding bg-green'">{{item.status}}</span>
                                                </td>
                                                <td>
                                                    <span class="ng-binding">{{formatDate(item.startDate)}} ~ {{formatDate(item.endDate)}}</span>
                                                </td>
                                                <td class="text-center">
                                                    <div v-bind:class="(item.type == 'Sick')?'badge ng-binding badge-sick':'badge ng-binding badge-annual'">{{item.type}}</div>
                                                </td>
                                                <td class="text-right bolder nowrap">
                                                    <span class="ng-binding">{{item.totalHours}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <br />
                                <h6 class="element-header bold" style="margin-bottom: 1rem;">Payslip History</h6>
                                <div v-show="listPayslips.length == 0" style="margin-bottom:15px" class="ng-scope">
                                    No payslip yet!
                                </div>
                                <table v-show="listPayslips.length > 0" class="table table-padded ng-scope" ng-if="payslips &amp;&amp; payslips.length > 0">
                                    <thead>
                                        <tr>
                                            <th class="text-left">Payslip</th>
                                            <th class="text-right">Earnings</th>
                                            <th class="text-right">Tax</th>
                                            <th class="text-right">Super</th>
                                            <th class="text-right">Net pay</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(item) in listPayslips" :key="item.id">
                                        <tr ng-repeat="payslip in payslips" class="ng-scope" style="cursor: pointer;" @click="getPayslipEmployee(item.id)">
                                            <td class="text-left ng-binding">{{formatDate(item.startPayPeriod)}} ~ {{formatDate(item.endPayPeriod)}}</td>
                                            <td class="text-right ng-binding">${{item.earnings}}</td>
                                            <td class="text-right ng-binding">${{item.tax}}</td>
                                            <td class="text-right ng-binding">${{item.super}}</td>
                                            <td class="text-right ng-binding">${{item.netPay}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-light" data-dismiss="modal" type="button" v-on:click="closeModal()">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</modal>
</template>

<script>
import {
    DETAIL_LEAVE,
} from "@/store/actionsType";
import * as Utils from "@/utils/Utils.js";
import ApiManager from "@/api/ApiManager";
export default {
    name: "DetailEmployeeModal",
    props: [],
    components: {},
    data() {
        return {
            employee: "",
            detailLeave: "",
            listScheduledLeaves: [],
            listPayslips: []
        };
    },
    methods: {
        closeModal() {
            this.$modal.hide("modal-detailemployee");
        },
        beforeOpen(event) {
            this.employee = event.params.employee;
            this.getDetailLeave()
        },
        openModalEdit(employee) {
            this.closeModal()
            this.$modal.show('modal-editemployee', {
                employee: employee
            });
        },
        closeModalEdit() {
            this.$modal.hide('modal-editemployee');
        },
        formatDate(inputDate) {
            var m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            var date = new Date(inputDate);
            var day = date.getDate();
            var month = date.getMonth();
            var year = date.getFullYear();
            var dateShow = day + "-" + m_names[month] + "-" + year;
            return dateShow
        },
        //Request API
        getDetailLeave() {
            this.$root.$refs.AppCyrus.start()
            this.$store.dispatch(DETAIL_LEAVE, this.employee.id)
                .then((response) => {
                    this.detailLeave = response
                    this.listScheduledLeaves = response.scheduledLeaves
                    this.listPayslips = response.payslips
                    this.$root.$refs.AppCyrus.finish()
                }).catch(() => {
                    this.$root.$refs.AppCyrus.fail()
                });
        },

        getPayslipEmployee(payrollDetailId) {
            var link = ApiManager.getUrlApi() + "/api/PaySlip/PayslipForEmployee?payrollDetailId=" + payrollDetailId;
            if (Utils.isLoadLinkPaySlip()) {
                this.viewPaySlipPdf(link);
            } else {
                this.viewPaySlipData(link);
            }
        },

        viewPaySlipPdf(link) {
            this.$modal.show('modal-viewPaySlip-pdf', {
                link: link
            });
        },

        viewPaySlipData(dataPdf, titleName) {
            this.$modal.hide('modal-payrundetails')
            this.$modal.show('modal-viewPaySlip-data', {
                link: dataPdf,
                titleName: titleName
            });
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.red {
    color: rgb(182, 43, 43);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.yellow {
    color: yellow;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.bgred {
    color: white;
    font-size: 14px;
    line-height: 19px;
    background: rgb(182, 43, 43);
}

@import '/assets/css/employees.css';
</style>
