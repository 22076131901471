<template>
    <div class="notification-window-wrapper" v-if="isVisible" v-click-outside="closeMe">
        <div class="triangle"></div>
        <div class="notification-window panel panel-primary">
            <!-- show notifications here -->
            <div style="max-height: 400px;overflow-y: auto;">
                <div v-for="(notification, index) in notifications" :key="index" 
                    :class="[getNotificationClass(index), notification.pathAndQuery ? 'cursor-pointer' : '']"
                    @click="openPage(notification)">
                    <div class="panel-heading">
                        <h4 class="panel-title">{{ notification.title }}</h4>
                        <span class="notification-date">{{ moment.utc(notification.createdAtUtc).local().format('DD/MM/YYYY H:mm') }}</span>
                    </div>
                    <div class="panel-body">
                        {{ notification.message }}
                    </div>
                </div>
                <i class="fa-regular fa-spinner fa-spin" style="font-size: 30px; padding: 10px; text-align: center; width:100%;" 
                    v-if="!notifications || notifications.length === 0"></i>
            </div>

        </div>
    </div>
</template>

<script>
import * as Logs from "@/utils/Logs";
import moment from "moment";

export default {
    name: 'NotificationWindow',
    data() {
        return {
            moment: moment,
            isVisible: false,
            notifications: [],
        };
    },
    methods: {
        toggleVisibility(notifications) {
            this.notifications = notifications;
            if (!this.notifications || this.notifications.length == 0) {
                this.notifications = [
                    { id: 0, title: 'No Notifications', message: 'There are no notifications to show' }
                ];
            }
            
            this.isVisible = !this.isVisible;
        },
        openPage(notification) {
            Logs.logDebug('notification', notification);
            if (notification?.pathAndQuery) {
                if (!notification.pathAndQuery.startsWith(this.$route.path)) {
                    this.$router.push(notification.pathAndQuery);
                }
                
                this.isVisible = false;
            }
        },
        closeMe() {
            this.isVisible = false;
        },
        getNotificationClass(index) {
            return index % 2 === 0 ? 'even-notification' : 'odd-notification';
        }
    },
};
</script>

<style scoped>
.notification-window-wrapper {
    position: relative;
}

.notification-window {
    position: absolute;
    top: 59px;
    /* Adjust as needed */
    right: 20px;
    /* Adjust as needed */
    width: 400px;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    z-index: 1000;
    overflow: hidden;
}

.triangle {
    position: absolute;
    top: 50px;
    /* Adjust as needed */
    right: 180px;
    /* Adjust as needed */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    z-index: 1001;
}

.separator-line {
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 1px solid #ccc;
}

.even-notification {
    background-color: #f9f9f9;
}

.odd-notification {
    background-color: #f2f2f2;
}

.panel-heading,
.panel-body {
    padding-left: 15px;
    padding-right: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.notification-date {
    position: absolute;
    right: 10px;
    top: 0;
}

.panel-heading {
    position: relative;
}
</style>