<template>
  <body class="menu-position-side full-screen with-content-panel body-wrapper">
    <!-- START - modal -->
    <ViewPaySlipModalData />
    <ViewPaySlipModalPdf />
    <MessageSTP />
    <NewEmployeeStep0Modal />
    <NewEmployeeStep1Modal />
    <NewEmployeeStep2Modal />
    <NewEmployeeStep3Modal />
    <NewEmployeeStep4Modal />
    <TerminateEmployeeModal />
    <TaxInformationModal />
    <DetailEmployeeModal />
    <EditEmployeeModal />
    <CreateRequestModal />
    <Notifications ref="notificationsWindow" />
    <!-- END - modal -->

    <div class="ng-scope">
      <div
        v-bind:class="
          isShowRightSidebar
            ? 'with-side-panel solid-bg-all ng-scope content-panel-active'
            : ' with-side-panel solid-bg-all ng-scope sideBarOn'
        "
      >
        <div class="notification-container" v-if="isNotificationAddCard">
          <div class="notification danger">
            <span class="message">
              <p>
                Your trial will expire in {{ customer.trialDays }} days. To file
                your payruns you need to add your card details
                <a class="invoicesLink" href="/settings/subscription"
                  >Add Card</a
                >
              </p>
            </span>
          </div>
        </div>

        <!-- START - Top Menu -->
        <v-toolbar
          class="menu-w"
          style="
            z-index: 3;
            position: relative;
            background-color: #09b585 !important;
            color: white;
            height: auto !important;
            padding: 0px !important;
          "
        >
          <v-btn
            v-if="this.isAccountant || this.isEmployer"
            @click="showMoreMenu()"
            text
            large
            style="
              color: white !important;
              font-size: 16px !important;
              text-transform: none;
              padding-left: 0px;
            "
          >
            <div class="row" style="margin: 0px 5px 0px 0px">
              <div class="top-settings" style="margin: 0px 10px 0px 5px">
                <vue-letter-avatar
                  v-if="currenCompanyName"
                  :name="currenCompanyName"
                  size="32"
                  :rounded="true"
                />
              </div>
              <div>
                <div class="company-title">
                  {{ Session.get("currenCompanyName") }}
                </div>
                <div class="company-discription">
                  {{ Session.get("companyABN") }}
                </div>
              </div>
            </div>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>

          <div
            v-else
            style="
              color: white !important;
              font-size: 16px !important;
              text-transform: none;
              padding-left: 0px;
              margin: 0px 10px;
            "
          >
            <div class="row" style="margin: 0px 5px 0px 0px">
              <div class="top-settings" style="margin: 0px 10px 0px 5px">
                <vue-letter-avatar
                  v-if="currenCompanyName"
                  :name="currenCompanyName"
                  size="32"
                  :rounded="true"
                />
              </div>
              <div>
                <div
                  class="company-title"
                  style="color: rgb(255, 218, 106); font-weight: bold"
                >
                  {{ Session.get("currenCompanyName") }}
                </div>
                <div
                  class="company-discription"
                  style="color: rgb(255, 218, 106)"
                >
                  {{ Session.get("companyABN") }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="!this.isEmployee" class="topnav">
            <a
              v-for="item in listMenu"
              :key="item.id"
              v-bind:class="idItemMenu == item.id ? 'active' : ''"
              @click="clickItemLeftMenu(item.id)"
              >{{ item.name }}</a
            >
          </div>
          <div v-else class="topnav">
            <a
              v-for="item in EmployeelistMenu"
              :key="item.id"
              v-bind:class="idItemMenu == item.id ? 'active' : ''"
              @click="clickItemLeftMenu(item.id)"
              >{{ item.name }}</a
            >
          </div>
          <v-spacer></v-spacer>

          
          <v-btn v-show="!this.isEmployee" 
            class="text-none" 
            icon
            stacked
            @click="toggleNotificationWindow">
            <!-- red badge -->
            <v-badge :color="notificationBadgeColor" :content="notificationBadgeUnreadCount">
              <v-icon style="color:white">mdi-bell</v-icon>
            </v-badge>
          </v-btn>
            

          <v-btn v-show="!this.isEmployee" class="feedback icon-menu box-title" icon @click="feedbackApp()">
            <v-icon style="color: white">mdi-flash</v-icon>
          </v-btn>

          <v-btn v-show="!this.isEmployee" class="help icon-menu box-title" icon @click="collapseRightMenu()">
            <v-icon style="color: white">mdi-help-circle</v-icon>
          </v-btn>

          <div
            class="top-bar color-scheme-transparent"
            style="padding-right: 0px; border-bottom: none"
          >
            <div class="top-menu-controls">
              <div
                v-on:click="clickAvartar"
                v-on:mouseover="onFocusMenu"
                v-on:mouseout="onBlurMenu"
                class="
                  top-icon top-settings
                  os-dropdown-trigger os-dropdown-position-left
                "
              >
                <img class="avartar" src="/assets/img/avatar.png" />
                <div class="os-dropdown menu" style="z-index: 999 !important">
                  <ul>
                    <li>
                      <a v-on:click="gotoProfile()">
                        <span class="txtHappy mr-0">{{ userName }}!</span>
                      </a>
                      <a v-on:click="gotoProfile()">
                        <i class="icon-feather-user"></i>
                        <span>Profile</span>
                      </a>
                    </li>
                    <li>
                      <a v-on:click="logout()">
                        <i class="icon-feather-log-out"></i>
                        <span>Log out</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </v-toolbar>
        <!-- END - Top Menu -->

        <!-- Show company -->
        <ViewMoreMenu
          :show="isShowMoreMenu"
          :listCompany="listCompany"
          :isFavories="isFavories"
          :searching="searching"
        />
        <div class="layout-w menu">
          <!-- Start Mobile Menu -->
          <div class="menu-mobile menu-activated-on-click color-scheme-dark">
            <div class="mm-logo-buttons-w">
              
              <!-- logo at top left hand side -->
              <a class="mm-logo" href="/dashboard" v-if="!isEmployee">
                <img src="https://test-api.cyruspay.com.au/img/logo_256.png"/>
              </a>
              <a class="mm-logo" href="#" v-else>
                <img src="https://test-api.cyruspay.com.au/img/logo_256.png"/>
              </a>

              <div class="mm-buttons">
                <v-btn class="notify icon-menu" v-if="!isEmployee" style="margin-top: 7px" icon stacked>
                  <!-- red badge -->
                  <v-badge :color="notificationBadgeColor" :content="notificationBadgeUnreadCount">
                    <v-icon style="color:white">mdi-bell</v-icon>
                  </v-badge>
                </v-btn>

                <v-btn v-if="!isEmployee"
                  class="feedback icon-menu"
                  style="margin-top: 7px"
                  icon
                  @click="feedbackApp()"
                >
                  <v-icon>mdi-flash</v-icon>
                </v-btn>

                <v-btn v-if="!isEmployee"
                  class="help icon-menu"
                  style="margin-top: 7px"
                  icon
                  v-on:click="toggleSideBar($event)"
                >
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>

                <div
                  class="top-bar color-scheme-transparent"
                  style="padding-right: 0px; border-bottom: none"
                >
                  <div class="top-menu-controls">
                    <div
                      v-on:click="clickAvartar"
                      v-on:mouseover="onFocusMenu"
                      v-on:mouseout="onBlurMenu"
                      class="
                        top-icon top-settings
                        os-dropdown-trigger os-dropdown-position-left
                      "
                    >
                      <img class="avartar" src="/assets/img/avatar.png" />
                      <div class="os-dropdown menu" style="">
                        <ul>
                          <li>
                            <a v-on:click="gotoProfile()">
                              <span class="txtHappy mr-0">{{ userName }}!</span>
                            </a>
                            <a v-on:click="gotoProfile()">
                              <i class="icon-feather-user"></i>
                              <span>Profile</span>
                            </a>
                          </li>
                          <li>
                            <a v-on:click="logout()">
                              <i class="icon-feather-log-out"></i>
                              <span>Log out</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="mobile-menu-trigger">
                  <div
                    v-on:click="toggleMenu2($event)"
                    class="os-icon os-icon-hamburger-menu-1"
                  ></div>
                </div>
              </div>
            </div>
            <!-- List menu for (max-width: 1024px) and (min-width: 768px) -->
            <div class="menu-and-user">
              <ul
                v-if="!this.isEmployee"
                class="main-menu"
                v-on:click="toggleMenu($event)"
              >
                <li v-for="item in listMenu" :key="item.id">
                  <div
                    v-if="idItemMenu == item.id"
                    class="itemMobileClicked"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div>
                        <img class="iConItemMobile" :src="item.iconActi" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="idItemMenu != item.id"
                    class="itemMobileNotClick"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div>
                        <img class="iConItemMobile" :src="item.iconNotActi" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-else class="main-menu" v-on:click="toggleMenu($event)">
                <li v-for="item in EmployeelistMenu" :key="item.id">
                  <div
                    v-if="idItemMenu == item.id"
                    class="itemMobileClicked"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div>
                        <img class="iConItemMobile" :src="item.iconActi" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="idItemMenu != item.id"
                    class="itemMobileNotClick"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div>
                        <img class="iConItemMobile" :src="item.iconNotActi" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!-- List menu for (max-width: 767px) -->
            <div class="menu-and-user2">
              <ul
                v-if="!this.isEmployee"
                class="main-menu"
                v-on:click="toggleMenu2($event)"
              >
                <li v-for="item in listMenu" :key="item.id">
                  <div
                    v-if="idItemMenu == item.id"
                    class="itemMobileClicked"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div class="text-left" style="display: flex">
                        <img class="iConItemMobile" :src="item.iconActi" />
                        <span class="text-center my-auto txtItemMobile">{{
                          item.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="idItemMenu != item.id"
                    class="itemMobileNotClick"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div class="text-left" style="display: flex">
                        <img class="iConItemMobile" :src="item.iconNotActi" />
                        <span
                          class="text-center my-auto txtItemMobileNotClick"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ul v-else class="main-menu" v-on:click="toggleMenu2($event)">
                <li v-for="item in EmployeelistMenu" :key="item.id">
                  <div
                    v-if="idItemMenu == item.id"
                    class="itemMobileClicked"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div class="text-left" style="display: flex">
                        <img class="iConItemMobile" :src="item.iconActi" />
                        <span class="text-center my-auto txtItemMobile">{{
                          item.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="idItemMenu != item.id"
                    class="itemMobileNotClick"
                    @click="clickItemLeftMenu(item.id)"
                  >
                    <div class="icon-w">
                      <div class="text-left" style="display: flex">
                        <img class="iConItemMobile" :src="item.iconNotActi" />
                        <span
                          class="text-center my-auto txtItemMobileNotClick"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- End Mobile Menu  -->

          <div class="content-w" style="background-color: #cccccc">
            <!-- Content -->
            <div
              v-if="SubscriptionStatus == 'HOLD'"
              class="CustomMessage40 row"
            >
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                <div class="notification-container">
                  <div class="notification danger">
                    <span class="message">
                      <p>
                        You have added employee more than allowed in
                        subscription. Your account is on hold, Upgrade
                        subscription with more employees. Your access will be
                        revoked from next month.<a
                          class="invoicesLink"
                          href="javascript:void(0)"
                          @click="redirectToSubscription()"
                          >read more</a
                        >
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-sm-1"></div>
            </div>
             
            <v-app class="leftImageContainer" style="background: transparent;">
              <figure class="dashboardImage" >
                      <img class="w-100" src="../../../assets/images/Man-Lantern-Dog.png" alt="DashboardImage">
              </figure>
              <div class="mainContent ">
                <router-view></router-view>
              </div>
            </v-app>
          </div>

          <!-- sidebar: Right menu -->
          <div class="collapse-rightMenu" v-show="isShowRightMenu" style="">
            <div
              v-bind:class="
                isShowRightSidebar ? 'rightMenuMobile' : 'rightMenu'
              "
            >
              <div
                v-show="this.$global.path == '/dashboard'"
                class="content-panel compact ng-scope"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <div>
                    <div class="title" style="height: 40px; width: 260px">
                      <h4 style="height: 40px; margin-top: 10px">Employees</h4>
                      <a
                        @click="
                          listPolicy.addEmployee
                            ? openModalStep0()
                            : gotoPayment()
                        "
                        class="ml-2 mb-1 btn ng-binding ng-scope btn-primary"
                        style="margin-top: -10px"
                      >
                        New Employee
                      </a>
                    </div>
                    <div
                      v-for="item in listEmployees"
                      :key="item.id"
                      class="dashboard_item_people os-dropdown-trigger"
                    >
                      <div
                        v-on:click="openModalEdit(item)"
                        class="btnedit pointer box-title edit-employe"
                        style="display: flex"
                      >
                        <img class="iconedit" src="/assets/img/edit.png" />
                      </div>
                      <div>
                        <div @click="openModalDetail(item)" class="body">
                          <img
                            v-if="item.avatar != ''"
                            class="imgavatar"
                            :src="renderAvatar(item.avatar)"
                          />
                          <avatar
                            v-else
                            size="50"
                            :username="utils.getUserName(item)"
                            style="margin-right: 10px"
                          ></avatar>
                          <div class="extra-info">
                            <div
                              v-bind:class="
                                item.active == 'ACTIVE'
                                  ? 'green'
                                  : item.active == 'PENDING'
                                  ? 'yellow'
                                  : item.active == 'INACTIVE'
                                  ? 'red'
                                  : 'black'
                              "
                              class="status-pill"
                            ></div>
                          </div>
                          <div class="cnt">
                            <div class="title">
                              {{ item.firstName + " " + item.lastName }}
                            </div>
                            <div class="discription">{{ item.job }}</div>
                            <div class="tags">
                              <p class="fulltime">
                                {{ showEmploymentType(item.employmentType) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="line"></div>
                    </div>
                  </div>
                  <div>
                    <h4 style="height: 40px; margin-top: 10px">Timesheet</h4>
                    <div class="bgAnnualLeave">
                      <img
                        src="/assets/img/img_annual_leave.png"
                        class="imgAnnualLeave"
                      />
                      <div class="contentAnnualLeave">
                        <h5 class="txtTitleAnual">Annual leave</h5>
                        <p class="txtContentAnual" style="padding-right: 70px">
                          Take vacations, go to as many places as you can, you
                          can always make money, you can't always make memories.
                        </p>
                        <button
                          v-on:click="
                            listPolicy.addLeave
                              ? openModalCreateRequest('Annual')
                              : gotoPayment()
                          "
                          class="btn btnCreateAnnual btn-primary bold"
                        >
                          Submit Leave
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="bgSick">
                    <img src="/assets/img/img_sick.png" class="imgSick" />
                    <div class="contentSick">
                      <h5 class="txtTitleSick">Sick/Carer's leave</h5>
                      <p class="txtContentSick">
                        Track your sick leave here. We support our employees
                        when they are not well!.
                      </p>
                      <button
                        v-on:click="
                          listPolicy.addLeave
                            ? openModalCreateRequest('Sick')
                            : gotoPayment()
                        "
                        class="btn btnCreate bold"
                      >
                        Submit Leave
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="this.$global.path == '/employees'"
                class="content-panel compact ng-scope"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <div class="element-wrapper">
                    <h6 class="" style="margin-top: 30px">Documents</h6>
                    <div class="element-box" style="margin-top: 15px">
                      <div class="users-list-w">
                        <div class="with-status status-red w-100 d-inline-flex">
                          <p>
                            <a
                              href="https://www.ato.gov.au/uploadedFiles/Content/IND/Downloads/TFN_declaration_form_N3092.pdf"
                              target="_blank"
                              >TFN Declaration</a
                            >
                          </p>
                        </div>
                        <div class="with-status status-red w-100 d-inline-flex">
                          <p>
                            <a
                              href="https://www.ato.gov.au/assets/0/104/2244/2335/3c4347e5-f117-48af-9349-43e9f72ea811.pdf"
                              target="_blank"
                              >Superannuation Form</a
                            >
                          </p>
                        </div>
                        <div class="with-status status-red w-100 d-inline-flex">
                          <p>
                            <a
                              href="https://www.ato.gov.au/uploadedFiles/Content/MEI/downloads/3447-02.2014_PAYG%20payment%20summary%20statement.pdf"
                              target="_blank"
                              >Fair Work Info Statement</a
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="
                  this.$global.path == '/payrolls' ||
                  this.$global.path == '/runpayroll'
                "
                class="content-panel compact ng-scope"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <div class="title" style="height: 40px; width: 260px">
                    <h4 style="height: 40px; margin-top: 10px">Payroll</h4>
                  </div>
                </div>
              </div>

              <div
                v-show="this.$global.path == '/leave'"
                class="content-panel compact ng-scope"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <div class="bgAnnualLeave">
                    <img
                      src="/assets/img/img_annual_leave.png"
                      class="imgAnnualLeave"
                    />
                    <div class="contentAnnualLeave">
                      <h5 class="txtTitleAnual">Annual leave</h5>
                      <p class="txtContentAnual" style="padding-right: 70px">
                        Take vacations, go as many places as you can, you can
                        always make money, you can't awakes make memories.
                      </p>
                      <button
                        v-on:click="
                          listPolicy.addLeave
                            ? openModalCreateRequest('Annual')
                            : gotoPayment()
                        "
                        class="btn btnCreateAnnual btn-primary bold"
                      >
                        Submit Leave
                      </button>
                    </div>
                  </div>
                  <div class="bgSick" style="margin-top: 50px">
                    <img src="/assets/img/img_sick.png" class="imgSick" />
                    <div class="contentSick">
                      <h5 class="txtTitleSick">Sick/Carer's leave</h5>
                      <p class="txtContentSick">
                        Track your sick leave here. We support our employees
                        when they are not well!.
                      </p>
                      <button
                        class="btn btnCreate"
                        v-on:click="
                          listPolicy.addLeave
                            ? openModalCreateRequest('Sick')
                            : gotoPayment()
                        "
                      >
                        Submit Leave
                      </button>
                    </div>
                  </div>
                  <h6 class="" style="margin-top: 30px">Documents</h6>
                  <div class="element-box" style="margin-top: 15px">
                    <div class="users-list-w">
                      <div class="with-status status-red w-100 d-inline-flex">
                        <p>
                          <a
                            href="https://www.fairwork.gov.au/how-we-will-help/templates-and-guides/fact-sheets/minimum-workplace-entitlements/annual-leave"
                            target="_blank"
                            >Annual Leave</a
                          >
                        </p>
                      </div>
                      <div class="with-status status-red w-100 d-inline-flex">
                        <p>
                          <a
                            href="https://www.fairwork.gov.au/leave/sick-and-carers-leave"
                            target="_blank"
                            >Sick Leave</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="this.$global.path == '/reports'"
                class="content-panel compact ng-scope"
                style="align-content: center"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <div class="element-wrapper">
                    <h6 class="">Portion Graph</h6>
                    <div class="element-box">
                      <div
                        class="w-100"
                        v-if="
                          this.$global.path == '/reports' && isShowRightMenu
                        "
                      >
                        <DoughnutChart
                          v-bind:chartData="chartData"
                          :width="200"
                          :height="200"
                        />
                      </div>
                      <div class="w-100" v-else></div>
                    </div>
                    <h6 class="">Report Files</h6>
                    <div class="element-box">
                      <div class="users-list-w">
                        <div
                          @click="
                            listPolicy.reportEmployeeContacts
                              ? getEmployeeContact()
                              : gotoPayment()
                          "
                          class="
                            user-w
                            with-status
                            status-red
                            w-100
                            d-inline-flex
                            os-dropdown-trigger
                          "
                        >
                          <div class="user-name">
                            <h6 class="user-title">Employee Contacts</h6>
                          </div>
                        </div>
                        <div
                          @click="
                            listPolicy.reportEmployeeRemuneration
                              ? getEmployeeRemuneration()
                              : gotoPayment()
                          "
                          class="
                            user-w
                            with-status
                            status-red
                            w-100
                            d-inline-flex
                            os-dropdown-trigger
                          "
                        >
                          <div class="user-name">
                            <h6 class="user-title">Employee Remuneration</h6>
                          </div>
                        </div>
                        <div
                          @click="
                            listPolicy.reportLeaveBalances
                              ? openLeaveBalancesModal()
                              : gotoPayment()
                          "
                          class="
                            user-w
                            with-status
                            status-red
                            w-100
                            d-inline-flex
                            os-dropdown-trigger
                          "
                        >
                          <div class="user-name">
                            <h6 class="user-title">Leave Balances</h6>
                          </div>
                        </div>
                        <div
                          @click="
                            listPolicy.reportPayrollActivity
                              ? openPayrollSummary()
                              : gotoPayment()
                          "
                          class="
                            user-w
                            with-status
                            status-green
                            w-100
                            d-inline-flex
                            os-dropdown-trigger
                          "
                        >
                          <div class="user-name">
                            <h6 class="user-title">Payroll Activity Summary</h6>
                          </div>
                        </div>
                        <div
                          @click="
                            listPolicy.reportPayrollEmployee
                              ? openEmployeeSummary()
                              : gotoPayment()
                          "
                          class="
                            user-w
                            with-status
                            status-green
                            w-100
                            d-inline-flex
                            os-dropdown-trigger
                          "
                        >
                          <div class="user-name">
                            <h6 class="user-title">Payroll Employee Summary</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-show="this.$global.path == '/settings'"
                class="content-panel compact ng-scope"
              >
                <div
                  class="content-panel-close"
                  v-on:click="toggleSideBar($event)"
                >
                  <i class="os-icon os-icon-close"></i>
                </div>
                <div class="right-menu">
                  <h6 class="" style="margin-top: 30px">Documents</h6>
                  <div class="element-box" style="margin-top: 15px">
                    <div class="users-list-w">
                      <div class="with-status status-red w-100 d-inline-flex">
                        <p>
                          <a href="/assets/tc.html" target="_blank">Terms And Conditions</a>
                        </p>
                      </div>
                      <div class="with-status status-red w-100 d-inline-flex">
                        <p>
                          <a href="/assets/tc.html#privacy-policy" target="_blank">Privacy Policy</a>
                        </p>
                      </div>
                      
                    </div>
                  </div>
                  <h6 class="" style="margin-top: 30px">Contact us</h6>
                  <div class="element-box" style="margin-top: 15px">
                    <div class="users-list-w">
                      <div class="with-status status-red w-100 d-inline-flex">
                        <a href="tel:+61419560971">0419560971</a>
                      </div>
                      <div class="with-status status-red w-100 d-inline-flex">
                        <p>
                          <a href="mailto:info@cyruspay.com.au" target="_blank"> info@cyruspay.com.au</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import * as external from "@/utils/External.js";
import "vue-lazy-youtube-video/dist/style.css";
import ViewPaySlipModalData from "@/views/home/base/modal/ViewPaySlipModalData";
import ViewPaySlipModalPdf from "@/views/home/base/modal/ViewPaySlipModalPdf";
import MessageSTP from "./modal/MessageSTP";

import NewEmployeeStep0Modal from "@/views/home/employees/modal/NewEmployeeStep0Modal";
import NewEmployeeStep1Modal from "@/views/home/employees/modal/NewEmployeeStep1Modal";
import NewEmployeeStep2Modal from "@/views/home/employees/modal/NewEmployeeStep2Modal";
import NewEmployeeStep3Modal from "@/views/home/employees/modal/NewEmployeeStep3Modal";
import NewEmployeeStep4Modal from "@/views/home/employees/modal/NewEmployeeStep4Modal";
import TerminateEmployeeModal from "@/views/home/employees/modal/TerminateEmployeeModal";
import TaxInformationModal from "@/views/home/employees/modal/TaxInformationModal";
import DetailEmployeeModal from "@/views/home/employees/modal/DetailEmployeeModal";
import EditEmployeeModal from "@/views/home/employees/modal/EditEmployeeModal";
import CreateRequestModal from "@/views/home/timesheet/modal/CreateRequestModal";
import Notifications from '@/components/Notifications.vue';

import ViewMoreMenu from "@/components/ViewMoreMenu";
import DoughnutChart from "@/components/chart/DoughnutChart.js";

import Token from "@/api/Token.js";
import ApiManager from "@/api/ApiManager";

import * as Utils from "@/utils/Utils.js";
import * as Logs from "@/utils/Logs";
import * as Session from "@/utils/SessionStore";

import Avatar from "vue-avatar";

import {
  GET_EMPLOYEE,
  GET_LIST_COMPANY,
  DETAIL_COMPANY,
  UPDATE_COMPANY,
  LOGOUT,
  GET_INFOR_SUMMARY,
  GET_STP_STATUS,
  GET_STP_RESULT,
  GETLIST_RUNPAYROLL,
  REMOVE_DEVICE,
  USER_INFO,
} from "@/store/actionsType";

import PaymentAction from "@/store/actionTypes/paymentActionType.js";

import { mapGetters } from "vuex";

export default {
  name: "BaseLayout",
  components: {
    ViewPaySlipModalData,
    ViewPaySlipModalPdf,
    MessageSTP,
    NewEmployeeStep0Modal,
    NewEmployeeStep1Modal,
    NewEmployeeStep2Modal,
    NewEmployeeStep3Modal,
    NewEmployeeStep4Modal,
    TerminateEmployeeModal,
    TaxInformationModal,
    DetailEmployeeModal,
    EditEmployeeModal,
    CreateRequestModal,
    Notifications,
    DoughnutChart,
    ViewMoreMenu,
    Avatar,
  },
  mounted() {
    external.head_link("/assets/css/style.css");
    external.head_link("/assets/css/angular-confirm.min.css");
    external.head_script("/assets/js/angular-config.min.js");
    external.head_script("/assets/js/widgets.js");

    window.$('.leftImageContainer div').first().addClass('v-application--wrap-Base');
  },
  destroyed() {
    external.del_link("/assets/css/style.css");
    external.del_link("/assets/scss/angular-confirm.min.css");
    external.del_script("/assets/js/angular-config.min.js");
    external.del_script("/assets/js/widgets.js");
  },
  data() {
    return {
      SubscriptionExpiredMode: Session.get("SubscriptionExpiredMode"),
      SubscriptionStatus: "",
      UserRole: "",

      notifications: [],
      notificationBadgeUnreadCount: 0,
      notificationBadgeColor: "",

      listPolicy: {
        addEmployee: true,
        updateEmployee: true,
        deleteEmployee: true,

        addPayroll: true,
        updatePayroll: true,
        deletePayroll: true,
        submitPayroll: true,

        addLeave: true,
        updateLeave: true,
        deleteLeave: true,

        reportEmployeeContacts: true,
        reportEmployeeRemuneration: true,
        reportLeaveBalances: true,
        reportPayrollActivity: true,
        reportPayrollEmployee: true,
      },
      Session: Session,
      utils: Utils,
      isShowMoreMenu: false,
      isClickCompany: false,
      isShowSearch: false,
      isShowSearchMobile: false,
      isShowMenu: true,
      isHoverCollapse: false,
      listEmployees: [],
      listCompany: [],
      listCompanyArchived: [],
      keySearch: "",
      isFavories: false,
      isShowRightSidebar: false,
      isShowRightMenu: false,
      userName: Session.get("Username"),
      currenCompanyName: Session.get("currenCompanyName"),
      idItemMenu: 1,
      isClickProfile: false,
      queue: [],
      listPayRoll: [],
      searching: null,

      /** timeout id for check queue */
      timeOut: null,

      EmployeelistMenu: [
        {
          id: 3,
          name: "Employees",
          iconNotActi: "/assets/img/menu/employees.png",
          iconActi: "/assets/img/menu/employees_acti.png",
        },
        {
          id: 4,
          name: "Leave",
          iconNotActi: "/assets/img/menu/timesheet.png",
          iconActi: "/assets/img/menu/timesheet_acti.png",
        },
      ],
      listMenu: [
        {
          id: 1,
          name: "Dashboard",
          iconNotActi: "/assets/img/menu/dashboard.png",
          iconActi: "/assets/img/menu/dashboard_acti.png",
        },
        {
          id: 3,
          name: "Employees",
          iconNotActi: "/assets/img/menu/employees.png",
          iconActi: "/assets/img/menu/employees_acti.png",
        },
        {
          id: 2,
          name: "Payroll",
          iconNotActi: "/assets/img/menu/runpayroll.png",
          iconActi: "/assets/img/menu/runpayroll_acti.png",
        },
        {
          id: 4,
          name: "Leave",
          iconNotActi: "/assets/img/menu/timesheet.png",
          iconActi: "/assets/img/menu/timesheet_acti.png",
        },
        {
          id: 5,
          name: "Reports",
          iconNotActi: "/assets/img/menu/report.png",
          iconActi: "/assets/img/menu/report_acti.png",
        },
        {
          id: 6,
          name: "Settings",
          iconNotActi: "/assets/img/menu/settings.png",
          iconActi: "/assets/img/menu/settings_acti.png",
        },
      ],
      listPaymentOnlyModeMenu: [
        {
          id: 1,
          name: "SubscriptionPayment",
          iconNotActi: "/assets/img/menu/dashboard.png",
          iconActi: "/assets/img/menu/dashboard_acti.png",
        },
      ],
      chartData: "",
    };
  },

  computed: {
    ...mapGetters({
      customer: "getCustomer",
      card: "getCard",
      isGetInfoCard: "isGetInfoCard",
    }),
    isNotificationAddCard() {
      //    console.log('this.session',Session.get("Username"))
      return (
        this.isGetInfoCard &&
        Object.keys(this.card).length === 0 &&
        !this.customer.isTrial
      );
    },
    /**
     * Returns true if the user is an employee.
     */
    isEmployee() {
      return this.$root.$refs.BaseLayout.UserRole === 'Employee';
    },
    /**
     * Checks if the user role is 'User'.
     * @returns {boolean} True if the user role is 'User', false otherwise.
     */
    isEmployer() {
      return this.$root.$refs.BaseLayout.UserRole === 'User';
      // Session.get('Profile').role === 'User'
    },
    /**
     * Checks if the user role is 'Accountant'.
     * @returns {boolean} True if the user role is 'Accountant', false otherwise.
     */
    isAccountant() {
      return this.$root.$refs.BaseLayout.UserRole === 'Accountant';
    },
  },

  watch: {
    isShowMenu: function () {
      this.isShowSearch = true;
      this.openSearch();
    },
  },

  created() {
    this.CheckUserRole();
    this.$root.$refs.BaseLayout = this;
    var currentPage = this.$global.path;

    this.getListCompany();
    this.AfterEmployeeCheck();
    switch (currentPage) {
      case "/dashboard":
        this.idItemMenu = 1;
        break;
      case "/payrolls":
      case "/runpayroll":
        this.idItemMenu = 2;
        break;
      case "/employees":
        this.idItemMenu = 3;
        break;
      case "/leave":
        this.idItemMenu = 4;
        break;
      case "/reports":
        this.idItemMenu = 5;
        this.getInforSummary();
        break;
      case "/settings":
        this.idItemMenu = 6;
        break;
      default:
        this.idItemMenu = 0;
    }

    this.getNotifications();
    
  },

  methods: {
    async toggleNotificationWindow() {
      this.$refs.notificationsWindow.toggleVisibility(this.notifications);

      if (this.$refs.notificationsWindow.isVisible) {
        const lastNotificationId = this.notifications[0].id;
        const isRead = this.notifications[0].isRead;
        
        if (lastNotificationId > 0 && !isRead) {
          // indicate that the user has read all notifications up to this notificationId
          this.notifications = await ApiManager.get(`/api/notification/ReadTo/${lastNotificationId}`);
          this.updateNotificationBadge();
          Logs.logDebug("read to", this.notifications);
        }
      }
    },

    async getNotifications() {
      try {
        this.notifications = await ApiManager.get("/api/notification");
        this.updateNotificationBadge();
        Logs.logDebug("getNotifications complete", this.notifications);
      }
      catch (error) {
        Logs.logError("getNotifications", error);
      }
      finally {
        setTimeout(this.getNotifications, 60000);
      }
      
    },

    updateNotificationBadge() {
      this.notificationBadgeUnreadCount = this.notifications.filter((n) => !n.isRead).length;
      this.notificationBadgeColor = this.notificationBadgeUnreadCount > 0 ? "error" : "";
    },

    CheckUserRole() {
      //   console.log("created", Session.get("Profile"));
      if (Session.get("Profile") != null) {
        var sessionProfile = Session.get("Profile");
        this.UserRole = sessionProfile.role;
      }
      if (Session.has("SubscriptionExpiredMode")) {
        if (this.SubscriptionExpiredMode) {
          this.$router.push({
            name: "SubscriptionPayment",
            params: {},
          });
        }
      }
    },
    checkPolicy(policy) {
      if (policy != undefined) {
        //console.log(policy);
        if (policy.indexOf("POLICY_EMPLOYEE_ADD") == -1) {
          this.listPolicy.addEmployee = false;
        } else {
          this.listPolicy.addEmployee = true;
        }
        if (policy.indexOf("POLICY_EMPLOYEE_UPDATE") == -1) {
          this.listPolicy.updateEmployee = false;
        } else {
          this.listPolicy.updateEmployee = true;
        }
        if (policy.indexOf("POLICY_EMPLOYEE_DELETE") == -1) {
          this.listPolicy.deleteEmployee = false;
        } else {
          this.listPolicy.deleteEmployee = true;
        }

        if (policy.indexOf("POLICY_PAYROLL_ADD") == -1) {
          this.listPolicy.addPayroll = false;
        } else {
          this.listPolicy.addPayroll = true;
        }
        if (policy.indexOf("POLICY_PAYROLL_UPDATE") == -1) {
          this.listPolicy.updatePayroll = false;
        } else {
          this.listPolicy.updatePayroll = true;
        }
        if (policy.indexOf("POLICY_PAYROLL_DELETE") == -1) {
          this.listPolicy.deletePayroll = false;
        } else {
          this.listPolicy.deletePayroll = true;
        }
        if (policy.indexOf("POLICY_PAYROLL_SUBMIT") == -1) {
          this.listPolicy.submitPayroll = false;
        } else {
          this.listPolicy.submitPayroll = true;
        }

        if (policy.indexOf("POLICY_LEAVE_ADD") == -1) {
          this.listPolicy.addLeave = false;
        } else {
          this.listPolicy.addLeave = true;
        }
        if (policy.indexOf("POLICY_LEAVE_UPDATE") == -1) {
          this.listPolicy.updateLeave = false;
        } else {
          this.listPolicy.updateLeave = true;
        }
        if (policy.indexOf("POLICY_LEAVE_DELETE") == -1) {
          this.listPolicy.deleteLeave = false;
        } else {
          this.listPolicy.deleteLeave = true;
        }

        if (policy.indexOf("POLICY_REPORT_EMPLOYEE_CONTACTS") == -1) {
          this.listPolicy.reportEmployeeContacts = false;
        } else {
          this.listPolicy.reportEmployeeContacts = true;
        }
        if (policy.indexOf("POLICY_REPORT_EMPLOYEE_REMUNERATION") == -1) {
          this.listPolicy.reportEmployeeRemuneration = false;
        } else {
          this.listPolicy.reportEmployeeRemuneration = true;
        }
        if (policy.indexOf("POLICY_REPORT_LEAVE_BALANCES") == -1) {
          this.listPolicy.reportLeaveBalances = false;
        } else {
          this.listPolicy.reportLeaveBalances = true;
        }
        if (policy.indexOf("POLICY_REPORT_PAYROLL_ACTIVITY_SUMMARY") == -1) {
          this.listPolicy.reportPayrollActivity = false;
        } else {
          this.listPolicy.reportPayrollActivity = true;
        }
        if (policy.indexOf("POLICY_REPORT_PAYROLL_EMPLOYEE_SUMMARY") == -1) {
          this.listPolicy.reportPayrollEmployee = false;
        } else {
          this.listPolicy.reportPayrollEmployee = true;
        }
        this.copyListPolicy();
      }
    },
    copyListPolicy() {
      if (this.$global.path == "/employees") {
        this.$root.$refs.People.listPolicy = this.listPolicy;
        if (this.$root.$refs.NewEditEmployee != undefined) {
          this.$root.$refs.NewEditEmployee.listPolicy = this.listPolicy;
        }
      }
      if (this.$global.path == "/leave") {
        this.$root.$refs.Leave.listPolicy = this.listPolicy;
      }
      if (this.$global.path == "/dashboard") {
        this.$root.$refs.Dashboard.listPolicy = this.listPolicy;
      }
      if (this.$global.path == "/reports") {
        this.$root.$refs.Reports.listPolicy = this.listPolicy;
      }
      if (this.$global.path == "/runpayroll") {
        this.$root.$refs.RunPayroll.listPolicy = this.listPolicy;
      }

      if (this.$global.path == "/settings") {
        this.$root.$refs.Setting.listPolicy = this.listPolicy;
      }
    },
    gotoPayment() {
      this.hideRight();
      Utils.toastError("This function has been closed! You need to pay for the service to open the functions.");
      
      this.idItemMenu = 6;
      this.$router.push({
        name: "settings",
        params: {
          tab: "Subscription",
        },
      });
    },

    redirectToSubscription() {
      // this.$router.push({
      //   name: "settings",
      //   params: {
      //     tab: "Subscription",
      //   },
      // });
      this.$router.push({
        name: "settings",
        params: {
          tab: "Subscription",
        },
      });
    },
    deleteSTPInQueue(id) {
      if (this.queue != undefined && this.queue.length > 0) {
        for (var i = 0; i < this.queue.length; i++) {
          if (this.queue[i].id == id) {
            this.queue.splice(i, 1);
          }
        }
      }
    },
    /**
     * Checks the queue and performs actions based on the queue items.
     * If the queue is not empty, it checks if the first item in the queue is ready to be processed.
     * If it is ready, it removes the item from the queue and calls the 'getSTPStatus' method with the item's ID.
     * If it is not ready, it sorts the queue based on the item's next time and sets a timeout to check the queue again later.
     * If there is already a timeout set, it does not set a new one.
     */
    checkQueue() {
      if (this.queue != undefined && this.queue.length > 0) {
        var stp = this.queue.shift();
        var now = new Date();
        if (now >= stp.nextTime) {
          this.timeOut = null;
          this.getSTPStatus(stp.id, true);
        } else {
          this.sortQueue(stp.id, stp.nextTime);
          if (this.timeOut == null) {
            Logs.string("set time out", stp.nextTime - now.getTime());
            var time = stp.nextTime - now.getTime();
            this.timeOut = setTimeout(() => {
              this.checkQueue();
            }, time);
          } else {
            Logs.stringKey("not set time out");
          }
        }
      }
    },

    getSTPStatus(id, timeout) {
      this.$store
        .dispatch(GET_STP_STATUS, id)
        .then((response) => {
          if (
            response.data.messageStatus == "RESPONDED" ||
            response.data.messageStatus == "RESPONDED_SUCCESS" ||
            response.data.messageStatus == "RESPONDED_ERROR"
          ) {
            this.$store.dispatch(GET_STP_RESULT, id).then((response) => {
              // Logs.json('result', response)
              this.$modal.show("modal-messageSTP", {
                data: response.data.tnS_Event.eventItems.eventItem,
              });
              if (this.$global.path == "/payrolls") {
                this.$root.$refs.RunOverview.reloadDataRunOverView(true);
              }
              this.checkQueue();
              this.$modal.hide("modal-payrundetails");
            });
          } else if (
            response.data.messageStatus == "PARTIAL" ||
            response.data.messageStatus == "QUEUED" ||
            response.data.messageStatus == "PUSHED"
          ) {
            var now = new Date();
            if (!timeout) {
              Utils.toastError("ATO Processing!");
            }
            now.setMinutes(now.getMinutes() + 5);
            this.sortQueue(id, now.getTime());
            this.checkQueue();
            this.$modal.hide("modal-payrundetails");
          } else {
            Utils.toastError(response.data.messageStatusDetail);
            this.$modal.hide("modal-payrundetails");
          }
        })
        .catch((error) => {
          this.$modal.show("modal-messageSTP", {
            data: error,
          });
          this.checkQueue();
          this.$modal.hide("modal-payrundetails");
        });
    },

    sortQueue(id, time) {
      Logs.stringKey("sort Queue");
      for (let i = 0; i < this.queue.length; i++) {
        if (this.queue[i].nextTime > time) {
          Logs.stringKey("push location: " + i);
          this.queue.splice(i, 0, {
            id: id,
            nextTime: time,
          });
          return;
        }
      }
      Logs.stringKey("push first");
      this.queue.push({
        id: id,
        nextTime: time,
      });
      return;
    },

    getListRunPayRoll() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        var now = new Date();
        if (now.getMonth() + 1 >= 7) {
          var year = now.getFullYear();
        } else {
          year = now.getFullYear() - 1;
        }
        var modelRequest = {
          companyId: Session.get("currenCompanyId"),
          fromDate: year + "-07-01",
          toDate: year + 1 + "-06-30",
        };
        this.$store
          .dispatch(GETLIST_RUNPAYROLL, modelRequest)
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            this.listPayRoll = response.reverse();
            if (this.$root.$refs.RunOverview != undefined) {
              this.$root.$refs.RunOverview.ongetListRunPayRollSuccess(
                this.listPayRoll
              );
            }
            now = new Date();
            if (this.timeOut == null) {
              for (var i = 0; i < this.listPayRoll.length; i++) {
                if (this.listPayRoll[i].statusPayroll == "Pushed") {
                  this.queue.push({
                    id: this.listPayRoll[i].id,
                    nextTime: now.getTime(),
                  });
                }
              }
            }
            this.checkQueue();
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      } else {
        if (this.$root.$refs.BaseLayout != undefined) {
          this.$root.$refs.BaseLayout.logout();
        }
      }
    },

    showStatus(status) {
      if (status == "PENDING") {
        return "Pending";
      } else if (status == "ACTIVE") {
        return "Active";
      } else if (status == "INACTIVE") {
        return "InActive";
      } else if (status == "REMOVED") {
        return "Removed";
      }
    },

    showEmploymentType(type) {
      if (type == "Casual") {
        return "Casual";
      } else if (type == "FullTime") {
        return "Full Time";
      } else if (type == "PartTime") {
        return "Part Time ";
      }
    },

    openModalCreateRequest(styleLeave) {
      this.$modal.show("modal-createrequest", {
        styleLeave: styleLeave,
      });
    },

    openModal() {
      this.$modal.show("modal-addpayroll", {
        data: "data",
      });
    },

    closeModal() {
      this.$modal.hide("modal-addpayroll");
    },

    openSearch() {
      if (this.isShowSearch) {
        this.isShowSearch = false;
      } else {
        this.isShowSearch = true;
        this.getListCompany();
      }
    },

    openSearchMobile() {
      // console.log('open Search mobile');
      if (this.isShowSearchMobile) {
        this.isShowSearchMobile = false;
      } else {
        this.isShowSearchMobile = true;
        this.getListCompany();
      }
    },

    toggleSideBar() {
      this.collapseRightMenu();
      //this.$store.dispatch('toggleSideBar')
      this.isShowRightSidebar = !this.isShowRightSidebar;
    },

    updateFavoriesPage(favories, id) {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DETAIL_COMPANY, id)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          var currentCompany = response;
          currentCompany.favories = favories;
          this.$store
            .dispatch(UPDATE_COMPANY, currentCompany)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
              if (favories > 0 && this.listCompany.length > 0) {
                this.isFavories = true;
              } else if (favories == 0 && this.listCompany.length == 0) {
                this.isFavories = false;
              }
              // Logs.json('list', this.listCompany)
              Logs.string("path", this.$global.path);
              if (this.$global.path == "/companies") {
                Logs.string("refs", this.$root.$refs.listCompany);
                this.$root.$refs.listCompany.updateFavories(favories, id);
              }
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    updateFavoriesMenu(favories, id) {
      for (var i = 0; i < this.listCompany.length; i++) {
        if (this.listCompany[i].id == id) {
          this.listCompany[i].favories = favories;
          break;
        }
      }
      if (favories > 0 && this.listCompany.length > 0) {
        this.isFavories = true;
      } else if (favories == 0 && this.listCompany.length == 0) {
        this.isFavories = false;
      }
    },
    changeActive(id) {
      for (var i = 0; i < this.listCompanyArchived.length; i++) {
        if (this.listCompanyArchived[i].id == id) {
          if (
            this.keySearch == null ||
            this.keySearch == "" ||
            this.listCompanyArchived.businessName
              .toLowerCase()
              .indexOf(this.keySearch.toLowerCase()) != -1
          ) {
            this.listCompany.push(this.listCompanyArchived[i]);
            this.listCompanyArchived.splice(i, 1);
            Utils.sortListJson(this.listCompany, "viewedTime", true);
          }
          break;
        }
      }
    },
    changeArchived(id) {
      for (var i = 0; i < this.listCompany.length; i++) {
        if (this.listCompany[i].id == id) {
          this.listCompanyArchived.push(this.listCompany[i]);
          this.listCompany.splice(i, 1);
          break;
        }
      }
    },
    showMoreMenu() {
      this.isClickCompany = true;
      if (this.isShowMoreMenu) {
        this.isShowMoreMenu = false;
      } else {
        this.isShowMoreMenu = true;
      }
    },

    collapseMenu() {
      if (!this.isShowMenu) {
        this.show();
        this.isShowMenu = true;
        window.$(".img-collapse").removeClass("badge-annual");
      } else {
        this.hide();
        this.isShowMenu = false;
        window.$(".img-collapse").addClass("badge-annual");
      }
    },

    imgCollapse() {
      if (!this.isShowMenu) {
        return "/assets/img/outline_chevron_right_white.png";
      } else {
        if (this.isHoverCollapse) {
          return "/assets/img/outline_chevron_left_white.png";
        }
        return "/assets/img/outline_chevron_left_black.png";
      }
    },

    showMenu() {
      if (!this.isShowMenu) {
        this.show();
      }
    },

    closeMenu() {
      if (this.isShowMenu) {
        this.hideOpacity("img-collapse");
      } else {
        this.hide();
      }
    },

    showOpacity(nameClass) {
      if (this.isShowMenu) {
        var web = window.$("." + nameClass);
        web.stop();
        web.animate(
          {
            opacity: "1",
          },
          200
        );
      }
    },

    hideOpacity(nameClass) {
      var web = window.$("." + nameClass);
      web.stop();
      web.animate(
        {
          opacity: "0",
        },
        200
      );
    },

    show() {
      var web = window.$(".menu-w");
      web.stop();
      web.animate(
        {
          "max-width": "200px",
        },
        350
      );
    },

    hide() {
      var web = window.$(".menu-w");
      web.stop();
      web.animate(
        {
          "max-width": "0px",
        },
        350
      );
      this.isShowSearch = false;
    },

    feedbackApp() {},

    collapseRightMenu() {
      this.isShowMoreMenu = false;
      if (this.isShowRightMenu) {
        this.hideRight();
      } else {
        this.showRight();
      }
    },

    showRightMenu() {
      if (!this.isShowRightMenu) {
        this.showRight();
      }
    },

    showRight() {
      var web = window.$(".collapse-rightMenu");
      web.stop();
      this.isShowRightMenu = true;
      web.animate(
        {
          "max-width": "350px",
        },
        250
      );
    },

    hideRight() {
      var web = window.$(".collapse-rightMenu");
      web.stop();
      web.animate(
        {
          "max-width": "0px",
        },
        250
      );
      setTimeout(() => {
        this.isShowRightMenu = false;
      }, 250);
    },

    toggleMenu() {
      var elem = window.$(this).closest("li");
      if (elem.hasClass("active")) {
        elem.removeClass("active");
      } else {
        elem.closest("ul").find("li.active").removeClass("active");
        elem.addClass("active");
      }
    },

    toggleMenu2() {
      window.$(".menu-mobile .menu-and-user2").slideToggle(200, "swing");
      this.toggleMenu();
    },

    onFocusMenu() {
      var elem = window.$(".os-dropdown-trigger");
      if (!elem.hasClass("over")) {
        elem.addClass("over");
      }
    },

    onBlurMenu() {
      var elem = window.$(".os-dropdown-trigger");
      if (elem.hasClass("over")) {
        elem.removeClass("over");
      }
    },

    clickAvartar() {
      var elem = window.$(".os-dropdown-trigger");
      if (!elem.hasClass("over")) {
        this.onFocusMenu();
      } else this.onBlurMenu();
    },

    logout() {
      Session.remove("Username");
      Session.remove("currenCompanyId");
      Session.remove("currenCompanyName");
      Session.remove("currenCompany");
      Session.remove("workHours");
      this.requestLogout();
    },

    gotoProfile() {
      if (!this.isEmployee) {
        this.idItemMenu = 6;
        this.$router.push({
          name: "settings",
          params: {
            tab: "Personal",
          },
        });
      }
    },

    gotoCreateCompany() {
      this.$router.push({
        name: "company",
        params: {
          isNotBack: false,
        },
      });
    },

    gotoListCompany() {
      this.$router.push({
        path: "/companies",
      });
      this.idItemMenu = 0;
      this.isShowSearch = false;
      this.isShowSearchMobile = false;
    },

    getDay() {
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      Date.prototype.getDayName = function () {
        return days[this.getDay()];
      };
      var now = new Date();
      var day = now.getDayName();
      return day;
    },

    // Employee
    renderAvatar(avatar) {
      return ApiManager.getUrlDomain() + avatar;
    },

    openModalStep0() {
      this.$modal.show("modal-newemployeestep0", {
        data: "data",
      });
    },

    openModalDetail(employee) {
      this.$modal.show("modal-detailemployee", {
        employee: employee,
      });
    },

    openModalEdit(employee) {
      this.$modal.show("modal-editemployee", {
        employee: employee,
      });
    },

    reloadDataEmployee(isReload) {
      if (isReload) {
        this.getListEmployee();
      }
    },

    clearTimeout() {
      if (this.timeOut != null) {
        Logs.stringKey("clear timeout");
        clearTimeout(this.timeOut);
        this.timeOut = null;
        Logs.stringKey("set queue = []");
        this.queue = [];
      }
    },

    // Company
    onClickItemCompany(item) {
      this.clearTimeout();
      Session.set("currenCompanyId", item.id);
      Session.set("currenCompanyName", item.businessName);
      Session.set("companyABN", item.companyABN);
      this.currenCompanyName = item.businessName;
      this.getDetailCompany();
      this.getListRunPayRoll();

      //Reload data
      if (this.$global.path == "/employees") {
        this.$root.$refs.People.getListEmployee();
      }
      if (this.$global.path == "/leave") {
        this.$root.$refs.Leave.reloadDataLeave(true);
      }
      if (this.$global.path == "/dashboard") {
        this.getListEmployee();
        this.$root.$refs.Dashboard.getInforSummary(true);
      }
      if (this.$global.path == "/reports") {
        this.getInforSummary();
      }
      if (this.$global.path == "/runpayroll") {
        this.$root.$refs.RunPayroll.goToRunOverview();
      }

      if (this.$global.path == "/settings") {
        this.$root.$refs.Setting.reloadChild();
      }
      Session.remove("runpayroll");
      this.isShowSearch = false;
      this.isShowSearchMobile = false;
    },
    // Request API

    searchCompany(keySearch) {
      this.listCompany = [];
      this.listCompanyArchived = [];
      for (var i = 0; i < this.listAllCompany.length; i++) {
        if (
          this.listAllCompany[i].businessName
            .toLowerCase()
            .indexOf(keySearch.toLowerCase()) != -1
        ) {
          if (this.listAllCompany[i].active == "ACTIVE") {
            this.listCompany.push(this.listAllCompany[i]);
          } else {
            this.listCompanyArchived.push(this.listAllCompany[i]);
          }
        }
      }
    },

    reloadListCompany() {
      this.listCompany = [];
      this.listCompanyArchived = [];
      for (var i = 0; i < this.listAllCompany.length; i++) {
        if (this.listAllCompany[i].active == "ACTIVE") {
          this.listCompany.push(this.listAllCompany[i]);
        } else {
          this.listCompanyArchived.push(this.listAllCompany[i]);
        }
      }
    },

    requestLogout() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(LOGOUT, Token.getRefreshToken())
        .then(() => {
          this.$store.dispatch(REMOVE_DEVICE, Session.get("Profile").id);
          Token.destroySession();
          this.$root.$refs.AppCyrus.finish();
          this.clearTimeout();
          window.location.href = "login";
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    getEmployeeContact() {
      if (this.$root.$refs.Reports != undefined) {
        this.$root.$refs.Reports.getEmployeeContacts();
      }
    },

    getEmployeeRemuneration() {
      if (this.$root.$refs.Reports != undefined) {
        this.$root.$refs.Reports.getEmployeeRemuneration();
      }
    },

    openLeaveBalancesModal() {
      if (Utils.isDisabled()) {
        if (this.$root.$refs.Reports != undefined) {
          this.$root.$refs.Reports.openLeaveBalancesModal();
        }
      }
    },

    openPayrollSummary() {
      if (this.$root.$refs.Reports != undefined) {
        this.$root.$refs.Reports.openPayrollSummary();
      }
    },
    openEmployeeSummary() {
      if (Utils.isDisabled()) {
        if (this.$root.$refs.Reports != undefined) {
          this.$root.$refs.Reports.openEmployeeSummary();
        }
      }
    },

    // Mothod for Left Menu
    async clickItemLeftMenu(id) {
      if (
        id == 3 ||
        this.$global.path != "/employees" ||
        this.$root.$refs.NewEditEmployee == undefined ||
        (await this.$root.$refs.NewEditEmployee.checkChangeInfoEmployee())
      ) {
        this.idItemMenu = id;
        this.isShowMoreMenu = false;
        switch (id) {
          case 1:
            if (this.$global.path != "/dashboard") {
              this.nextPage("/dashboard");
              this.getListEmployee();
            }
            break;
          case 2:
            if (this.$global.path != "/payrolls") {
              this.nextPage("/payrolls");
            }
            break;
          case 3:
            if (this.$global.path != "/employees") {
              this.nextPage("/employees");
            }
            break;
          case 4:
            if (this.$global.path != "/leave") {
              this.nextPage("/leave");
            }
            break;
          case 5:
            if (this.$global.path != "/reports") {
              this.nextPage("/reports");
              this.getInforSummary();
            }
            break;
          case 6:
            if (this.$global.path != "/settings") {
              this.nextPage("/settings");
            }
            break;
          default:
            if (this.$global.path != "/dashboard") {
              this.nextPage("/dashboard");
            }
        }
      }
    },

    nextPage(url) {
      this.$router.push({
        path: url,
        param: {},
      });
    },

    // Method for Company
    getListCompany() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(GET_LIST_COMPANY)
        .then((response) => {
          // console.log('GET_LIST_COMPANY')
          // console.log(response)
          this.$root.$refs.AppCyrus.finish();
          this.listAllCompany = response.listCompany;
          Utils.sortListJson(this.listAllCompany, "viewedTime", true);
          this.listCompany = [];
          this.listCompanyArchived = [];
          for (var i = 0; i < this.listAllCompany.length; i++) {
            if (this.listAllCompany[i].active == "ACTIVE") {
              this.listCompany.push(this.listAllCompany[i]);
              if (
                this.listAllCompany[i].favories != null &&
                this.listAllCompany[i].favories > 0
              ) {
                this.isFavories = true;
              }
            } else {
              this.listCompanyArchived.push(this.listAllCompany[i]);
            }
          }
          if (this.listCompany.length > 0) {
            var lastCompanySelect = this.listCompany[0];
            for (var j = 0; j < this.listCompany.length; j++) {
              if (
                this.utils.checkDate(
                  lastCompanySelect.viewedTime,
                  this.listCompany[j].viewedTime
                )
              ) {
                lastCompanySelect = this.listCompany[j];
              }
            }
            this.currenCompanyName = lastCompanySelect.businessName;
            if (!Session.has("currenCompanyId")) {
              Session.set("currenCompanyId", lastCompanySelect.id);
              Session.set("currenCompanyName", lastCompanySelect.businessName);
              Session.set("companyABN", lastCompanySelect.companyABN);
            }
            if (!this.isEmployee) {
              this.getDetailCompany();
              this.getListRunPayRoll();
              if (this.$global.path == "/dashboard") {
                this.getListEmployee();
                this.$root.$refs.Dashboard.getInforSummary();
              }
            }
          }
          if (this.$global.path == "/companies") {
            this.$root.$refs.listCompany.onLoadListCompany(
              response.listCompany
            );
          }
          this.searching = null;
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    getDetailCompany() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(DETAIL_COMPANY, Session.get("currenCompanyId"))
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();

            // test list rule
            // var listRule = [
            //     'POLICY_EMPLOYEE_ADD',
            //     'POLICY_EMPLOYEE_UPDATE',
            //     'POLICY_EMPLOYEE_DELETE',

            //     'POLICY_PAYROLL_ADD',
            //     'POLICY_PAYROLL_UPDATE',
            //     'POLICY_PAYROLL_DELETE',
            //     'POLICY_PAYROLL_SUBMIT',

            //     'POLICY_LEAVE_ADD',
            //     'POLICY_LEAVE_UPDATE',
            //     'POLICY_LEAVE_DELETE',

            //     'POLICY_REPORT_EMPLOYEE_CONTACTS',
            //     'POLICY_REPORT_EMPLOYEE_REMUNERATION',
            //     'POLICY_REPORT_LEAVE_BALANCES',
            //     'POLICY_REPORT_PAYROLL_ACTIVITY_SUMMARY',
            //     'POLICY_REPORT_PAYROLL_EMPLOYEE_SUMMARY'
            // ]
            // this.checkPolicy(listRule)
            // end test
            this.checkPolicy(response.listPolicy);
            if (response.companySettings != undefined) {
              Session.set("workHours", response.companySettings.workHours);
              Session.set("payPeriod", response.companySettings.payPeriod);
              Session.set(
                "payrollStartDate",
                response.companySettings.payrollStartDate
              );
            }
            Session.set("currenCompany", response);
            if (this.$root.$refs.Setting != undefined) {
              this.$root.$refs.Setting.getInfoCompany(response);
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },

    // Mothod for Employee
    getListEmployee() {
      if (Session.has("currenCompanyId")) {
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(GET_EMPLOYEE, Session.get("currenCompanyId"))
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            //  Logs.json('list employee old', this.listEmployees)
            this.listEmployees = response.reverse().slice(0, 3);
            // Logs.json('list employee new', this.listEmployees)
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },

    // Show nhắc nhở tạo employee khỗng xóa
    showRemind() {
      if (Session.get("isShowRemind")) {
        window.$(".not-pulse").addClass("pulse-itself");
      } else {
        window.$(".not-pulse").removeClass("pulse-itself");
      }
    },

    // Mothod for Report
    getInforSummary() {
      if (Utils.isDisabled()) {
        this.$root.$refs.AppCyrus.start();
        var dt = new Date();
        if (dt.getMonth() + 1 >= 7) {
          var year = dt.getFullYear();
        } else {
          year = dt.getFullYear() - 1;
        }
        var modelRequest = {
          companyId: Session.get("currenCompanyId"),
          fromDate: year + "-07-01",
          toDate: year + 1 + "-06-30",
        };
        this.$store
          .dispatch(GET_INFOR_SUMMARY, modelRequest)
          .then((response) => {
            Session.set("summary", response);
            if (this.$root.$refs.Reports != undefined) {
              this.$root.$refs.Reports.updateDataInfor();
            }
            this.$root.$refs.AppCyrus.finish();
            this.chartData = {
              labels: ["Tax", "Deduction", "Net pay", "Super"],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    response.totalTax,
                    response.totalDeduction,
                    response.totalNetPay,
                    response.totalSuper,
                  ],
                  backgroundColor: ["#FC6E73", "#61BB4B", "#2BA8D4", "#FFDE57"],
                  borderColor: ["#FC6E73", "#61BB4B", "#2BA8D4", "#FFDE57"],
                  borderWidth: 1,
                },
              ],
            };
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    validateTrial() {
      this.$store
        .dispatch(PaymentAction.VALIDATE_TRIAL, {
          email: Session.get("Profile").email,
        })
        .then(() => {
          //   Logs.json("Result:", response);
        })
        .catch((error) => {
          Logs.json("Error:", error);
        });
    },
    AfterEmployeeCheck() {
      this.$store
        .dispatch(USER_INFO)
        .then((response) => {
          //   this.$root.$refs.AppCyrus.finish();
          Session.remove("Profile");
          var date = new Date();
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          Session.set("Profile", {
            id: response.id,
            firstName: "",
            lastName: response.lastName,
            birthDate: date,
            email: response.email,
            role: response.role,
            creationTime: response.creationTime,
            userSubscription: response.userSubscription,
            currentEmpCount: response.currentEmpCount,
            allowedEmployeeInPLan: response.allowedEmployeeInPLan,
          });
          this.SubscriptionStatus =
            response.userSubscription.subscriptionStatus;
        })
        .catch(() => {
          //  this.$root.$refs.AppCyrus.fail();
          //this.errorHandling()
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.mainContent {
  width: 100%;
  height: 100vh;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.menu-collapse {
  max-width: 225px;
  display: contents;
}

.menu {
  background-color: #09b585;
}

.modal-content {
  -webkit-box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
  box-shadow: 0 25px 65px rgba(15, 24, 33, 0.29);
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  display: block;
}

.text-center {
  text-align: center !important;
}

strong {
  font-weight: bold;
}

// .content-panel {
//     z-index: 1000;
// }

.content-panel {
  min-width: 350px;
  // z-index: 1000;
  vertical-align: top;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 2rem 2.5rem;
  background-color: transparent;
}

.content-panel {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}

.content-panel-toggler {
  background-color: #22bdbc;
  padding: 4px 10px;
  border-radius: 6px;
  color: #fff;
  font-size: 0.72rem;
  text-transform: uppercase;
  display: none;
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 4;
  cursor: pointer;
}

.content-panel-toggler span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  letter-spacing: 1px;
}

.extra-info {
  margin-top: 33px;
  margin-left: 35px;
  background: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: absolute;
}

.extra-info .status-pill {
  width: 10px;
  height: 10px;
  margin-top: 2.3px;
  margin-left: 2.3px;
}

.notification-container {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
}

.notification.danger {
  color: white;
  background-color: #f24c3d;
}

.notification {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  font-size: 14px;
  min-height: 40px !important;
  border-radius: 6px !important;
}

.notification > .message {
  width: 100%;
  text-align: center;
}

.message p {
  margin-bottom: 0px;
}

.message a:hover {
  color: white;
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
}

.invoicesLink {
  color: white;
  text-decoration: underline;
  padding: 0 5px;
  cursor: pointer;
}



</style>
